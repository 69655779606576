import React from "react";
import styles from "./serial.module.css";
import { ReactComponent as Logo } from "../../../assets/images/arrow.svg";
import { Focusable } from "react-js-spatial-navigation";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { keyMap } from "../../../utils/constants";

const Serial = ({ seasons, handleOpenModal }) => {
  const ref = useRef(null);
  const focusRef = useRef(null);
  const btnRef = useRef([]);
  const [episodeIdsObj, setEpisodeIdsObj] = useState(seasons[0]);
  const [selectesSeason, setSelectesSeason] = useState(0);

  let num = useRef(0);
  const handleFocus = (ind) => {
    if (ind > num.current) {
      ref.current.scrollBy(260, 0);
      num.current = ind;
    }

    if (ind < num.current) {
      ref.current.scrollBy(-260, 0);
      num.current = ind;
    }
  };

  useEffect(() => {
    focusRef.current.firstChild.firstChild.focus();
  }, [episodeIdsObj]);

  function goUp(e, ind) {
    let code = e.which;
    if (code === keyMap.ARROW_UP && ind === 0) {
      btnRef.current[selectesSeason].parentNode.focus();
    }
  }

  return (
    <div className={styles.mainSerial}>
      <div className={styles.season}>
        <Logo className={styles.logo} width="50" height="50" />
        <div className={styles.buttons} ref={ref}>
          {seasons.map((item, ind) => (
            <div
            onClick={(e) => {
              setEpisodeIdsObj(item);
              setSelectesSeason(ind);
            }}
            key={item.label}
            >

            <Focusable
              
              id={ind}
              className={styles.divBtn}
              onFocus={() => handleFocus(ind)}
              onClickEnter={(e) => {
                setEpisodeIdsObj(item);
                setSelectesSeason(ind);
              }}
            >
              <button ref={(e) => (btnRef.current[ind] = e)}>
                {item.label}
              </button>
            </Focusable>
            </div>
          ))}
        </div>
        <Logo className={styles.logo2} width="50" height="50" />
      </div>
      <div className={styles.episodes} ref={focusRef}>
        {episodeIdsObj?.episodes.map((item, ind) => (
          <div onClick={() => {
            handleOpenModal(item, seasons[selectesSeason]);
          }} key={item} onKeyDown={(e) => goUp(e, ind)}>
            <Focusable
              className={styles.episodeItem}
              onClickEnter={() => {
                handleOpenModal(item, seasons[selectesSeason]);
              }}
            >
              <div>{"Episode " + (ind + 1)}</div>
            </Focusable>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Serial;
