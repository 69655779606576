import jwtDecode from "jwt-decode";
import React from "react";
import { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Focusable } from "react-js-spatial-navigation";
import { api } from "../../../api/config";
import { base, keyMap } from "../../../utils/constants";
import { getActiveUser, isLogged } from "../../../utils/helpers";
import styles from "./select.module.css";
import cx from "classnames";
import { ReactComponent as Logo } from "../../../assets/images/check.svg";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const SelectChannels = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const ref = useRef(null);
  const [channels, setChannels] = useState([]);
  const [selected, setSelected] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state.allowedChannels?.length) {
      setSelected(location.state.allowedChannels);
    }
  }, [location]);

  useEffect(() => {
    const token = isLogged();
    const decoded = jwtDecode(token);
    const tariffId = decoded.tariff?.[0];
    api
      .get(`/channels/channelsByTariffId/${tariffId}`, {
        headers: { Authorization: token },
      })
      .then((res) => setChannels(res?.data?.channels));
  }, []);

  function goBack(e) {
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-2);
    }
  }

  const selectChannels = (itemID, e) => {
    if (!selected.includes(itemID)) {
      setSelected([...selected, itemID]);
    } else {
      setSelected(selected.filter((item) => item !== itemID));
    }
  };

  const selectAllChannels = () => {
    if (selected.length !== channels.length) {
      setSelected(channels.map((item) => item._id));
    } else {
      setSelected([]);
    }
  };

  const okReq = async () => {
    const activeUser = getActiveUser();
    const res = await api.put(
      `/users/${activeUser.id}/subuser/${location.state.id}`,
      {
        allowedChannels: selected,
      }
    );

    if (res.data.status === "success") {
      enqueueSnackbar(t(`Settings saved successfully`), {
        variant: "success",
        autoHideDuration: 3000,
      });
      return;
    }
    enqueueSnackbar(t("An error occurred, please try again later"), {
      variant: "error",
      autoHideDuration: 3000,
    });
  };

  useEffect(() => {
    ref.current.firstChild.firstChild.focus();
  }, []);

  return (
    <div className={styles.mainSelect} onKeyUp={(e) => goBack(e)}>
      <div className={styles.containerSelect}>
        <div className={styles.channelsContext}>
          <div className={styles.channelsListContainer}>
            <div className={styles.selectChannels}>
              {channels?.map((item, ind) => {
                let bool = selected.includes(item._id);
                return (
                  <div onClick={(e) => {
                    selectChannels(item._id, e);
                  }} key={ind}>
                    <Focusable
                      className={cx(styles.focusableSelect, {
                        [styles.backgroundGreen]: bool,
                      })}
                      onClickEnter={(e) => {
                        selectChannels(item._id, e);
                      }}
                    >
                      <div className={styles.infoChannels}>
                        <div>{ind + 1 + "."}</div>
                        <div
                          style={{
                            backgroundImage: `url("${base}/img/channels/${item.image}")`,
                          }}
                          className={styles.channelLogo}
                        ></div>

                        <div>{item.name}</div>
                      </div>
                      {bool && (
                        <Logo
                          className={styles.svgLogo}
                          width="40"
                          height="40"
                        />
                      )}
                    </Focusable>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.middleContainer}>
            <div className={styles.title}>{t("settings.Select channels")}</div>
            <div className={styles.buttons} ref={ref}>
              <div onClick={selectAllChannels} >
              <Focusable
                className={styles.selectAll}
                onClickEnter={selectAllChannels}
              >
                <div  >
                {t("settings.Select all")}
                </div>
              </Focusable>
              </div>
              <div onClick={okReq} >

              <Focusable className={styles.ok} onClickEnter={okReq}>
                <div >
                {t("settings.ok")}
                </div>
              </Focusable>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default SelectChannels;
