import { api } from "../config";

export const R_GetAdminNotifications = async (data) => {
    const res = await api.get(`/notifications/users/${data.customerId}`);
    return res.data
}

export const R_SeenAdminNotification = async (data) => {
    const res = await api.put(`/notifications/users`, data);
    return res
}