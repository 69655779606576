import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { keyMap } from "../../utils/constants";
import { removeSubuser, saveSubuserActiveSession } from "../../utils/helpers";
import styles from "./styles.module.css";

const Warning = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const warningRef = useRef();

  useEffect(() => {
    warningRef.current.focus();
    saveSubuserActiveSession();
    removeSubuser();
  }, []);

  const handleKeyUp = (e) => {
    switch (e.which) {
      case keyMap.KEY_D:
        navigate("/users");
        break;
      case keyMap.REMOTE_BACK:
        navigate("/users");
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.mainWarning}>
      <div className={styles.containerWarning}>
        <div
          onKeyUp={handleKeyUp}
          tabIndex={1}
          ref={warningRef}
          autoFocus
          className={styles.context}
        >
          <span>Dear {location.state},</span>
          sorry but since your daily period is <br /> over, You can’t watch TV.
        </div>
      </div>
    </div>
  );
};

export default Warning;
