import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getActiveUser, getSubuser, isLogged, logout } from '../utils/helpers';


const PrivateRoute = ({children}) => {
    const navigate = useNavigate()
    const [token, setToken] = useState('initial');
    useEffect(()=>{
        const token = isLogged()
        setToken(token)
        if(token){
            const user = getSubuser()
            if(!user){
                navigate("/users");
            }
        }else{
            logout()
            navigate("/")
        }
    }, [])
    if(token==='initial'){
        return <Typography component="h1" >Loading ...</Typography>
    }
    return (
        token ? children : <Navigate to="/" />
    );
};

export default PrivateRoute;