import { Button, IconButton } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SpatialNavigation, { Focusable } from 'react-js-spatial-navigation';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Forward30Icon from '@material-ui/icons/Forward30';
import Replay30Icon from '@material-ui/icons/Replay30';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import CastConnectedIcon from '@material-ui/icons/CastConnected';
import CastIcon from '@material-ui/icons/Cast';
import cx from 'classnames';
import styles from './styles.module.css';
import { generateM3U8URL, parse } from '../../../utils/helpers';
import moment from 'moment';
import { baseArchiver, keyMap } from '../../../utils/constants';
import axios from 'axios';
import VideoJS from '../../playerVideo';
import Modal from '../../Modal';
import { closePeer, initPeer } from '../../../testPeer';
import { useDispatch, useSelector } from 'react-redux';

import { selectConnectionAction, selectConnectionStatus } from "../../../Store/TVConnection/selectors"
import tvConnectionSlice from '../../../Store/TVConnection/slice';

export default function PlayerControls({
  videoRef,
  channelInfo,
  currentChannelsPrograms,
  timeoutId,
  controllsRef,
  setIsArchive,
  dialogOptions,
  setDialogOptions
}) {
  const dispatch = useDispatch()
  const [info, setInfo] = useState()
  const [playedPercent, setPlayedPercent] = useState(0)
  const [programTimeInfo, setProgramTimeInfo] = useState()
  const [cursorTime, setCursorTime] = useState(false)
  const [shiftedDay, setShiftedDay] = useState('LIVE')
  const [shiftedProgram, setShiftedProgram] = useState()
  const [helpText, setHelpText] = useState('')
  const [shiftedProgramIndex, setShiftedProgramIndex] = useState(null)
  const [mounted, setMounted] = useState(false)
  // const [isModalHandled, setIsModalHandled] = useState(false)
  const isModalHandled = useRef(false)
  const playerRefThumbnail = useRef(null)

  const [thumbnailURL, setThumbnailURL] = useState('')
  const [thumbnail, setThumbnail] = useState(null)
  const [thumbLoading, setThumbLoading] = useState(false)



  const [connectionDialogOptions, setConnectionDialogOptions] = useState({
    title: "",
    open: false,
    content: "",
    cancelText: "",
    okText: "",
  })




  const [videoState, setVideoState] = useState({
    isPlaying: true,
  })
  const prevProgramRef = useRef(null)
  const nextProgramRef = useRef(null)
  const playerIntervalId = useRef(null)
  const shiftedHours = useRef({ hours: 0, minutes: 0, seconds: 0 })
  const shiftedFromLive = useRef(0)
  const shiftedDayRef = useRef(shiftedDay)
  const isKeyDown = useRef(true)
  const programNameRef = useRef(null)
  useEffect(() => {
    shiftedDayRef.current = shiftedDay
    setIsArchive && setIsArchive(shiftedDay !== 'LIVE')
  }, [shiftedDay])

  const isTvConnected = useSelector(selectConnectionStatus) 

  const cursorChangeInterval = useRef(null)
  const cursorChangeIntervalfaster = useRef(null)
  const cursorDeltaChange = useRef(1)
  const momentRef = useRef(moment())

  const handlePlayerInterval = (isLive, byProgram) => {
    if (shiftedDayRef.current === 'LIVE' || isLive) {
      const formatEPG = 'YYYYMMDDHHmmss'
      const dateNow = new Date()
      // const duration = dateNow.getMinutes() * 60 + dateNow.getSeconds()
      const duration = 3600
      const currentTime = dateNow.getMinutes() * 60 + dateNow.getSeconds()
      const startTime = `${moment().format('HH')}:00:00`
      const percent = ((currentTime * 100) / duration)
      setPlayedPercent(percent)
      const formattedDuration = `${moment().format('HH')}:59:59`
      const formattedcurrentPlayed = moment().format("HH:mm:ss")
      const formattedStartTime = startTime
      setProgramTimeInfo({ formattedDuration, formattedcurrentPlayed, formattedStartTime });
    } else {
      let duration, date;
      momentRef.current.add(1, 'seconds')
      if (shiftedHours.current.hours > 0 || shiftedHours.current.minutes > 0 || shiftedHours.current.seconds > 0) {
        duration = 3600
      } else {
        date = new Date()
        // duration = date.getMinutes() * 60 + date.getSeconds()
        duration = 3600
      }
      const minutes = momentRef.current.format('mm')
      const seconds = momentRef.current.format('ss')
      const currentTime = +moment.duration(minutes, 'minutes').asSeconds() + +seconds
      const percent = ((currentTime * 100) / duration)
      setPlayedPercent(percent)
      // if(percent > 98 && !isModalHandled.current){
      //   isModalHandled.current = true
      //   setDialogOptions({
      //     title: 'Want to continue?',
      //     open: true,
      //     setOpen: (val) => setDialogOptions({...dialogOptions, open: val}),
      //     content: '',
      //     cancelText: 'Stay',
      //     okText: 'Live',
      //     handleCancel: ()=>{setDialogOptions({...dialogOptions, open: false})},
      //     handleOk: ()=>{
      //       setDialogOptions({...dialogOptions, open: false}); 
      //       setShiftedDay('LIVE')
      //       getAndStoreM3U8(channelInfo.url)
      //       shiftedHours.current = {hours: 0, minutes: 0, seconds: 0}},
      //     })
      // }
      if (percent === 0) {
        const seekTo = 0

        const options = {
          startTime: `${momentRef.current.format('HH')}0000`,
          endTime: `${momentRef.current.format('HH')}5959`,
          day: momentRef.current.format('DD'),
          offset: new Date().getTimezoneOffset(),
          date: momentRef.current.format('YYYYMMDD'),
          archiveName: channelInfo.archive.name,
          baseArchiver: channelInfo.archive.archiverInfo.host
        }
        const url = generateM3U8URL(options)
        if (videoRef.current.currentSrc() === url) {
          videoRef.current.currentTime(+seekTo)
        } else {
          getAndStoreM3U8(url, seekTo)
        }
      }
      if (percent === 0 && !byProgram) {
        if (shiftedHours.current.hours > 0 || shiftedHours.current.minutes > 0 || shiftedHours.current.seconds > 0) {
          shiftedHours.current.hours -= 1
          // const seekTo = shiftedHours.current.hours > 0 ? +momentRef.current.format('mm') * 60 + +momentRef.current.format('ss') : 0


        } else {
          shiftedHours.current = { hours: 0, minutes: 0, seconds: 0 }
        }
      }
      setProgramTimeInfo({
        formattedStartTime: `${momentRef.current.format('HH')}:00:00`,
        formattedDuration: `${momentRef.current.format('HH')}:59:59`,
        formattedcurrentPlayed: momentRef.current.format('HH:mm:ss')
      });
      setShiftedDay(momentRef.current.format('MM DD (ddd)'))
    }
  }

  const simplifyPrograms = (arr) => arr.map(single => {
    const dateStart = parse(single?.start);
    const dateStop = parse(single?.stop);
    return {
      start: `${dateStart.h}:${dateStart.m}`,
      stop: `${dateStop.h}:${dateStop.m}`,
      startEpgTime: single?.start,
      endEpgTime: single?.stop,
      title: single?.title?.[0]?.value,
    };
  });

  useEffect(() => {
    setShiftedDay('LIVE')
  }, [channelInfo])



  useEffect(() => {
    if (currentChannelsPrograms && currentChannelsPrograms.currentProgramIndex > -1) {
      const i = currentChannelsPrograms.currentProgramIndex
      const [prevProgram, currentProgram, nextProgram] = simplifyPrograms([currentChannelsPrograms.fullEpg[i - 1], currentChannelsPrograms.fullEpg[i], currentChannelsPrograms.fullEpg[i + 1]])
      setInfo({ prevProgram, currentProgram, nextProgram })
    } else {
      setInfo(null)
    }
  }, [currentChannelsPrograms])

  const pauseButtonRef = useRef(null)

  async function handlePlayPause (value) {
    if(0 in arguments){
      value ? videoRef.current.pause() : videoRef.current.play()
      setVideoState({ ...videoState, isPlaying: !value })
    }else{
      videoState.isPlaying ? videoRef.current.pause() : videoRef.current.play()
      setVideoState({ ...videoState, isPlaying: !videoState.isPlaying })
    }
  };

  const handleCursorFocus = (focus) => {
    setCursorTime(focus)
  }

  const handleProgramFocus = (delta) => {
    if (delta < 0) prevProgramRef.current.style.opacity = 1
    else nextProgramRef.current.style.opacity = 1
  }
  const handleProgramUnFocus = (delta) => {
    if (delta < 0) prevProgramRef.current.style.opacity = 0
    else nextProgramRef.current.style.opacity = 0
  }


  const getAndStoreM3U8 = async (url, seekTo, playerType) => {
    try {
      setHelpText('')
      await axios.get(url)
      videoRef.current.handleSrc_(
        [
          {
            src: url,
            type: 'application/x-mpegURL',
          },
        ],
        true
      );
      Number.isInteger(seekTo) && videoRef.current.currentTime(seekTo)
    } catch (error) {
      setHelpText('File not found!')
    }

  }

  const handlePlayerWaiting = () => {
    clearInterval(playerIntervalId.current)
  }

  const handlePlayerPlaying = () => {
    clearInterval(playerIntervalId.current)
    playerIntervalId.current = setInterval(() => { handlePlayerInterval() }, 1000)
    // if(videoState.isPlaying){
    //   videoRef.current.play()
    // }else{
    //   videoRef.current.pause()
    // }
  }


  useEffect(() => {
    if (videoRef.current && mounted) {
      videoRef.current.on('waiting', handlePlayerWaiting);
      videoRef.current.on('playing', handlePlayerPlaying);
      videoRef.current.on('loadstart', handlePlayerWaiting);
      videoRef.current.on('pause', handlePlayerWaiting);
      videoRef.current.on('seeking', handlePlayerWaiting);
      videoRef.current.on('ended', () => {
        const seek = videoRef.current.currentTime()
        getAndStoreM3U8(videoRef.current.currentSrc(), seek)
      });
    }
  }, [videoRef.current, mounted])


  useEffect(() => {
    if (currentChannelsPrograms?.fullEpg) {
      const shiftedIndex = currentChannelsPrograms.fullEpg.findIndex(item => moment(item.start, 'YYYYMMDDHHmmss').isSameOrBefore(momentRef.current) && moment(item.stop, 'YYYYMMDDHHmmss').isSameOrAfter(momentRef.current))
      setShiftedProgram(currentChannelsPrograms?.fullEpg?.[shiftedIndex])
      setShiftedProgramIndex(shiftedIndex >= 0 ? shiftedIndex : null)
    }
  }, [momentRef.current])

  useEffect(() => {
    if (currentChannelsPrograms?.fullEpg && Number.isInteger(shiftedProgramIndex) && shiftedProgramIndex > -1) {
      const [next, prev] = simplifyPrograms([currentChannelsPrograms?.fullEpg?.[shiftedProgramIndex + 1], currentChannelsPrograms?.fullEpg?.[shiftedProgramIndex - 1]])
      setInfo({
        ...info,
        nextProgram: next,
        prevProgram: prev,
      })
    }
  }, [shiftedProgramIndex])


  useEffect(() => {
    if (pauseButtonRef?.current?.parentNode) {
      pauseButtonRef.current.parentNode.focus()
    }
  }, [pauseButtonRef])


  const handleGoToLive = () => {
    if (!channelInfo?.archive?.name) { return }
    if (shiftedDay !== 'LIVE') {
      handlePlayerInterval(true)
      setShiftedDay('LIVE')
      getAndStoreM3U8(channelInfo.url)
      shiftedFromLive.current = 0
    }
  }

  const openConnectionModal = () => {
    if(!isTvConnected){
      setConnectionDialogOptions({
        title: "Connection Id is",
        open: true,
        content: "123456",
        cancelText: "Close",
        okText: "Confirm",
      })
      const onConnection = () => {
        dispatch(tvConnectionSlice.actions.setIsTVConnected(true))
        setConnectionDialogOptions({ ...connectionDialogOptions, open: true, title: "", content: "Connected!" })
        setTimeout(() => {
          setConnectionDialogOptions({ ...connectionDialogOptions, open: false })
        }, 3000)
      }
      initPeer(onConnection)
    }else{
      closePeer()
    }
  }

  const handleSeekTo = (delta) => {
    videoRef.current.currentTime(delta);
  };

  const handleSeekKeyDown = (e, delta) => {
    if (!channelInfo?.archive?.name) { return }
    let date, duration;
    let keyArr = [keyMap.ARROW_LEFT, keyMap.ARROW_RIGHT]
    if (delta) {
      keyArr = [keyMap.REMOTE_OK]
    }
    if (keyArr.includes(e.which)) {
      if (isModalHandled.current) {
        if (playedPercent < 98)
          isModalHandled.current = false
      }
      if (!cursorChangeInterval.current) {
        cursorChangeInterval.current = setTimeout(() => {
          cursorDeltaChange.current = 3;
          cursorChangeIntervalfaster.current = setTimeout(() => {
            cursorDeltaChange.current = 6;
          }, 3000)
        }, 3000)
      }
      // clearInterval(timeoutId.current)
      e.stopPropagation && e.stopPropagation();
      e.preventDefault && e.preventDefault();
      clearInterval(playerIntervalId.current)
      if (shiftedFromLive.current > 0) {
        momentRef.current = moment().subtract(shiftedFromLive.current, 'seconds')
        duration = 3600
      } else {
        date = new Date()
        // duration = date.getMinutes() * 60 + date.getSeconds()
        momentRef.current = moment()
      }
    }
    duration = 3600
    const condition = delta < 0 ? e.which === keyMap.REMOTE_OK : !delta ? e.which === keyMap.ARROW_LEFT : false
    const elseCondition = delta > 0 ? e.which === keyMap.REMOTE_OK : !delta ? e.which === keyMap.ARROW_RIGHT : false
    if (condition) {
      // goes back till archive duration
      // if(+channelInfo.archive.duration === shiftedHours.current.hours + 1){
      //   return
      // }
      const percentDelta = delta < 0 ? delta * -100 / duration : cursorDeltaChange.current
      if (playedPercent - percentDelta >= 0) {
        let change = percentDelta * duration / 100
        momentRef.current = momentRef.current.clone().subtract(change, 'seconds')

        shiftedFromLive.current = moment().diff(momentRef.current, 'seconds')
        // shiftedHours.current.minutes = +momentRef.current.format('mm')
        // shiftedHours.current.seconds = +momentRef.current.format('ss')
        setShiftedDay(momentRef.current.format('MM DD (ddd)'))
        const formattedcurrentPlayed = momentRef.current.format('HH:mm:ss')
        const formattedDuration = `${momentRef.current.format("HH")}:59:59`
        const startTime = `${momentRef.current.format('HH')}:00:00`
        const formattedStartTime = startTime
        setProgramTimeInfo({ formattedStartTime, formattedDuration, formattedcurrentPlayed });
        setPlayedPercent(playedPercent - percentDelta)
        // handlePlayerInterval()
      } else {
        setPlayedPercent(100)
      }

    } else if (elseCondition) {
      const percentDelta = delta > 0 ? delta * 100 / duration : cursorDeltaChange.current
      if (playedPercent + percentDelta <= 100) {
        let change = percentDelta * duration / 100
        const timeToChange = momentRef.current.clone().add(change, 'seconds')
        const calcShifted = +moment().diff(timeToChange, 'seconds')
        if (calcShifted < 0) {
          shiftedFromLive.current = 0
          handleGoToLive()
          return
        }

        momentRef.current = timeToChange;
        shiftedFromLive.current = calcShifted;
        const formattedcurrentPlayed = momentRef.current.format('HH:mm:ss')
        setShiftedDay(momentRef.current.format('MM DD (ddd)'))
        const formattedDuration = `${momentRef.current.format("HH")}:59:59`
        const startTime = `${momentRef.current.format('HH')}:00:00`
        const formattedStartTime = startTime
        setProgramTimeInfo({ formattedStartTime, formattedDuration, formattedcurrentPlayed });
        setPlayedPercent(playedPercent + percentDelta)
        // handlePlayerInterval()
      } else {
        setPlayedPercent(0)
      }
    }
    // const seekTo = +momentRef.current.format('mm') * 60 + +momentRef.current.format('ss')
    // const startTime = `${momentRef.current.format('HHmm')}00`
    // const endTime = `${momentRef.current.format('HH')}${+momentRef.current.format('mm')+1}00`
    // const url = `${baseArchiver}/archive/indexByDate?startTime=${startTime}&endTime=${endTime}&day=${momentRef.current.format('DD')}&archiveName=${channelInfo.archive.name}_small&date=${momentRef.current.format('YYYYMMDD')}&userOffset=-240`
    // getAndStoreM3U8(url, 0, 'thumb') 
  }

  const handleSeekKeyUp = (e, delta) => {
    if (shiftedDay === "LIVE") return
    if (!channelInfo?.archive?.name) { return }
    let keyArr = [keyMap.ARROW_LEFT, keyMap.ARROW_RIGHT]
    if (delta) {
      keyArr = [keyMap.REMOTE_OK]
    }
    if (keyArr.includes(e.which)) {
      isKeyDown.current = false
      cursorChangeInterval.current && clearTimeout(cursorChangeInterval.current)
      cursorChangeIntervalfaster.current && clearTimeout(cursorChangeIntervalfaster.current)
      cursorChangeInterval.current = null
      cursorChangeIntervalfaster.current = null
      cursorDeltaChange.current = 1;
      // if (controllsRef.current) {
      //   controllsRef.current.style.opacity = 1;
      //   timeoutId.current = setTimeout(() => {
      //     if(controllsRef?.current?.style?.opacity){
      //       controllsRef.current.style.opacity = 0;
      //       pauseButtonRef.current.parentNode.focus()
      //     }
      //   }, 20000);
      // }
      e.stopPropagation && e.stopPropagation();
      e.preventDefault && e.preventDefault();
      const seekTo = +momentRef.current.format('mm') * 60 + +momentRef.current.format('ss')

      const options = {
        startTime: `${momentRef.current.format('HH')}0000`,
        endTime: `${momentRef.current.format('HH')}5959`,
        day: momentRef.current.format('DD'),
        offset: new Date().getTimezoneOffset(),
        date: momentRef.current.format('YYYYMMDD'),
        archiveName: channelInfo.archive.name,
        baseArchiver: channelInfo.archive.archiverInfo.host
      }
      const url = generateM3U8URL(options)
      if (videoRef.current.currentSrc() === url) {
        videoRef.current.currentTime(+seekTo)
      } else {
        getAndStoreM3U8(url, seekTo)
      }
    }
    // switch (e.which) {
    //   case keyMap.ARROW_LEFT:
    //     break;
    //   case keyMap.ARROW_RIGHT:

    //     break;

    //   default:
    //     break;
    // }
  }




  const handleGoToProgram = async (delta) => {
    if (channelInfo?.archive?.name) {
      const programToChange = delta > 0 ? info.nextProgram : info.prevProgram
      const start = moment(programToChange.startEpgTime, 'YYYYMMDDHHmmss')
      if (start.isAfter(moment())) {
        return
      }
      const seekTo = +start.format('mm') * 60 + +start.format('ss')
      const today = +moment().format('DD')
      const currentHour = +moment().format('HH')
      const currentMinutes = +moment().format('mm')
      const currentSeconds = +moment().format('ss')

      const startDay = +start.format('DD')
      const startHour = +start.format('HH')
      const startMinutes = +start.format('mm')
      const startSeconds = +start.format('ss')



      if (startDay === today) {
        const diff = moment.duration(moment().diff(start));
        shiftedHours.current = {
          hours: currentHour - startHour - 1,
          minutes: currentMinutes,
          seconds: currentSeconds,
          withCurrentSeek: +diff.minutes() * 60,
        }
      } else {
        let days = 0
        if (today < startDay) {
          const currentMonthsEndDay = +moment().clone().endOf('month').format('DD');
          days = today + (currentMonthsEndDay - startDay)
        } else {
          const diff = moment.duration(moment().diff(start));
          let timeDiff = currentHour - startHour - 1
          if (currentHour < startHour) {
            timeDiff = currentHour + (24 - startHour) - 1
          }
          shiftedHours.current = {
            hours: timeDiff,
            minutes: currentMinutes,
            seconds: currentSeconds,
            withCurrentSeek: +diff.minutes() * 60,
          }
        }
      }
      const diff = moment.duration(moment().diff(start)).asSeconds();
      shiftedFromLive.current = diff
      momentRef.current = moment().subtract(shiftedHours.current.hours + 1, 'hours').subtract(shiftedHours.current.minutes, 'minutes').subtract(shiftedHours.current.seconds, 'seconds').add(seekTo, 'seconds')
      setShiftedDay(momentRef.current.format('MM DD (ddd)'))


      const options = {
        startTime: `${start.format('HH')}0000`,
        endTime: `${start.format('HH')}5959`,
        day: start.format('DD'),
        offset: new Date().getTimezoneOffset(),
        date: start.format('YYYYMMDD'),
        archiveName: channelInfo.archive.name,
        baseArchiver: channelInfo.archive.archiverInfo.host
      }
      const url = generateM3U8URL(options)
      if (videoRef.current.currentSrc() === url) {
        videoRef.current.currentTime(+seekTo)
      } else {
        getAndStoreM3U8(url, seekTo)
      }


      // await getAndStoreM3U8(`${baseArchiver}/archives/${channelInfo.archive.name}/${start.format('DD/HH')}0000/index.m3u8`, seekTo)
      handlePlayerInterval(null, true)
    }

  }


  // const generateThumbnailUrl = (archiveName) => {
  //   const url = `${baseArchiver}/archives/${archiveName}/${momentRef.current.format('DD/HH')}0000/images/${Math.ceil((+momentRef.current.format('mm') * 60 + +momentRef.current.format('ss'))) + 3}.jpeg`
  //   return url
  // }

  // useEffect(()=>{
  //   if(channelInfo?.archive?.name)
  //   setThumbnailURL(generateThumbnailUrl(channelInfo.archive.name));
  // }, [playedPercent, channelInfo])

  // const getAndStoreThumbnail = async (thumbnailURL) => {
  //     try {
  //       setThumbLoading(true)
  //       const img = await axios.get(thumbnailURL, { responseType: 'arraybuffer' })
  //       const dataUrl = `data:image/png;base64, ${Buffer.from(img.data).toString('base64')}`
  //       setThumbnail(dataUrl)
  //       setThumbLoading(false)
  //     } catch (error) {
  //       setThumbLoading(false)
  //       setThumbnail(null)
  //     }
  // }

  // useEffect(()=>{
  //   getAndStoreThumbnail(thumbnailURL)
  // }, [thumbnailURL])


  useEffect(() => {
    if (mounted) {
      handlePlayerInterval()
      playerIntervalId.current = setInterval(() => { handlePlayerInterval() }, 1000)
    }
    if (videoRef?.current && !mounted) {
      setMounted(true)
    }
    return () => {
      playerIntervalId.current && clearInterval(playerIntervalId.current)
    }
  }, [videoRef.current, info, mounted])



  // const optionsThumbnail = {
  //   autoplay: true,
  //   // controls: false,
  //   responsive: false,
  //   fill: true,
  //   // controlBar: false,
  //   // loadingSpinner: true,
  //   bigPlayButton: false,
  //   fluid: true,
  //   aspectRatio: '16:9',
  // };

  // const handlePlayerReadyThumbnail = useCallback(player => {
  //   if(channelInfo && playerRefThumbnail){
  //     playerRefThumbnail.current = player;
  //     player.handleSrc_(
  //       [
  //         {
  //           src: channelInfo.url,
  //           type: 'application/x-mpegURL',
  //         },
  //       ],
  //       true
  //     );
  //   }
  // }, [channelInfo, playerRefThumbnail.current]);

  const progNameIntervalId = useRef(null)
  useEffect(() => {
    const name = shiftedProgram?.title?.[0]?.value || info?.currentProgram?.title
    if (name?.length > 35) {
      progNameIntervalId.current = setInterval(() => {
        if (programNameRef.current.scrollWidth - programNameRef.current.scrollLeft === programNameRef.current.clientWidth) {
          setTimeout(() => {
            programNameRef.current.scrollTo({ left: 0 })
          }, 1000)
        }
        programNameRef.current.scrollBy({ left: 1 })
      }, 10);
    } else {
      clearInterval(progNameIntervalId.current)
    }
    return () => {
      clearInterval(progNameIntervalId.current)
    }
  }, [shiftedProgram?.title?.[0]?.value, info?.currentProgram?.title])




  const connectionAction = useSelector(selectConnectionAction)

  useEffect(()=>{
    if(connectionAction?.goToLive){
      handleGoToLive()
    }else if(connectionAction?.positionSeekTo){
      if(connectionAction.option.delta){
        handleSeekKeyDown({ which: keyMap.REMOTE_OK }, connectionAction.option.delta)
        setTimeout(()=>{
          handleSeekKeyUp({ which: keyMap.REMOTE_OK }, connectionAction.option.delta)
        }, 100)
      }else if(connectionAction.option.percent){
        console.log(connectionAction);

      }
    } else if(connectionAction?.hasOwnProperty("isPaused")){
      handlePlayPause(connectionAction.isPaused)
    }
  }, [connectionAction])

  return (
    <>
      <Modal {...connectionDialogOptions}
        setOpen={(val) => setConnectionDialogOptions({ open: false })}
        handleCancel={(val) => setConnectionDialogOptions({ open: false })}
        handleOk={(val) => setConnectionDialogOptions({ open: false })}
      />
      <div tabIndex='-1' className={styles.controllsContainer}>
        {info && <><div ref={prevProgramRef} className={styles.prevProgram}>
          <span className={styles.time}>
            {info?.prevProgram?.start} - {info?.prevProgram?.stop}
          </span>
          <span>{info?.prevProgram?.title}</span>
        </div>
          <div ref={nextProgramRef} className={styles.nextProgram}>
            <span className={styles.time}>
              {info?.nextProgram?.start} - {info?.nextProgram?.stop}
            </span>
            <span>{info?.nextProgram?.title}</span>
          </div>
          <div className={styles.programInfo}>
            {/* <MaterialIcons name="play-arrow" size={24} color="#F7E975" /> */}
            <span ref={programNameRef} className={styles.programName}>
              {' '}
              &nbsp; {shiftedProgram ? shiftedProgram?.title?.[0]?.value : info?.currentProgram?.title} &nbsp;{' '}
            </span>
            <span className={styles.currentTime}> | {programTimeInfo?.formattedcurrentPlayed} / {programTimeInfo?.formattedDuration} </span>
            {helpText && <div className={styles.helpText}>&nbsp;{' '} | {helpText}</div>}
          </div></>}
        {/* <BlurView intensity={90} tint="dark"  style={styles.blurContainer}> */}
        <div className={styles.channelInfo}>
          <div className={styles.live}>
            {/* <MaterialIcons name="play-arrow" size={24} color="#F7E975" /> */}
            <span style={{ color: '#F7E975' }}>
              {' '}
              &nbsp; {channelInfo?.name} &nbsp;{' '}
            </span>
            <span style={{ color: '#979BB1' }}> | {shiftedDay}</span>
          </div>
        </div>
        <div className={styles.playButtonsContainer}>
          <Focusable
            onFocus={() => { handleProgramFocus(-1) }}
            onUnfocus={() => { handleProgramUnFocus(-1) }}
            onClickEnter={() => {
              handleGoToProgram(-1)
              // handleOpenArchive(currentChannelsPrograms.fullEpg, currentChannelsPrograms.currentProgramIndex - 1)
              //   handleChannelChange(-1);
            }}>
            <IconButton
              size="medium"
              onClick={() => {
                handleGoToProgram(-1)
                // handleOpenArchive(currentChannelsPrograms.fullEpg, currentChannelsPrograms.currentProgramIndex - 1)
                //   handleChannelChange(-1);
              }}>
              <SkipPreviousIcon />
            </IconButton>
          </Focusable>
          <div onKeyUp={(e) => { handleSeekKeyUp(e, -30) }} onKeyDown={(e) => { handleSeekKeyDown(e, -30) }}
            onMouseUp={(e) => { handleSeekKeyUp({ which: keyMap.REMOTE_OK }, -30) }} onMouseDown={(e) => { handleSeekKeyDown({ which: keyMap.REMOTE_OK }, -30) }}
          >
            <Focusable onClickEnter={() => {
              // handleSeek(-30)
            }}>
              <IconButton size="medium" onClick={() => { }}>
                <Replay30Icon />
              </IconButton>
            </Focusable>
          </div>
          <Focusable
            onClickEnter={() => {
              handlePlayPause();
            }}>
            <IconButton
              ref={pauseButtonRef}
              id='pauseButton'
              classes={{ root: styles.bigPlayButton }}
              size="medium"
              onClick={() => {
                handlePlayPause();
              }}
            >
              {/* {videoState.isPlaying ? <AntDesign name={"pause"} size={36} color="#fff" /> : <MaterialIcons name="play-arrow" size={36} color="#fff" />} */}
              {videoState.isPlaying ? (
                <PauseIcon />
              ) : (
                <PlayArrowIcon />
              )}
            </IconButton>
          </Focusable>
          <div onKeyUp={(e) => { handleSeekKeyUp(e, 30) }} onKeyDown={(e) => { handleSeekKeyDown(e, 30) }}
            onMouseUp={(e) => { handleSeekKeyUp({ which: keyMap.REMOTE_OK }, 30) }} onMouseDown={(e) => { handleSeekKeyDown({ which: keyMap.REMOTE_OK }, 30) }}
          >
            <Focusable onClickEnter={() => { }}>
              <IconButton size="medium" onClick={() => { }}>
                <Forward30Icon />
              </IconButton>
            </Focusable>
          </div>
          <Focusable
            onFocus={() => { handleProgramFocus(1) }}
            onUnfocus={() => { handleProgramUnFocus(1) }}
            onClickEnter={() => {
              handleGoToProgram(1)
              // handleOpenArchive(currentChannelsPrograms.fullEpg, currentChannelsPrograms.currentProgramIndex + 1)
              //   handleChannelChange(1);
            }}>
            <IconButton
              size="medium"
              onClick={() => {
                handleGoToProgram(1);
              }}>
              <SkipNextIcon />
            </IconButton>
          </Focusable>
        </div>
        <div className={styles.options}>
          {shiftedDay !== 'LIVE' && <Focusable
            onClickEnter={() => {
              handleGoToLive();
            }}>
            <IconButton
              size="medium"
              onClick={() => {
                handleGoToLive();
              }}>
              <LiveTvIcon />
            </IconButton>
          </Focusable>}
          {/* TODO uncomment for casting this is experimental not working for tizen */}
          {/* <Focusable
            onClickEnter={() => {
              openConnectionModal();
            }}>
            <IconButton
              size="medium"
              onClick={() => {
                openConnectionModal();
              }}>
              {isTvConnected ? <CastConnectedIcon /> : <CastIcon />}
            </IconButton>
          </Focusable> */}
        </div>
        {channelInfo?.archive?.name && <div style={{ top: !!info ? '-85px' : '-10px' }} className={cx(styles.seekBar, { [styles.seekBarGray]: shiftedFromLive.current > (new Date().getMinutes() * 60 + new Date().getSeconds()) })}>
          {shiftedFromLive.current < (new Date().getMinutes() * 60 + new Date().getSeconds()) && <div
            style={{ width: `${(new Date().getMinutes() * 60 + new Date().getSeconds()) / 36}%` }}
            className={styles.liveBorder}
          >
            <div
              className={styles.liveThumb}
            />
          </div>}
          <div
            onKeyDown={handleSeekKeyDown}
            onKeyUp={handleSeekKeyUp}
            style={{ width: `${playedPercent}%` }}
            className={styles.seek}
          >
            <Focusable onFocus={() => { handleCursorFocus(true) }} onUnfocus={() => { handleCursorFocus(false) }} className={styles.seekThumb}>
              <div className={styles.cursorTime} style={{ border: cursorTime ? '1px solid #fff' : 'none', opacity: cursorTime ? 1 : 0, right: playedPercent > 90 ? '230%' : playedPercent < 5 ? '-150%' : '50%' }} >
                <span>
                  {programTimeInfo?.formattedcurrentPlayed}
                </span>
                {/* <VideoJS options={optionsThumbnail} onReady={handlePlayerReadyThumbnail} /> */}
              </div>
            </Focusable>
          </div>
        </div>}
      </div>
    </>
  );
}