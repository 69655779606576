import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PlayerControls from "../../components/LivePlayer/LiveControlls";
import {
  generateM3U8URL,
  getLastChannel,
  getSubuser,
  isLogged,
  logout,
  parse,
  saveLastChannel,
} from "../../utils/helpers";
import jwtDecode from "jwt-decode";
import "video-react/dist/video-react.css"; // import css
import VideoJS from "../../components/playerVideo";
import ChannelsList from "../../components/channelsList/channelsList";
import styles from "./style.module.css";
import EPGList from "./epg/epg";
import {
  base,
  keyMap,
  keymapNumbers,
  keymapNumbersWeb,
} from "../../utils/constants";
import { Navigate, useLocation, useNavigate } from "react-router";
import Header from "../../components/header/header";
import { api } from "../../api/config";
import cx from "classnames";
import VODPlayer from "../../components/VODPlayer";
import axios from "axios";
import { useSnackbar } from "notistack";
import VODControlls from "../../components/VODPlayer/VODControlls";
import EPGFull from "../../components/epgFull";
import Modal from "../../components/Modal";
import { useTranslation } from "react-i18next";
import { selectConnectionAction } from "../../Store/TVConnection/selectors";
import { useSelector } from "react-redux";

export default function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const location = useLocation();
  const channels = useRef([]);
  const controllsRef = useRef();
  const timeoutId = useRef();
  const index = useRef(0);
  const playerRef = useRef(null);
  const [smallView, setSmallView] = useState(false);
  const [fullEpgView, setFullEpgView] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [currentFocusedChannel, setCurrentFocusedChannel] = useState(null);
  const [currentChannelsPrograms, setCurrentChannelsPrograms] = useState();
  const [playerData, setPlayerData] = useState({});
  const [VOD, setVOD] = useState(null);
  const [showInPlayerChannelsList, setShowInPlayerChannelsList] = useState(false);
  const [showInPlayerEPGs, setShowInPlayerEPGs] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const [isArchiveInPlayer, setIsArchiveInPlayer] = useState(false);
  const [numberSearch, setNumberSearch] = useState([]);
  const [dialogOptions, setDialogOptions] = useState({
    title: "",
    open: false,
    setOpen: (val) => setDialogOptions({ ...dialogOptions, open: val }),
    content: "",
    cancelText: "",
    okText: "",
    handleCancel: () => {},
    handleOk: () => {},
  });


  
  const connectionAction = useSelector(selectConnectionAction)

  useEffect(()=>{
    if(connectionAction?.channelId){
      const index = channels.current.findIndex(item=>item._id === connectionAction.channelId)
      if(index > -1){
        handleSelectChannel(channels.current[index], index)
      }
    }
  }, [connectionAction])

  const numTimeOutId = useRef(null);

  //////////////////////////handlers/////////////////////

  const changeToSmallView = (smallView) => {
    const channelList = document.getElementById("channelsList");
    const player = document.getElementById("containerPlayer");
    const epgContainer = document.getElementById("epgContainer");
    if (smallView) {
      channelList.style.width = "550px";
      player.style.width = `${document.body.clientWidth - 550}px`;
      // player.style.height = '60vh';
      player.style.margin = `0px 0px 0px ${550}px`;
      epgContainer.style.width = `${document.body.clientWidth - 550}px`;
      epgContainer.style.height = `${
        window.screen.height - player.clientHeight
      }px`;
    } else {
      channelList.style.width = 0;
      player.style.width = `100%`;
      player.style.margin = `auto 0px`;
    }
  };

  const handleChannelChange = (i, byNum = false) => {
    if (controllsRef?.current?.style) {
      controllsRef.current.style.opacity = 1;
    }
    handleControllsInterval(1000);
    if (byNum && index.current === i - 1) return;
    index.current = byNum
      ? i - 1
      : index.current + i >= channels.current.length
      ? 0
      : index.current + i < 0
      ? channels.current.length - 1
      : index.current + i;
    playerRef.current.handleSrc_(
      [
        {
          src: channels.current[index.current].url,
          type: "application/x-mpegURL",
        },
      ],
      true
    );
    setCurrentChannel(channels.current[index.current]);
  };

  const handleChangeM3U8 = (url) => {
    if (playerRef.current)
      playerRef.current.handleSrc_(
        [
          {
            src: url,
            type: "application/x-mpegURL",
          },
        ],
        true
      );
  };

  const handleSelectChannel = (channel, i) => {
    if (numberSearch.length) return;
    if (smallView && currentChannel?._id === channel._id) {
      setSmallView(!smallView);
      return;
    }
    playerRef.current.handleSrc_(
      [
        {
          src: channel.url,
          type: "application/x-mpegURL",
        },
      ],
      true
    );
    index.current = i;
    setCurrentChannel(channel);
  };

  useEffect(() => {
    if (currentChannel?.url) saveLastChannel(currentChannel?.url);
  }, [currentChannel]);

  const handlePlayerReady = useCallback((player) => {
    playerRef.current = player;
    player.muted(false);
    player.currentTime(0);
    // const i = channels.current.findIndex(
    //   item => item._id === location?.state?.currentChannel._id
    // );
    // handleChannelChange(i === -1 ? 0 : i);

    // // you can handle player events here

    // player.on('error', err => {
    //   // console.log('player error!', err);
    // });

    // player.on('dispose', () => {
    //   // console.log('player will dispose!');
    // });
  }, []);

  const getTariff = async () => {
    try {
      const token = isLogged();
      const decoded = jwtDecode(token);
      const tariffId = decoded.tariff?.[0];
      const res = await api.get(`/channels/channelsByTariffId/${tariffId}`);

      const user = getSubuser();
      if (user?.allowedChannels?.length) {
        channels.current = res?.data?.channels?.filter?.((item) =>
          user.allowedChannels.includes(item._id)
        ) || [];
      } else {
        channels.current = res?.data?.channels?.filter?.((item) => item) || [];
      }
      channels.current = res?.data?.channels?.filter?.((item) => item) || [];
      const lastChannelUrl = getLastChannel();
      let lastChannel;
      if (lastChannelUrl) {
        lastChannel = channels.current.find(
          (item) => item?.url === lastChannelUrl
        );
      }
      setCurrentChannel(lastChannel || channels.current[0]);
    } catch (error) {
      console.log(error);
      if (error.message === "Invalid token specified") {
        logout();
        window.location.reload();
      }
    }
  };

  const handleOpenArchive = async (programs, i) => {
    const list = programs.map((single) => {
      const dateStart = parse(single.start);
      const dateStop = parse(single.stop);
      let isCurrent;
      // if(moment().isSameOrAfter(moment(single.start, 'YYYYMMDDHHmmss')) && moment().isSameOrBefore(moment(single.stop, 'YYYYMMDDHHmmss')) ) {
      //   isCurrent = true
      // }
      // const time = `${dateStart.h}:${dateStart.m} - ${dateStop.h}:${dateStop.m}`
      return {
        start: `${dateStart.h}:${dateStart.m}`,
        stop: `${dateStop.h}:${dateStop.m}`,
        // isCurrent,
        startEpgTime: single.start,
        endEpgTime: single.stop,
        title: single?.title?.[0]?.value,
      };
    });
    const program = programs[i];
    try {
      const options = {
        startTime: program.start.substr(8),
        endTime: program.stop.substr(8),
        day: program.start.substr(6, 2),
        date: program.start.substr(0, 8),
        offset: new Date().getTimezoneOffset(),
        archiveName: currentChannel?.archive?.name,
      };
      if (options.archiveName) {
        const index = await axios.get(generateM3U8URL(options));
        if (
          !index?.data ||
          index?.data === "#EXT-X-ENDLIST" ||
          index?.data.includes("undefined") ||
          index?.data?.status === 404
        ) {
          throw new Error();
        }
        setPlayerData({
          currentProgram: program,
          currentProgramIndex: i,
          listPrograms: list,
          day: program.start.substr(6, 2),
          date: program.start.substr(0, 8),
          archiveName: currentChannel?.archive?.name,
        });
        // console.log(generateM3U8URL(options));
        setVOD(generateM3U8URL(options));
      }
    } catch (error) {
      enqueueSnackbar(
        t(`file for {{range}} does not found`, {
          range: `${program.start.substr(8, 2)}:${program.start.substr(
            10,
            2
          )}-${program.stop.substr(8, 2)}:${program.stop.substr(10, 2)}`,
        }),
        {
          variant: "info",
          autoHideDuration: 3000,
        }
      );
      console.log(error);
    }
  };

  //////////////////////////useEffects/////////////////////

  useEffect(() => {
    if (playerRef.current) changeToSmallView(smallView);
  }, [smallView, playerRef]);

  useEffect(() => {
    (async () => {
      await getTariff();
      if (location?.state?.smallView && location?.state?.currentChannel) {
        setSmallView(location?.state?.smallView);
        changeToSmallView(location?.state?.smallView);
      }
    })();
  }, []);

  const handleControllsInterval = (delta = 15000) => {
    clearInterval(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      if (controllsRef?.current?.style) {
        controllsRef.current.style.opacity = 0;
      }
      const el = document.getElementById("fullContainer")
      if(el?.focus){
        el.focus();
      }
    }, delta);
  };

  useEffect(() => {
    if (!smallView) {
      handleControllsInterval();
    }
  }, [smallView, controllsRef]);

  useEffect(() => {
    if (smallView && isArchiveInPlayer) handleChangeM3U8(currentChannel?.url);
  }, [smallView, isArchiveInPlayer]);

  useEffect(() => {
    currentChannel && setCurrentFocusedChannel(currentChannel);
  }, [currentChannel]);

  const handleKeyDown = (e) => {
    if (controllsRef?.current?.style?.opacity === "1") {
      handleControllsInterval();
      // clearInterval(timeoutId.current);
    } else {
      handleControllsInterval(1000);
    }
    if (
      controllsRef?.current?.style?.opacity === "0" &&
      !showInPlayerChannelsList &&
      !showInPlayerEPGs
    ) {
      if (smallView || dialogOptions.open) return;
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const handleKeyUp = (e) => {
    if (isArchive || dialogOptions.open) return;
    const keys =
      typeof window?.tizen === "undefined" ? keymapNumbersWeb : keymapNumbers;
    if (keys.includes(e.which) && !isArchiveInPlayer) {
      setNumberSearch([...numberSearch, keys.indexOf(e.which)]);

      return;
    }
    if (
      [keyMap.REMOTE_CHANNEL_UP, keyMap.REMOTE_CHANNEL_DOWN].includes(
        e.which
      ) &&
      !isArchiveInPlayer &&
      !smallView &&
      !showInPlayerChannelsList &&
      !showInPlayerEPGs
    ) {
      if (e.which === keyMap.REMOTE_CHANNEL_DOWN) {
        handleChannelChange(-1);
      } else if (e.which === keyMap.REMOTE_CHANNEL_UP) {
        handleChannelChange(1);
      }
    }
    // if(controllsRef?.current?.style?.opacity === '1'){
    //   controllsRef.current.style.opacity = 1
    //   handleControllsInterval()
    //   // clearInterval(timeoutId.current);
    // }
    switch (e.which) {
      case keyMap.REMOTE_OK:
        if (numberSearch.length) {
          clearTimeout(numTimeOutId.current);
          if (+numberSearch.join("") <= channels.current.length) {
            handleChannelChange(+numberSearch.join(""), true);
          }
          setTimeout(() => {
            setNumberSearch([]);
          }, 100);
          return;
        }
        if (!showInPlayerChannelsList && !showInPlayerEPGs) {
          if (controllsRef?.current?.style?.opacity === "0") {
            controllsRef.current.style.opacity = 1;
            handleControllsInterval();
            e.preventDefault();
            e.stopPropagation();
          }
          // clearInterval(timeoutId.current);
          // if (controllsRef.current) {
          //   controllsRef.current.style.opacity = 1;
          //   timeoutId.current = setTimeout(() => {
          //     if(controllsRef?.current?.style?.opacity){
          //       controllsRef.current.style.opacity = 0;
          //       document.getElementById('fullContainer').focus();
          //     }
          //   }, 20000);
          // }
        }
        break;
      case keyMap.ARROW_LEFT:
        if (showInPlayerEPGs) {
          const today = document.querySelector(".selectedDay");
          if (today?.parentNode) today.parentNode.focus();
          e.preventDefault();
          e.stopPropagation();
          break;
        }
        if (controllsRef?.current?.style?.opacity === "0") {
          setShowInPlayerChannelsList(true);
          e.preventDefault();
          e.stopPropagation();
          break;
        }
        break;
      case keyMap.ARROW_RIGHT:
        if (showInPlayerEPGs) {
          const currentProgram = document.querySelector(".currentProgram");
          if (currentProgram?.parentNode) currentProgram.parentNode.focus();
          e.preventDefault();
          e.stopPropagation();
          break;
        }
        if (showInPlayerChannelsList) {
          setShowInPlayerChannelsList(false);
          e.preventDefault();
          e.stopPropagation();
        } else if (controllsRef?.current?.style?.opacity === "0") {
          setShowInPlayerEPGs(true);
          e.preventDefault();
          e.stopPropagation();
        }
        break;
      case keyMap.REMOTE_BACK:
        if (showInPlayerChannelsList) {
          setShowInPlayerChannelsList(false);
          document.getElementById("pauseButton").parentNode.focus();
        } else if (
          controllsRef?.current?.style &&
          controllsRef?.current?.style?.opacity !== "0"
        ) {
          controllsRef.current.style.opacity = 0;
          e.preventDefault();
          e.stopPropagation();
        } else if (showInPlayerEPGs) {
          setShowInPlayerEPGs(false);
          document.getElementById("pauseButton").parentNode.focus();
        } else if (isArchiveInPlayer) {
          setDialogOptions({
            title: t("returnText"),
            open: true,
            setOpen: (val) => setDialogOptions({ ...dialogOptions, open: val }),
            content: "",
            cancelText: t("settings.Cancel"),
            okText: t("okText"),
            handleCancel: () => {
              setDialogOptions({ ...dialogOptions, open: false });
              controllsRef.current.style.opacity = 1;
              handleControllsInterval();
              document.getElementById("pauseButton").parentNode.focus();
            },
            handleOk: () => {
              setDialogOptions({ ...dialogOptions, open: false });
              setSmallView(!smallView);
            },
          });
        } else {
          if(!smallView){
            currentChannel && setCurrentFocusedChannel(currentChannel);
          }
          smallView ? navigate("/main") : setSmallView(!smallView);
        }
        break;
      case keyMap.KEY_D:
        if (showInPlayerChannelsList) {
          setShowInPlayerChannelsList(false);
          document.getElementById("pauseButton").parentNode.focus();
        } else if (
          controllsRef?.current?.style &&
          controllsRef?.current?.style?.opacity !== "0"
        ) {
          controllsRef.current.style.opacity = 0;
          e.preventDefault();
          e.stopPropagation();
        } else if (showInPlayerEPGs) {
          setShowInPlayerEPGs(false);
          document.getElementById("pauseButton").parentNode.focus();
        } else if (isArchiveInPlayer) {
          setDialogOptions({
            title: t("returnText"),
            open: true,
            setOpen: (val) => setDialogOptions({ ...dialogOptions, open: val }),
            content: "",
            cancelText: t("settings.Cancel"),
            okText: t("okText"),
            handleCancel: () => {
              setDialogOptions({ ...dialogOptions, open: false });
              controllsRef.current.style.opacity = 1;
              handleControllsInterval();
              document.getElementById("pauseButton").parentNode.focus();
            },
            handleOk: () => {
              setDialogOptions({ ...dialogOptions, open: false });
              setSmallView(!smallView);
            },
          });
        } else {
          smallView ? navigate("/main") : setSmallView(!smallView);
        }
        break;
      default:
        if (!smallView) {
          e.preventDefault();
          e.stopPropagation();
        }
        break;
    }
  };

  useEffect(() => {
    if (playerRef.current) {
      if (isArchive) {
        playerRef.current.pause();
      } else {
        playerRef.current.load();
        playerRef.current.handleSrc_(
          [
            {
              src: currentChannel?.url,
              type: "application/x-mpegURL",
            },
          ],
          true
        );
        playerRef.current.play();
      }
    }
  }, [isArchive, currentChannel, playerRef]);

  useEffect(() => {
    isArchive && setIsArchive(false);
  }, [showInPlayerEPGs]);

  useEffect(() => {
    if (!numberSearch.length) return;
    if (numTimeOutId.current) {
      clearTimeout(numTimeOutId.current);
    }

    numTimeOutId.current = setTimeout(() => {
      if (+numberSearch.join("") <= channels.current.length) {
        handleChannelChange(+numberSearch.join(""), true);
      }
      setNumberSearch([]);
    }, 2000);
  }, [numberSearch]);

  return (
    <div
      id="fullContainer"
      className={styles.liveScreen}
      style={{backgroundColor: smallView ? "transparent" : "#000000"}}
      onKeyUpCapture={handleKeyUp}
      onKeyDownCapture={handleKeyDown}
      onClick={(e)=>{
        if(controllsRef?.current?.style){
          controllsRef.current.style.opacity = 1;
        }
      }}
    >
      {!!numberSearch.length && (
        <div className={styles.numberBox}>{numberSearch.join("")}</div>
      )}
      <div
        className={cx({
          [styles.smallView]: smallView,
          [styles.fullScreenView]: !smallView,
        })}
        id="containerPlayer"
      >
        <VideoJS isArchive={isArchive} onReady={handlePlayerReady} />

        {!smallView && !isArchive && (
          <div
            id="controllsContainer"
            ref={controllsRef}
            className={styles.controllsContainer}
          >
            <PlayerControls
              timeoutId={timeoutId}
              controllsRef={controllsRef}
              channelInfo={currentChannel}
              videoRef={playerRef}
              handleChannelChange={handleChannelChange}
              currentChannelsPrograms={currentChannelsPrograms}
              setCurrentChannelsPrograms={setCurrentChannelsPrograms}
              handleOpenArchive={handleOpenArchive}
              setIsArchive={setIsArchiveInPlayer}
              dialogOptions={dialogOptions}
              setDialogOptions={setDialogOptions}
            />
          </div>
        )}
        {/* {!smallView && VOD && <VODControlls
          handleVODBackClick={handleVODBackClick}
          setInfo={setPlayerData}
          info={playerData}
          videoRef={playerRef}
          handleChannelChange={()=>{}}
        />} */}
        {showInPlayerChannelsList && !smallView && (
          <ChannelsList
            list={channels.current}
            classes={[styles.inPlayerChannels]}
            currentChannel={currentChannel}
            handleSelectChannel={handleSelectChannel}
            currentFocusedChannel={currentFocusedChannel}
            setCurrentFocusedChannel={setCurrentFocusedChannel}
            setFullEpgView={setFullEpgView}
            isInPlayer={showInPlayerChannelsList}
          />
        )}
        {showInPlayerEPGs && !smallView && (
          <EPGFull
            setShowInPlayerEPGs={setShowInPlayerEPGs}
            setIsArchive={setIsArchive}
            classes={[styles.inPlayerEPGs]}
            channel={currentChannel}
          />
        )}
      </div>
      {/* {VOD && <VODPlayer
        setInfo={setPlayerData} info={playerData} handleBackClick={handleVODBackClick} url={VOD}
      />} */}
      {/* {smallView && (<>
      <div  id="channelsList" className="channelsList">
      </div>
      <div className='epgContainer' id='epgContainer'>
        <EPGList fullEpgView={fullEpgView} setFullEpgView={setFullEpgView} currentChannel={currentChannel} />
      </div>
      </>)} */}

      <div id="channelsList" className={styles.channelsList}>
     
        {smallView && (
          <ChannelsList
            list={channels.current}
            currentChannel={currentChannel}
            handleSelectChannel={handleSelectChannel}
            currentFocusedChannel={currentFocusedChannel}
            setCurrentFocusedChannel={setCurrentFocusedChannel}
            setFullEpgView={setFullEpgView}
          />
        )}
      </div>
      <div
        className={styles.epgContainer}
        style={{ opacity: smallView ? 1 : 0 }}
        id="epgContainer"
      >
        <EPGList
          setCurrentChannelsPrograms={setCurrentChannelsPrograms}
          currentChannel={currentChannel}
          currentFocusedChannel={currentFocusedChannel}
        />
      </div>

      <Modal {...dialogOptions} />
    </div>
  );
}
