import { IconButton } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import SpatialNavigation, {
  Focusable,
  FocusableSection,
} from "react-js-spatial-navigation";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Forward30Icon from "@material-ui/icons/Forward30";
import Replay30Icon from "@material-ui/icons/Replay30";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import styles from "./styles.module.css";
import { useSnackbar } from "notistack";
import axios from "axios";
import { keyMap } from "../../../utils/constants";
import moment from "moment";
import { generateM3U8URL } from "../../../utils/helpers";
import { usePrevious } from "../../../utils/customHooks";
import { api } from "../../../api/config";
import { ClipLoader } from "react-spinners";
import Modal from "../../Modal";
import { useTranslation } from "react-i18next";

export default function PlayerControls({
  handleChannelChange,
  videoRef,
  info,
  handleBackClick,
  setInfo,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [programInfo, setProgramInfo] = useState(info);
  const [cursorTime, setCursorTime] = useState(false);
  const [playedPercent, setPlayedPercent] = useState(0);
  const [corruptedPercents, setCorruptedPercents] = useState([]);
  const [programTimeInfo, setProgramTimeInfo] = useState({});
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbLoading, setThumbLoading] = useState(false);
  const [videoState, setVideoState] = useState({
    isPlaying: true,
  });

  const [dialogOptions, setDialogOptions] = useState({
    title: "",
    open: false,
    setOpen: (val) => setDialogOptions({ ...dialogOptions, open: val }),
    content: "",
    cancelText: "",
    okText: "",
    handleCancel: () => {},
    handleOk: () => {},
  });

  const prevThumbnailURL = usePrevious(thumbnailURL);

  const controllsRef = useRef(null);
  const prevProgRef = useRef(null);
  const nextProgRef = useRef(null);
  const pauseButtonRef = useRef(null);
  const timeoutOpacityId = useRef(null);
  const playerIntervalId = useRef(null);
  const isModalHandled = useRef(null);

  const handleControllsInterval = () => {
    clearInterval(timeoutOpacityId.current);
    timeoutOpacityId.current = setTimeout(() => {
      if (controllsRef?.current?.style) {
        controllsRef.current.style.opacity = 0;
      }
      const el = document.getElementById("fullContainer")
      if(el?.focus){
        el.focus();
      }
    }, 15000);
  };

  const handlePlayPause = async () => {
    videoState.isPlaying ? videoRef.current.pause() : videoRef.current.play();
    setVideoState({ ...videoState, isPlaying: !videoState.isPlaying });
  };

  const handleMute = async () => {
    // videoState.isMuted ? await videoRef.current.setVolumeAsync(videoState.volume) : await videoRef.current.setVolumeAsync(0)
    // setVideoState({...videoState, isMuted: !videoState.isMuted})
  };

  //   const handleVolumeChange = async e => {
  //     await videoRef.current.setVolumeAsync(e);
  //     // setVideoState({...videoState, volume: e})
  //   };

  useEffect(() => {
    if (programInfo?.corrupteds?.length && videoRef.current) {
      let duration = getVideoDuration(programInfo, videoRef);
      const corruptedPercents = programInfo.corrupteds.map((item) => {
        const left = (item.range.fromSecond * 100) / duration;
        const width =
          ((item.range.toSecond - item.range.fromSecond) * 100) / duration;
        const corruptedSeconds = item.range.toSecond - item.range.fromSecond;
        return { left, width, corruptedSeconds };
      });
      setCorruptedPercents(corruptedPercents);
    }
  }, [programInfo, videoRef.current]);

  const handleProgramFocus = (delta) => {
    if (delta < 0) prevProgRef.current.style.opacity = 1;
    else nextProgRef.current.style.opacity = 1;
  };
  const handleProgramUnFocus = (delta) => {
    if (delta < 0) prevProgRef.current.style.opacity = 0;
    else nextProgRef.current.style.opacity = 0;
  };
  const handleVODChange = async (delta) => {
    const program =
      programInfo?.listPrograms?.[programInfo.currentProgramIndex + delta];
    if (program.isCurrent) return;
    try {
      const options = {
        startTime: `${program.start.replace(":", "")}00`,
        endTime: `${program.stop.replace(":", "")}00`,
        day: programInfo.day,
        offset: new Date().getTimezoneOffset(),
        date: programInfo.date,
        archiveName: programInfo.archiveName,
        baseArchiver: info.currentChannel.archive.archiverInfo.host,
      };
      if (options.archiveName) {
        const index = await axios.get(generateM3U8URL(options));
        if (
          !index?.data ||
          index?.data === "#EXT-X-ENDLIST" ||
          index?.data.includes("undefined") ||
          index?.data?.status === 404
        ) {
          throw new Error();
        }
        setProgramInfo({
          currentProgram: program,
          currentProgramIndex: programInfo.currentProgramIndex + delta,
          listPrograms: programInfo?.listPrograms,
          day: programInfo.day,
          currentChannel: info.currentChannel,
          date: programInfo.date,
          archiveName: programInfo.archiveName,
          corrupteds: JSON.parse(index.headers["content-corrupted-parts"]),
        });
        videoRef.current.handleSrc_(
          [
            {
              src: generateM3U8URL(options),
              type: "application/x-mpegURL",
            },
          ],
          true
        );
      }
    } catch (error) {
      enqueueSnackbar(
        t("file for {{range}} does not found", {
          range: `${program.start}-${program.stop}`,
        }),
        {
          variant: "info",
          autoHideDuration: 3000,
        }
      );
      console.log(error);
    }
  };
  const handleSeek = (delta) => {
    videoRef.current.currentTime(videoRef.current.currentTime() + delta);
  };
  const handleSeekTo = (delta) => {
    videoRef.current.currentTime(delta);
  };

  const getVideoDuration = (programInfo, videoRef) => {
    const corruptedOverallLength = programInfo?.corrupteds?.reduce(
      (acc, item) => {
        acc += item?.range?.toSecond - item?.range?.fromSecond;
        return acc;
      },
      0
    );
    let duration = videoRef.current.duration();
    if (corruptedOverallLength) {
      duration += corruptedOverallLength;
    }
    return duration;
  };

  const getDeltaTime = (programInfo, currentTime) => {
    let delta = 0;
    programInfo.corrupteds.forEach((item) => {
      if (currentTime >= item.range.fromSecond) {
        delta += item.range.toSecond - item.range.fromSecond;
      }
    });
    return delta;
  };

  const handleSeekKeyUp = (e) => {
    if (dialogOptions.open) return;
    e.preventDefault();
    e.stopPropagation();
    if ([keyMap.ARROW_LEFT, keyMap.ARROW_RIGHT].includes(e.which)) {
      const duration = getVideoDuration(programInfo, videoRef);

      let currentTime = (playedPercent * duration) / 100;

      let delta = getDeltaTime(programInfo, currentTime);
      currentTime = (playedPercent * duration) / 100 - delta;

      handleSeekTo(+currentTime.toFixed());
      if (videoRef.current.paused) {
        videoRef.current.play();
      }
      playerIntervalId.current = setInterval(handlePlayerInterval, 1000);
    }
  };

  const handleSeekKeyDown = (e) => {
    handleControllsInterval();
    if (dialogOptions.open) return;
    if ([keyMap.ARROW_LEFT, keyMap.ARROW_RIGHT].includes(e.which)) {
      e.preventDefault();
      e.stopPropagation();
      clearInterval(playerIntervalId.current);
    }
    const duration = getVideoDuration(programInfo, videoRef);

    let percent = playedPercent;
    const currentTime = ((playedPercent + 1) * duration) / 100;
    let delta = getDeltaTime(programInfo, currentTime);
    switch (e.which) {
      case keyMap.ARROW_RIGHT:
        if (playedPercent + 1 <= 100) {
          const currentTime = ((playedPercent + 1) * duration) / 100;
          const format = duration < 3600 ? "mm:ss" : "HH:mm:ss";
          const formattedDuration = moment
            .utc(moment.duration(duration, "seconds").asMilliseconds())
            .format(format);
          const formattedcurrentPlayed = moment
            .utc(moment.duration(currentTime, "seconds").asMilliseconds())
            .format(format);
          setProgramTimeInfo({ formattedDuration, formattedcurrentPlayed });
          setPlayedPercent(playedPercent + 1);
        }
        break;
      case keyMap.ARROW_LEFT:
        if (playedPercent - 1 >= 0) {
          if (playedPercent < 98 && isModalHandled.current) {
            isModalHandled.current = false;
          }
          const currentTime = ((playedPercent - 1) * duration) / 100;
          const format = duration < 3600 ? "mm:ss" : "HH:mm:ss";
          const formattedDuration = moment
            .utc(moment.duration(duration, "seconds").asMilliseconds())
            .format(format);
          const formattedcurrentPlayed = moment
            .utc(moment.duration(currentTime, "seconds").asMilliseconds())
            .format(format);
          setProgramTimeInfo({ formattedDuration, formattedcurrentPlayed });
          setPlayedPercent(playedPercent - 1);
        }
        break;

      default:
        break;
    }
  };

  const handleCursorFocus = (focus) => {
    setCursorTime(focus);
  };

  const handleKeyPress = (e) => {
    if (dialogOptions.open) return;
    switch (e.which) {
      case keyMap.REMOTE_BACK:
        if (controllsRef.current.style.opacity === "0") {
          setDialogOptions({
            title: t("returnText"),
            open: true,
            setOpen: (val) => setDialogOptions({ ...dialogOptions, open: val }),
            content: "",
            cancelText: t("settings.Cancel"),
            okText: t("okText"),
            handleCancel: () => {
              setDialogOptions({ ...dialogOptions, open: false });
              controllsRef.current.focus();
              controllsRef.current.style.opacity = 1;
              handleControllsInterval();
              document.getElementById("pauseButton").parentNode.focus();
            },
            handleOk: handleBackClick,
          });
        } else {
          clearInterval(timeoutOpacityId.current);
          controllsRef.current.style.opacity = 0;
        }
        break;
      case keyMap.KEY_D:
        if (controllsRef.current.style.opacity === "0") {
          setDialogOptions({
            title: t("returnText"),
            open: true,
            setOpen: (val) => setDialogOptions({ ...dialogOptions, open: val }),
            content: "",
            cancelText: t("settings.Cancel"),
            okText: t("okText"),
            handleCancel: () => {
              setDialogOptions({ ...dialogOptions, open: false });
              controllsRef.current.focus();
              controllsRef.current.style.opacity = 1;
              handleControllsInterval();
              document.getElementById("pauseButton").parentNode.focus();
            },
            handleOk: handleBackClick,
          });
        } else {
          clearInterval(timeoutOpacityId.current);
          controllsRef.current.style.opacity = 0;
        }
        break;
      default:
        break;
    }
    if (controllsRef.current.style.opacity === "0") {
      e.preventDefault();
      e.stopPropagation();
      if (e.which === keyMap.REMOTE_OK) {
        clearInterval(timeoutOpacityId.current);
        if (controllsRef.current) {
          handleHideControlsInterval();
        }
      }
    } else if (controllsRef.current.style.opacity !== "0") {
      clearInterval(timeoutOpacityId.current);
      if (controllsRef.current) {
        handleHideControlsInterval();
      }
    }
  };

  const handleHideControlsInterval = (willShow = true) => {
    if (willShow) {
      controllsRef.current.style.opacity = 1;
      handleControllsInterval();
    }
    // timeoutOpacityId.current = setTimeout(() => {
    //   if(controllsRef?.current?.style?.opacity){
    //     controllsRef.current.style.opacity = 0;
    //     pauseButtonRef.current.parentNode.focus()
    //   }
    // }, 20000);
  };

  const handlePlayerInterval = () => {
    let currentTime = videoRef.current.currentTime();
    const duration = getVideoDuration(programInfo, videoRef);

    let percent = (currentTime * 100) / duration;
    let delta = 0;

    if (corruptedPercents?.length) {
      delta = getDeltaTime(programInfo, currentTime);

      corruptedPercents.forEach((item) => {
        const start = item?.left;
        const end = item?.left + item?.width;
        if (percent >= start) {
          percent += item?.width;
        }
      });
    }
    setPlayedPercent(percent);
    const format = duration < 3600 ? "mm:ss" : "HH:mm:ss";
    const formattedDuration = moment
      .utc(moment.duration(duration, "seconds").asMilliseconds())
      .format(format);
    const formattedcurrentPlayed = moment
      .utc(moment.duration(currentTime + delta, "seconds").asMilliseconds())
      .format(format);
    setProgramTimeInfo({ formattedDuration, formattedcurrentPlayed });
    if (playedPercent > 98 && !isModalHandled.current) {
      isModalHandled.current = true;
      setDialogOptions({
        title: "Program is about to end. Want to continue?",
        open: true,
        setOpen: (val) => setDialogOptions({ ...dialogOptions, open: val }),
        content: "",
        cancelText: "Return to EPG",
        okText: "Continue",
        handleCancel: () => {
          setDialogOptions({ ...dialogOptions, open: false });
          videoRef.current.on("ended", handleBackClick);
          controllsRef.current.focus();
          controllsRef.current.style.opacity = 1;
          handleControllsInterval();
          document.getElementById("pauseButton").parentNode.focus();
        },
        handleOk: () => {
          setDialogOptions({ ...dialogOptions, open: false });
          videoRef.current.on("ended", () => {
            handleVODChange(1);
          });
          pauseButtonRef.current.parentNode.focus();
        },
      });
    }
  };

  useEffect(() => {
    if (controllsRef.current) {
      handleHideControlsInterval();
    }
  }, [controllsRef]);

  useEffect(() => {
    if (videoRef) {
      playerIntervalId.current = setInterval(handlePlayerInterval, 1000);
    }
    return () => {
      clearInterval(playerIntervalId.current);
    };
  }, [videoRef]);

  useEffect(() => {
    pauseButtonRef.current.parentNode.focus();
    return () => {
      clearInterval(timeoutOpacityId.current);
      clearInterval(playerIntervalId.current);
    };
  }, []);

  // useEffect(()=>{
  //   corruptedPercents.forEach((item)=>{
  //     const start = item.left
  //     const end = item.left + item.width
  //     if(playedPercent>=start && playedPercent<end){
  //       setPlayedPercent(end)
  //     }

  //   })
  // }, [playedPercent])

  // const generateThumbnailUrl = (programInfo, percent) => {
  //   const start = programInfo.currentProgram.startEpgTime
  //   const duration = videoRef?.current?.duration()
  //   const currentPlayed = duration * percent/100
  //   if(currentPlayed){
  //     const momentVOD = moment(start, 'YYYYMMDDHHmmss').add(currentPlayed, 'seconds')
  //     const url = `${baseArchiver}/archives/${programInfo.archiveName}/${momentVOD.format('DD/HH')}0000/images/${Math.ceil((+momentVOD.format('mm') * 60 + +momentVOD.format('ss')))+1}.jpeg`
  //     return url
  //   }
  // }

  // useEffect(()=>{
  //   setThumbnailURL(generateThumbnailUrl(programInfo, playedPercent));
  // }, [playedPercent])

  // const getAndStoreThumbnail = async (thumbnailURL) => {
  //     try {
  //       setThumbLoading(true)
  //       const img = await axios.get(thumbnailURL, { responseType: 'arraybuffer' })
  //       const dataUrl = `data:image/png;base64, ${Buffer.from(img.data).toString('base64')}`
  //       setThumbnail(dataUrl)
  //       setThumbLoading(false)
  //     } catch (error) {
  //       setThumbLoading(false)
  //       console.log('error getting thumbnail', error);
  //       setThumbnail(null)
  //     }
  // }

  // useEffect(()=>{
  //   getAndStoreThumbnail(thumbnailURL)
  // }, [thumbnailURL])

  return (
    // <FocusableSection defaultElement={styles.bigPlayButton}>
    <div
      onKeyUpCapture={handleKeyPress}
      id="controllsContainer"
      ref={controllsRef}
      className={styles.controllsContainer}
    >
      <div className={styles.playerContainer}>
        <div ref={prevProgRef} className={styles.prevProgram}>
          <span className={styles.time}>
            {
              programInfo?.listPrograms?.[programInfo.currentProgramIndex - 1]
                ?.start
            }{" "}
            -{" "}
            {
              programInfo?.listPrograms?.[programInfo.currentProgramIndex - 1]
                ?.stop
            }
          </span>
          <span>
            {
              programInfo?.listPrograms?.[programInfo.currentProgramIndex - 1]
                ?.title
            }
          </span>
        </div>
        <div ref={nextProgRef} className={styles.nextProgram}>
          <span>
            {
              programInfo?.listPrograms?.[programInfo.currentProgramIndex + 1]
                ?.title
            }
          </span>
          <span className={styles.time}>
            {
              programInfo?.listPrograms?.[programInfo.currentProgramIndex + 1]
                ?.start
            }{" "}
            -{" "}
            {
              programInfo?.listPrograms?.[programInfo.currentProgramIndex + 1]
                ?.stop
            }
          </span>
        </div>
        {/* <BlurView intensity={90} tint="dark"  style={styles.blurContainer}> */}
        <div>
          <div className={styles.programInfo}>
            {/* <MaterialIcons name="play-arrow" size={24} color="#F7E975" /> */}
            <span style={{ color: "#F7E975" }}>
              {" "}
              &nbsp; {programInfo?.currentProgram?.title} &nbsp;{" "}
            </span>
            <span className={styles.currentTime}>
              {" "}
              | {programTimeInfo.formattedcurrentPlayed} /{" "}
              {programTimeInfo.formattedDuration}{" "}
            </span>
          </div>
        </div>
        {/* {/* <FocusableSection  */}
        {/* // enterTo='last-focused'  */}
        {/* > */}
        <div className={styles.playButtonsContainer}>
          <Focusable
            onFocus={() => {
              handleProgramFocus(-1);
            }}
            onUnfocus={() => {
              handleProgramUnFocus(-1);
            }}
            onClickEnter={() => {
              handleVODChange(-1);
            }}
          >
            <IconButton
              size="medium"
              onClick={() => {
                handleVODChange(-1);
              }}
            >
              <SkipPreviousIcon />
            </IconButton>
          </Focusable>
          <Focusable
            onClickEnter={() => {
              handleSeek(-30);
            }}
          >
            <IconButton
              size="medium"
              onClick={() => {
                handleSeek(-30);
              }}
            >
              <Replay30Icon />
            </IconButton>
          </Focusable>
          <Focusable
            onClickEnter={() => {
              handlePlayPause();
            }}
          >
            <IconButton
              ref={pauseButtonRef}
              id="pauseButton"
              classes={{ root: styles.bigPlayButton }}
              size="medium"
              onClick={() => {
                handlePlayPause();
              }}
            >
              {/* {videoState.isPlaying ? <AntDesign name={"pause"} size={36} color="#fff" /> : <MaterialIcons name="play-arrow" size={36} color="#fff" />} */}
              {videoState.isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
          </Focusable>
          <Focusable
            onClickEnter={() => {
              handleSeek(30);
            }}
          >
            <IconButton
              size="medium"
              onClick={() => {
                handleSeek(30);
              }}
            >
              <Forward30Icon />
            </IconButton>
          </Focusable>
          <Focusable
            onUnfocus={() => {
              handleProgramUnFocus(1);
            }}
            onFocus={() => {
              handleProgramFocus(1);
            }}
            onClickEnter={() => {
              handleVODChange(1);
            }}
          >
            <IconButton
              size="medium"
              onClick={() => {
                handleVODChange(1);
              }}
            >
              <SkipNextIcon />
            </IconButton>
          </Focusable>
        </div>
        <div className={styles.options}>
          {/* <Focusable
            onClickEnter={() => {
              handleMute();
            }}>
            <IconButton
              size="medium"
              onClick={() => {
                handleMute();
              }}>
              <LiveTvIcon />
            </IconButton>
          </Focusable> */}
        </div>
        {/* </FocusableSection> */}

        <div className={styles.seekBar}>
          {corruptedPercents?.map((item) => (
            <div
              key={item.left}
              className={styles.corrupted}
              style={{ width: `${item.width}%`, left: `${item.left}%` }}
            />
          ))}
          <div
            onKeyDownCapture={handleSeekKeyDown}
            onKeyUpCapture={handleSeekKeyUp}
            className={styles.seek}
            style={{ width: `${playedPercent}%` }}
          >
            <Focusable
              onFocus={() => {
                handleCursorFocus(true);
              }}
              onUnfocus={() => {
                handleCursorFocus(false);
              }}
              className={styles.seekThumb}
            >
              <div
                className={styles.cursorTime}
                style={{
                  border: cursorTime ? "1px solid #fff" : "none",
                  opacity: cursorTime ? 1 : 0,
                  right:
                    playedPercent > 90
                      ? "230%"
                      : playedPercent < 5
                      ? "-150%"
                      : "50%",
                }}
              >
                <span>{programTimeInfo.formattedcurrentPlayed}</span>
                {/* <img width={210} height={120} src={thumbnail}/> */}
              </div>
            </Focusable>
          </div>
        </div>
      </div>
      <Modal {...dialogOptions} />
    </div>
  );
}

// var styles = {
//   playPause: {
//     marginHorizontal: 0,
//     paddingHorizontal: 0,
//     width: 70,
//     height: 70,
//     borderRadius: 70,
//     backgroundColor: '#6881F7',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },

//   live: {
//     display: 'flex',
//     justifyContent: 'center',
//     flexDirection: 'row',
//     alignItems: 'center',
//   },
//   blurContainer: {
//     flex: 1,
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
// };
