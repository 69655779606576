import { Button, IconButton } from "@material-ui/core";
import { BackspaceOutlined } from "@material-ui/icons";
import React, { useEffect } from "react";
import SpatialNavigation, { Focusable } from "react-js-spatial-navigation";
import { useNavigate } from "react-router";
import { keyMap, subuserRoles } from "../../utils/constants";
import { getSubuser, logout } from "../../utils/helpers";
import styles from "./settings.module.css";
import cx from "classnames";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export default function Settings() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLogOut = () => {
    logout();
    navigate("/");
  };

  const initialFocusedRef = useRef(null);
  const subuser = getSubuser();

  const handleKeyPress = (e) => {
    switch (e.which) {
      case keyMap.REMOTE_BACK:
        navigate("/main");
        break;
      case keyMap.KEY_D:
        navigate("/main");
        break;
      default:
        break;
    }
  };

  const actions = [
    {
      label: "Add User",
      disabled: false,
      onEnter: () => {
        navigate("/add_users");
      },
      roles: [subuserRoles.subAdmin],
    },
    {
      label: "Users_settings",
      disabled: false,
      onEnter: () => {
        navigate("/users_settings");
      },
      roles: [subuserRoles.subAdmin],
    },
    {
      label: "Change password",
      disabled: false,
      onEnter: () => {
        navigate("/change_password");
      },
      roles: [subuserRoles.subAdmin],
    },
    {
      label: "Languages",
      disabled: false,
      onEnter: () => {
        navigate("/languages");
      },
    },
    {
      label: "Logout",
      color: "secondary",
      onEnter: () => {
        handleLogOut();
      },
    },
  ];

  useEffect(() => {
    initialFocusedRef.current.parentNode.focus();
  }, []);

  return (
    <div onKeyUp={handleKeyPress} className={styles.settings}>
      {actions
        .filter((item) =>
          item.roles ? item.roles.includes(subuser?.role) : true
        )
        .map((item, i) => (
          <Focusable
            className={styles.focusable}
            key={item.label}
            onClickEnter={item.onEnter}
          >
            <div
              ref={i === 0 ? initialFocusedRef : null}
              className={cx(styles.actionBtn, {
                [styles.disabledBtn]: item.disabled,
              })}
              onClick={item.onEnter}
              disabled={item.disabled}
              variant="contained"
              color={item.color || "default"}
            >
              {t(`settings.${item.label}`)}
            </div>
          </Focusable>
        ))}
    </div>
  );
}
