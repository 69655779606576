import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Focusable } from "react-js-spatial-navigation";
import { useNavigate } from "react-router";
import { ClipLoader } from "react-spinners";
import { api } from "../../../api/config";
import { keyMap } from "../../../utils/constants";
import { getActiveUser } from "../../../utils/helpers";
import styles from "../AddUser/styles.module.css";

const ChangePass = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const current = useRef(null);
  const newPass = useRef(null);
  const confirm = useRef(null);
  const btn = useRef(null);
  const inputsValues = {};
  const [passwordsError, setPasswordsError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(newPass?.current?.parentNode)
    newPass.current.parentNode.focus();
  }, [newPass?.current]);

  function goBack(e) {
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-1);
    }
  }

  function handleClick(ref) {
    ref.current.focus();
  }

  function handleEnter(e, ref) {
    e.preventDefault();
    inputsValues[e.target.name] = e.target.value;
    let code = e.which;
    if (code === keyMap.REMOTE_OK) {
      setTimeout(() => {
        if (ref.current.id === "myButton") {
          current.current.focus();
        } else {
          ref.current.parentNode.focus();
        }
      }, 10);
    }
    if (code === keyMap.ARROW_UP && ref.current.name === "confirm_password") {
      newPass.current.parentNode.focus();
    }

    if (code === keyMap.ARROW_DOWN) {
      ref.current.name === "new_password"
        ? confirm.current.parentNode.focus()
        : ref.current.name === "confirm_password"
        ? btn.current.parentNode.focus()
        : confirm.current.parentNode.focus();
    }
  }


  const handleChangePass = async () => {
    if (inputsValues?.confirm_password !== inputsValues?.new_password) {
      setPasswordsError(true)
      return
    }
    setPasswordsError(false)
    const user = getActiveUser()
    setLoading(true)
    const res = await api.patch(`/users/${user?.id}`, { password: inputsValues.new_password })
    if (res?.data?.status === "success") {
      enqueueSnackbar(
        t(`Password updated`),
        {
          variant: "success",
          autoHideDuration: 3000,
        }
      );
      return;
    }
    enqueueSnackbar(res?.message || res?.errorMsg, {
      variant: "error",
      autoHideDuration: 3000,
    });
    setLoading(false)
  }

  return (
    <div className={styles.mainAddUser} onKeyUpCapture={(e) => goBack(e)}>
      <div className={styles.containerAddUser}>
        <div className={styles.addUser}>{t(`settings.Change password`)}</div>
        <div className={styles.form}>
          {/* <Focusable
            className={styles.focusable}
            onClickEnter={() => handleClick(current)}
          >
            <input
              name="current_password"
              className={styles.name}
              placeholder={t(`settings.Change`)}
              onKeyUp={(e) => handleEnter(e, current)}
              ref={current}
            />
          </Focusable> */}
          <Focusable
            className={styles.focusable}
            onClickEnter={() => handleClick(newPass)}
          >
            <input
            onClick={() => handleClick(newPass)}
              name="new_password"
              className={styles.pin}
              placeholder={t(`settings.New password*`)}
              onKeyUp={(e) => handleEnter(e, newPass)}
              ref={newPass}
              type='password'
            />
          </Focusable>

          <Focusable
            className={styles.focusable}
            onClickEnter={() => handleClick(confirm)}
          >
            <input
            onClick={() => handleClick(confirm)}
              name="confirm_password"
              className={styles.pin}
              placeholder={t(`settings.Conform password*`)}
              onKeyUp={(e) => handleEnter(e, confirm)}
              ref={confirm}
              type='password'
            />
          </Focusable>
          {(!!passwordsError) && <span className={styles.helperText}> {t('passwords_not_match')} </span>}
          <Focusable
            className={styles.focusable}
            onClickEnter={() => handleChangePass()}
          >
            <button
            onClick={() => handleChangePass()}
              className={styles.btn}
              id="myButton"
              ref={btn}
              // onClick={(e) => handleChangePass(e, btn)}
            >
              {loading && <ClipLoader size={50} color='#fff' cssOverride={{ borderWidth: 3 }} />} <span style={{ marginLeft: loading ? 20 : 0 }}>{t(`settings.Change`)}</span>
            </button>
          </Focusable>
        </div>
      </div>
    </div>
  );
};

export default ChangePass;
