import * as serviceWorker from './serviceWorker';
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import ErrorBoundary from "./routesHOC/errorboundary";
import { SnackbarProvider } from "notistack";
import SpatialNavigation from "react-js-spatial-navigation";
import { HashRouter } from "react-router-dom";
import { Suspense } from "react";

import "./i18n";
window.TopTVAppData = {}
// if(!window.tizen){
//   window.tizen = 'web'
// }
// alert(window.tizen)
ReactDOM.render(
  <HashRouter>
    <ErrorBoundary>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        classes={{
          variantError: "snackClass",
          variantInfo: "snackClass",
          variantSuccess: "snackClass",
        }}
      >
        <SpatialNavigation>
          <Suspense fallback={<div>Loading...</div>}>
            <App />
          </Suspense>
        </SpatialNavigation>
      </SnackbarProvider>
    </ErrorBoundary>
  </HashRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
