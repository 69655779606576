import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  Focusable,
  FocusableSection,
} from 'react-js-spatial-navigation';
import './epgFull.css';
import { base, keyMap } from '../utils/constants';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import { generateM3U8URL, parse, shiftEpgTime } from '../utils/helpers';
import { api } from '../api/config';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import VODPlayer from './VODPlayer';
import cx from 'classnames'
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

export default function EPGFull({ classes, channel, setIsArchive, setShowInPlayerEPGs }) {
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation()
  const navigation = useNavigate();
  const [epg, setEpg] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [days, setDays] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [loadingID, setLoadingID] = useState(false)

  const [index, setIndex] = useState('')
  const [playerData, setPlayerData] = useState({})

  const todayRef = useRef(null)
  const currentProgramRef = useRef(null)
  
  const currentChannel = channel ? channel : location?.state;

  const getEpg = async () => {
    const {
      data: { epg: fullEpg },
    } = await api.post(`/epg/${currentChannel.epgId.epgId}/${currentChannel.epgId.dbId}`, {reserveEpg: currentChannel.reserveEpgId});

    setEpg(fullEpg.map(item=>({
      ...item,
      start: shiftEpgTime(item.start),
      stop: shiftEpgTime(item.stop)
    })));
  };

  useEffect(() => {
    currentChannel && getEpg();
  }, [currentChannel]);

  const getDays = useCallback(() => {
    const days = [
      {
        epgValue: moment().format('YYYYMMDD'),
        label: moment().format('DD/MM ddd'),
      },
    ];
    setSelectedDay({
      epgValue: moment().format('YYYYMMDD'),
      label: moment().format('DD/MM ddd'),
    });
    for (let i = 1; i < 14; i++) {
      days.push({
        epgValue: moment().add(i, 'days').format('YYYYMMDD'),
        label: moment().add(i, 'days').format('DD/MM ddd'),
      });
      days.unshift({
        epgValue: moment().subtract(i, 'days').format('YYYYMMDD'),
        label: moment().subtract(i, 'days').format('DD/MM ddd'),
      });
    }
    return days;
  }, []);

  useEffect(()=>{
    setIsArchive && setIsArchive(!!index)
  }, [index])

  const getPrograms = useCallback(() => {
    if (!epg) return;
    const filteredEpg = epg.filter(item =>
      item.start.startsWith(selectedDay.epgValue)
    );

    const result = filteredEpg.map(single => {
      const dateStart = parse(single.start);
      const dateStop = parse(single.stop);
      let isCurrent;
      if(moment().isSameOrAfter(moment(single.start, 'YYYYMMDDHHmmss')) && moment().isSameOrBefore(moment(single.stop, 'YYYYMMDDHHmmss')) ) {
        isCurrent = true
      }
      // const time = `${dateStart.h}:${dateStart.m} - ${dateStop.h}:${dateStop.m}`
      return {
        start: `${dateStart.h}:${dateStart.m}`,
        stop: `${dateStop.h}:${dateStop.m}`,
        isCurrent,
        startEpgTime: single.start,
        endEpgTime: single.stop,        
        title: single?.title?.[0]?.value,
      };
    });

    return result;
  }, [epg, selectedDay]);

  useEffect(() => {
    setDays(getDays());
  }, []);

  useEffect(() => {
    if (epg && selectedDay) setPrograms(getPrograms());
  }, [epg, selectedDay]);

  const handleSelectDay = day => {
    setSelectedDay(day);
  };

  // handlers
  const handleBack = e => {
    switch (e.which) {
      case keyMap.ARROW_LEFT:
        navigation('/player', {
          state: { smallView: true, currentChannel: currentChannel },
        });
        break;

      default:
        break;
    }
  };

  const handleOpenArchive = async (programs, i) => {
    window.scrollTo(0, 0)
    const program = programs[i]
    try {
      const options = {
        startTime: `${program.start.replace(':', '')}00`,
        endTime: `${program.stop.replace(':', '')}00`,
        day: selectedDay.epgValue.substr(6),
        date: selectedDay.epgValue,
        offset: new Date().getTimezoneOffset(),
        archiveName: currentChannel?.archive?.name,
        baseArchiver: currentChannel.archive.archiverInfo.host
      }
      if(options.archiveName){
        setLoadingID(i)
        const index = await axios.get(generateM3U8URL(options))
        if(!index?.data || index?.data ==='#EXT-X-ENDLIST' || index?.data.includes('undefined') || index?.data?.status === 404){
          throw new Error()
        }
        setPlayerData({
          currentProgram: program,
          currentProgramIndex: i,
          listPrograms: programs,
          currentChannel: currentChannel,
          day: selectedDay.epgValue.substr(6),
          date: selectedDay.epgValue,
          archiveName: currentChannel?.archive?.name,
          corrupteds: JSON.parse(index.headers['content-corrupted-parts'])
        })
        setIndex(generateM3U8URL(options))
        setLoadingID(false)
      }
    } catch (error) {
      setLoadingID(false)
      enqueueSnackbar(t(`file for {{range}} does not found`, {range: `${program.start}-${program.stop}`}), {
        variant: 'info',
        autoHideDuration: 3000,
      });
      console.log(error)
    }
  }

  const isArchived = (start, currentChannel) => {
    const duration = currentChannel?.archive?.duration
    if(duration){
      return moment(start, 'YYYYMMDDHHmmss').isAfter(moment().subtract(duration, 'hours')) && moment(start, 'YYYYMMDDHHmmss').isBefore(moment())
    }
  }



  const handleKeyPress = (e) => {
    switch (e.which) {
      case keyMap.REMOTE_BACK:
        if(index){
          setIndex(null)
        }else {
          navigation('/player', {
            state: { smallView: true, currentChannel: currentChannel },
          });
        }
        break;
      case keyMap.KEY_D:
      if(index){
        setIndex(null)
      }else {
        navigation('/player', {
          state: { smallView: true, currentChannel: currentChannel },
        });
      }
      break;
      case keyMap.ARROW_LEFT:
        const selectedDay = document.querySelector('.selectedDay')
        if(selectedDay){
          selectedDay.parentNode.focus()
        }
        break;
      case keyMap.ARROW_RIGHT:
        const currentProgram = document.querySelector('.currentProgram')
        if(currentProgram){
          currentProgram.parentNode.focus()
        }
        break;
    
      default:
        break;
    }
  }

  const handleVODBackClick = () => {
    setIndex(null)
    setShowInPlayerEPGs && setShowInPlayerEPGs(false)
  }

  useLayoutEffect(()=>{
    const today = document.querySelector('.today')
    const currentProgram = document.querySelector('.currentProgram')
      setTimeout(()=>{
      if(currentProgramRef.current){
        currentProgramRef.current.scrollIntoView({block: "center"})
      }
      }, 1)
      setTimeout(()=>{
        if(todayRef.current){
          todayRef.current.scrollIntoView({block: "center"})
          todayRef.current.parentNode.focus()
        }
      },20)
  }, [])

  const handleKeyNav = (e) => {
    if((document.activeElement.childNodes[0].id == 0 && e.which === keyMap.ARROW_UP) ||
      (document.activeElement.childNodes[0].id == programs.length - 1 && e.which === keyMap.ARROW_DOWN)
    ){
      e.preventDefault()
      e.stopPropagation()
    //   return false
    }
  }
  return (<>
        {index && <div className={cx('playerFromEpg')}>
          <VODPlayer setInfo={setPlayerData} info={playerData} handleBackClick={handleVODBackClick} url={index} />
        </div>}
        <div style={{display: index ? 'none' : 'block'}}  id="fullCont"  className={cx('epgFullContainer', ...classes)}>
        {!index && <header className = "header">
          <img
            height={170}
            src={`${base}/img/channels/${currentChannel.image}`}
          />
          <span className='headerTitle'>{currentChannel.name} EPG</span>
        </header>}
        {!index && <div className="EpgBody" onKeyUpCapture={handleKeyPress} >
          <div className="dayPicker" onKeyDown={handleBack}>
            <FocusableSection defaultElement='today' >
            {days?.map(item => {
              const rest = !!(item.label === moment().format('DD/MM ddd')) ? {ref: todayRef} : {}
              return <Focusable
                onClickEnter={() => {
                  handleSelectDay(item);
                }}
                // className={
                //   item.label === moment().format('DD/MM ddd') ? 'active' : ''
                // }
                className={'day'}
                key={item.label}>
                <div
                  {...rest}
                  onClick={() => {
                    handleSelectDay(item);
                  }}
                  className={
                    item.label === moment().format('DD/MM ddd')
                      ? item.label === selectedDay.label
                        ? 'day today selectedDay'
                        : 'day today'
                      : item.label === selectedDay.label
                      ? 'day selectedDay'
                      : 'day'
                  }>
                  {item.label.replace(' ', '\n')}
                </div>
              </Focusable>
            })}
            </FocusableSection>
          </div>
          <div onKeyDownCapture={handleKeyNav} className="programs">
            {programs?.map((item, i) => {
                const rest = item?.isCurrent ? {ref: currentProgramRef} : {}
              return <Focusable 
                onClickEnter={()=>{handleOpenArchive(programs, i)}} 
                key={item.start + item.stop + selectedDay.label}
                className='programCont'
                >
                <div  {...rest} onClick={()=>{handleOpenArchive(programs, i)}}  id={i} className={cx('program', {hasArchive: isArchived(item.endEpgTime, currentChannel)}, {currentProgram: item?.isCurrent} )}>
                {/* currentChannel?.archive */}
                  <span className="time">
                    {item.start} - {item.stop}
                  </span>
                  <span>{item.title}</span>
                  {loadingID === i && <ClipLoader color='#fff' />}
                </div>
              </Focusable>
            })}
          </div>
        </div>}
    </div>
    </>
  );
}

EPGFull.defaultProps = {
  classes: [],
}
