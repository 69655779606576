import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Focusable } from "react-js-spatial-navigation";
import { useLocation, useNavigate } from "react-router";
import { api } from "../../../api/config";
import { keyMap } from "../../../utils/constants";
import { getActiveUser } from "../../../utils/helpers";
import styles from "./styles.module.css";

const Edit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const current = useRef(null);
  const newPass = useRef(null);
  const confirm = useRef(null);
  const btn = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [editUser, setEditUser] = useState({});
  const inputsValues = useRef({name:'', pin: ''});
  const [error, setError] = useState(false)

  function handleClick(ref) {
    ref.current.focus();
  }

  function goBack(e) {
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-1);
    }
  }

  function handleEnter(e, ref) {
    e.preventDefault();
    inputsValues.current[e.target.name] = e.target.value;
    let code = e.which;
    if (code === keyMap.REMOTE_OK) {
      setTimeout(() => {
        if (ref.current.id === "myButton") {
          current.current.focus();
        } else {
          ref.current.parentNode.focus();
        }
      }, 10);
    }

    setError(null)
    if (code === keyMap.ARROW_UP && ref.current.name === "pin") {
      current.current.parentNode.focus();
    }


    if (code === keyMap.ARROW_DOWN) {
      ref.current.name === "name"
        ? newPass.current.parentNode.focus()
        : ref.current.name === "pin"
        ? btn.current.parentNode.focus()
        : newPass.current.parentNode.focus();
    }
  }

  const createUser = async () => {
    const activeUser = getActiveUser();
    if(!inputsValues.current.name || !inputsValues.current.pin ){
      setError(t('fieldsRequired'))
      return
    }
    if(`${+inputsValues.current.pin}`.length > 4 || isNaN(+inputsValues.current.pin)){
      setError(t('pinError'))
      return
    }
    const res = await api.put(
      `/users/${activeUser.id}/subuser/${location.state.id}`,
      {
        name: inputsValues.current.name,
        pin: inputsValues.current.pin,
      }
    );
    if (res?.data?.status === "success") {
      enqueueSnackbar(
        t(`User {{name}} successfully changed`, {
          name: `${inputsValues.current.name}`,
        }),
        {
          variant: "success",
          autoHideDuration: 3000,
        }
      );
      setEditUser((prev) => ({
        ...prev,
        name: inputsValues.current.name,
        pin: inputsValues.current.pin,
      }));
      return;
    }
    enqueueSnackbar(res?.message, {
      variant: "error",
      autoHideDuration: 3000,
    });
  };

  useEffect(() => {
    current.current.parentNode.focus();
    setEditUser(location.state);
  }, []);

  return (
    <div className={styles.mainEdit} onKeyUp={(e) => goBack(e)}>
      <div className={styles.containerEdit}>
        <h2 className={styles.title}>
          {t(`settings.Edit`)} {editUser.name}
        </h2>
        <div className={styles.form}>
          <Focusable
            className={styles.focusable}
            onClickEnter={() => handleClick(current)}
          >
            <input
              name="name"
              className={styles.name}
              // placeholder={t(`settings.Name*`)}
              placeholder={editUser.name}
              // value={editUser.name}
              onKeyUp={(e) => handleEnter(e, current)}
              ref={current}
            />
          </Focusable>
          <Focusable
            className={styles.focusable}
            onClickEnter={() => handleClick(newPass)}
          >
            <input
              name="pin"
              className={styles.pin}
              // placeholder={t(`settings.Pin*`)}
              placeholder={editUser.pin}
              // value={editUser.pin}
              onKeyUp={(e) => handleEnter(e, newPass)}
              onClick={() => handleClick(newPass)}
              ref={newPass}
            />
          </Focusable>
          {(!!error) && <>
            <div className={styles.helperText}> {error} </div>
          </> }
          <div
            onClick={() => {
              createUser();
              // handleClick(btn)
            }}
          >

          <Focusable
            className={styles.focusable}
            onClickEnter={() => {
              createUser();
              // handleClick(btn)
            }}
          >
            <button
              className={styles.btn}
              id="myButton"
              ref={btn}
            
              // onKeyUp={(e) => handleEnter(e, btn)}
            >
              {t(`settings.Change`)}
            </button>
          </Focusable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
