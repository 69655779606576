import React, { useEffect, useState } from "react";
import { isLogged } from "../../../utils/helpers";
import styles from "./styles.module.css";
import { api } from "../../../api/config";
import jwtDecode from "jwt-decode";
// import { ClipLoader } from "react-spinners";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { Focusable } from "react-js-spatial-navigation";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { keyMap } from "../../../utils/constants";
import { useTranslation } from "react-i18next";

const UsersSettings = () => {
  const { t } = useTranslation();
  const [subusers, setSubusers] = useState([]);
  const navigate = useNavigate();
  const focusDiv = useRef(null);
  // const location = useLocation();

  const getSubusers = async (userInfo) => {
    try {
      const {
        data: { subusers },
      } = await api.get(`/users/${userInfo.id}/subuser`);
      setSubusers(subusers);
    } catch (error) {
      setSubusers("error");
      console.log(error.toString());
    }
  };

  const handleNavigate = (item) => {
    navigate("/select_user", { state: item });
  };

  function goBack(e) {
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-1);
    }
  }

  useEffect(() => {
    const token = isLogged();
    if (token) {
      const userInfo = jwtDecode(token);
      getSubusers(userInfo);
    }
  }, []);

  useEffect(() => {
    if (subusers.length > 0) {
      focusDiv.current.parentNode.focus();
    }
  }, [subusers]);

  return (
    <div className={styles.mainParental} onKeyUp={(e) => goBack(e)}>
      <div className={styles.containerParental}>
        <div className={styles.parentalControl}>
          <h2 className={styles.title}>{t(`settings.Users_settings`)}</h2>
          <h4 className={styles.text}>{t(`settings.Select User`)}</h4>
        </div>
        <div className={styles.users}>
          {subusers
            ?.filter((item) => item?.role !== "subAdmin")
            ?.map((item, ind) => {
              return (
                <div onClick={() => handleNavigate(item)}>

                <Focusable
                  key={item.name}
                  className={styles.userItem}
                  onClickEnter={() => handleNavigate(item)}
                >
                  <AccountBoxIcon />
                  <div ref={ind === 0 ? focusDiv : null}> {item.name} </div>
                </Focusable>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default UsersSettings;
