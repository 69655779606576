import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getActiveUser, isLogged } from '../utils/helpers';


const PublicRoute = ({ children }) => {
    const [token, setToken] = useState('initial');
    useEffect(() => {
        setToken(isLogged())
    }, [])
    
    if (token === 'initial') {
        return <Typography component="h1" >Loading ...</Typography>
    }
    return (
        token ? <Navigate to="/users" /> : children
    );
};

export default PublicRoute;