import React, { useMemo } from "react";
import { useRef } from "react";
import { Navigate, useNavigate } from "react-router";
import styles from "./styles.module.css";
import data from "../../utils/VODFakeData.json";
import { Focusable, FocusableSection } from "react-js-spatial-navigation";
import CategoryItems from "../../components/Vod/Category/CategoryItems";
import Search from "../../components/Vod/Search/Search";
import Movies from "../../components/Vod/Movies/Movies";
import { useState } from "react";
import { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { keyMap } from "../../utils/constants";
import { api } from "../../api/config";

const VodScreen = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // function getData() {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     const { categories, movies } = data;
  //     setCategories(categories);
  //     setMovies(movies);
  //     setIsLoading(false);
  //   }, 2000);
  // }

  // const [filteredMovies, setFilteredMovies] = useState([]);
  // const [search, setSearch] = useState([]);

  // useEffect(() => {
  //   getData();
  // }, []);

  const [allMovies, setAllMovies] = useState([]);
  const [categories, setCategories] = useState([]);

  const [search, setSearch] = useState(""); // debouncing state
  const [ganresName, setGanresName] = useState({ id: "0", label: "All" });
  const [filteredMovies, setFilteredMovies] = useState([]);

  const handleBack = (e) => {
    if ([keyMap.REMOTE_BACK, keyMap.KEY_D].includes(e.which)) {
      navigate(-1);
    }
  };
  // fetch data
  useEffect(() => {
    setIsLoading(true)
    api.get(`/VOD/categories`).then((res) => {
      if (res.status === 200) {
        setCategories([{ id: "0", label: "All" }, ...res.data.categories]);
      }
    });
    api.get(`/VOD`).then((res) => {
      if (res.status === 200) {
        setIsLoading(false)
        setAllMovies(res.data.result);
      }
    });
  }, []);

  useEffect(() => {
    if (ganresName?.label === "All" && !search) {
      setFilteredMovies([]);
    }
    const ganreFilter = allMovies.filter((item) => ganresName?.id == 0 || item.genre.includes(+ganresName?.id));

    const fullFilter =
      !search
        ? ganreFilter
        : ganreFilter.filter((item) =>
            Object.values(item.title || {}).some((title) => {
              return title.toLowerCase().includes(search.toLowerCase());
            })
          );
    setFilteredMovies(fullFilter);
  }, [ganresName, allMovies, search]);

  const memoizeMovie = useMemo(() => {
      return filteredMovies;
  }, [filteredMovies]);


  return (
    <div onKeyUpCapture={handleBack} className={styles.mainVod}>
      <div className={styles.containerVod}>
        <div className={styles.sliderAndSearch}>
          <FocusableSection>
            <CategoryItems
              categories={categories}
              setGanresName={setGanresName}
            />
          </FocusableSection>
          <FocusableSection>
            <Search allMovies={memoizeMovie} setSearch={setSearch} />
          </FocusableSection>
        </div>
        <div className={styles.line}></div>
        {isLoading ? (
          <div className={styles.loader}>
            <ClipLoader
              size={100}
              color={"#fff"}
              cssOverride={{
                borderWidth: "5px",
              }}
            />
          </div>
        ) : (
          <>
            <Movies allMovies={memoizeMovie} categories={categories} />{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default VodScreen;
