import React, { useState, useEffect, useRef } from 'react';

const LazyLoadingList = ({ data, renderItem, chunkSize = 20 }) => {
  const [itemsToShow, setItemsToShow] = useState([]);
  const [next, setNext] = useState(chunkSize);
  const loaderRef = useRef(null);

  useEffect(() => {
    setItemsToShow(data.slice(0, chunkSize));
  }, [data, chunkSize]);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [next, data]);

  const loadMore = () => {
    const nextItems = data.slice(next, next + chunkSize);
    setItemsToShow((prevItems) => [...prevItems, ...nextItems]);
    setNext(next + chunkSize);
  };

  return (
    <>
      {itemsToShow.map((item, index) => renderItem(item, index))}
      <div ref={loaderRef} style={{ height: '20px', margin: '10px 0' }}>
        {next < data.length && <p>Loading more...</p>}
      </div>
    </>
  );
};

export default LazyLoadingList