import { createSlice } from '@reduxjs/toolkit';

export const tvConnectionSlice = createSlice({
    name: 'tvconnection',
    initialState: {
        isConnected: false,
        action: null
    },
    reducers: {
        setIsTVConnected: (state, { payload }) => {
            state.isConnected = payload;
        },
        setAction: (state, { payload }) => {
            state.action = payload;
        },
        removeAction: (state) => {
            state.action = null;
        },
    },
});


export default tvConnectionSlice;
