import axios from 'axios';
import { APP_VERSION, baseApi } from '../utils/constants';
import { getDevice, getPlatformFromUserAgent, isLogged, logout } from '../utils/helpers';
import { v4 } from 'uuid';


const baseURL = baseApi;
const api = axios.create({ baseURL });

api.interceptors.request.use(
  request => {
    const headers = {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    };
    if (isLogged()) {
      headers.Authorization = `JWT ${isLogged()}`;
    }
    const device = getDevice()
    if (device) {
      let uuid = localStorage.getItem("devUUID")
      if (!uuid) {
        uuid = v4()
        localStorage.setItem("devUUID", uuid)
      }
      headers['X-Device-Name'] = getPlatformFromUserAgent();
      headers['X-Device-brand'] = device.manufacturer;
      // headers['X-Device-deviceYearClass'] = deviceYearClass;
      headers['X-Device-manufacturer'] = device.manufacturer;
      headers['X-Device-Model-Name'] = device.model;
      headers['X-Device-OS-Name'] = device.platform || getPlatformFromUserAgent();
      headers['X-Device-OS-Version'] = device.buildVersion;
      headers['X-Device-UUID'] = device.uuid;
      headers['X-Device-Ethernet'] = JSON.stringify(device.ethernetNetwork);
      headers['X-Device-Wifi'] = JSON.stringify(device.wifiNetwork);
      headers['App-Version'] = APP_VERSION;
      headers['Key-Constant'] = window.tizen ? "samsung" : window?.webos ? "lg" : "";
      headers['Device-UUID'] = device.uuid || uuid;
    }
    const newRequest = { ...request, headers };
    return newRequest;
  },
  error => {
    // if (error.message === "Invalid token specified") {
    //   logout()
    // }
  }
);

api.interceptors.response.use(null, rej => {
  if ((rej?.response?.status === 401 && !rej?.response?.request?.responseURL.includes("users/login")) || rej?.response?.data?.error?.message === 'invalid signature') {
    logout();
    window.location.reload();
    // window.location.replace('/');
  } else {
    return rej?.response?.data
  }
});

export { api };
