import React, { useCallback, useEffect, useRef, useState } from "react";
// import PlayerControls from './VODControlls'
import styles from "./styles.module.css";
import { keyMap } from "../../utils/constants";
import VideoJS from "../playerVideo";
import PlayerControls from "./VODMovieControlls";
import Test from "./VODMovieControlls/Test";

export default function VODPlayer({ url, info, setInfo, handleBackClick }) {
  const playerRef = useRef(null);
  const containerPlayer = useRef(null);

  const options = {
    autoplay: true,
    // controls: false,
    responsive: false,
    fill: true,
    // controlBar: false,
    // loadingSpinner: true,
    bigPlayButton: false,
    fluid: false,
    aspectRatio: "16:9",
  };

  const handlePlayerReady = useCallback((player) => {
    playerRef.current = player;
    // const i = channels.current.findIndex(
    //   item => item._id === location?.state?.currentChannel._id
    // );
    // handleChannelChange(i === -1 ? 0 : i);

    player.handleSrc_(
      [
        {
          src: url,
          type: "application/x-mpegURL",
        },
      ],
      true
    );

    // you can handle player events here
    player.on("waiting", () => {
      // console.log('player is waiting!');
    });

    player.on("error", (err) => {
      // console.log('player error!', err);
    });

    player.on("dispose", () => {
      // console.log('player will dispose!');
    });
  }, []);

  /////////////////////////useEffects//////////////////
  useEffect(() => {
    containerPlayer.current && containerPlayer.current.focus();
  }, [containerPlayer]);

  return (
    <div ref={containerPlayer} className={styles.containerPlayer}>
      {
        <div className={styles.playerFromEpg}>
          <VideoJS options={options} onReady={handlePlayerReady} />
        </div>
      }
      {/* <Test videoRef={playerRef} /> */}

      <PlayerControls
        handleBackClick={handleBackClick}
        // setInfo={setInfo}
        info={info}
        videoRef={playerRef}
        handleChannelChange={() => {}}
      />
    </div>
  );
}
