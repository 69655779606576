import { api } from "./config";

export const saveActiveStatusToServer = async (info) => {
  console.log("start saved" , info.userId, info.personalID);
  await api.put(`/users/${info.userId}/subuser/${info.personalID}`, {
      lastLoginTime: Date.now(),
    })
};

export const saveSessionEndStatusToServer = async (info) => {
  console.log("end saved", info.userId, info.personalID);
  await api.put(`/users/${info.userId}/subuser/${info.personalID}`, {
    sessionEndTime: Date.now(),
  });
};
