import React, { useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { keyMap } from "../../utils/constants";
import styles from "./styles.module.css";
import logo from "../../assets/images/logo192.png";
import { useTranslation } from "react-i18next";

import winLogo from "../../assets/images/windowslog.png"
import appleLogo from "../../assets/images/applelogo.png"
import androidLogo from "../../assets/images/android-logo.png"


export default function PWAInstallModal({
  open,
  setOpen,
}) {
  const { t } = useTranslation()
  const okRef = useRef();
  const cancelRef = useRef();

  const [thanks, setThanks] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (okRef?.current) {
      okRef.current.parentNode.focus();
    }
  }, [okRef.current, open]);

  const handleKeyUp = e => {
    // e.preventDefault();
    // e.stopPropagation();
  };

  const handleKeyDown = e => {
    const activeId = document.activeElement.childNodes[0].id;
    if (e.which === keyMap.ARROW_LEFT) {
      cancelRef.current.parentNode.focus();
    } else if (e.which === keyMap.ARROW_RIGHT) {
      okRef.current.parentNode.focus();
    } else if (e.which === keyMap.ARROW_RIGHT) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      event.preventDefault();
      // Stash the event so it can be triggered later
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Check if the app is already installed
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      setIsInstalled(true);
    } else {
      // Check if the app is installed using getInstalledRelatedApps() method
      if (navigator.getInstalledRelatedApps) {
        navigator.getInstalledRelatedApps().then((relatedApps) => {
          if (relatedApps.length > 0) {
            setIsInstalled(true);
          }
        });
      }
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstall = (win) => {
    setThanks(true)
  };

  if (isInstalled || window.tizen || window?.webos || window.location.href.includes("pwa=true")) {
    return null
  }


  return (
    <div onKeyUp={handleKeyUp} onKeyDown={handleKeyDown}>
      <Dialog
        classes={{ scrollPaper: styles.Dialog, root: styles.className }}
        PaperProps={{
          style: {
            backgroundColor: "rgb(57 65 70 / 70%)",
            border: "3px solid #87c232 !important",
            padding: "20px !important",
            borderRadius: "24px"
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className={styles.titleCont} >
            <img src={logo} width={40} height={40} />
            <span className={styles.dialogText}>{t("installPWATitle")}</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={styles.dialogText}
            id="alert-dialog-description"
          >
            {thanks ? <div className={styles.titleCont} >
              <span className={styles.dialogText}>{t("installPWAThanks")}</span>
            </div> : <>
              <span className={styles.dialogText}>{t("installPWAContent")}</span>
              <div className={styles.btnsCont} >
                <a
                  href="https://api.rippletv.io/download/rippletv-setup.exe"
                  id="win"
                  className={styles.modalButton}
                  ref={okRef}
                  onClick={() => handleInstall(true)}
                >
                  <img src={winLogo} height={"30px"} />
                  <span className={styles.text}>
                    For Windows
                  </span>
                </a>
                <a
                  id="ok"
                  href="https://api.rippletv.io/download/rippletv-setup.dmg"
                  className={styles.modalButton}
                  ref={okRef}
                  onClick={() => handleInstall(false)}
                >
                  <img src={appleLogo} height={"30px"} />
                  <span className={styles.text}>
                    For MacOS
                  </span>
                </a>
                <button
                  id="ok"
                  className={styles.modalButton}
                  ref={okRef}
                  onClick={() => window.open("https://play.google.com/store/apps/details?id=com.marshall7.TOPTV&hl=en&gl=US")}
                >
                  <img src={androidLogo} height={"50px"} />
                  <span className={styles.text} style={{ marginLeft: "-10px" }} >
                    For Android
                  </span>
                </button>
                <button
                  id="ok"
                  className={styles.modalButton}
                  ref={okRef}
                  onClick={() => window.open("https://apps.apple.com/sn/app/toptv/id6447475897")}
                >
                  <img src={appleLogo} height={"30px"} />
                  <span className={styles.text}>
                    For IOS
                  </span>
                </button>
              </div>
            </>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <button
            id="cancel"
            className={styles.modalButtonClose}
            ref={cancelRef}
            onClick={() => {
              setOpen(false);
              localStorage.setItem("seenInstallApp", true)
            }}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
