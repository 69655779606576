import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Focusable } from "react-js-spatial-navigation";
import { useLocation, useNavigate } from "react-router";
import { keyMap } from "../../../utils/constants";
import styles from "../SelectChannels/select.module.css";
import cx from "classnames";
import { ReactComponent as Logo } from "../../../assets/images/check.svg";
import { getActiveUser } from "../../../utils/helpers";
import { useSnackbar } from "notistack";
import { api } from "../../../api/config";
import { useTranslation } from "react-i18next";

const DailyPeriod = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const ref = useRef(null);
  const [time, setTime] = useState([]);
  const [selected, setSelected] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state.allowedHours) {
      setSelected(location.state.allowedHours);
    }
  }, [location]);

  useEffect(() => {
    const numbers = Array.from({ length: 24 }, (_, i) => i + 1);
    setTime([0.01, ...numbers]);
  }, []);

  function goBack(e) {
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-2);
    }
  }

  const selectHours = (ind) => {
    setSelected(ind);
  };

  useEffect(() => {
    ref.current.firstChild.focus();
  }, []);


  const okReq = async () => {
    const activeUser = getActiveUser();
    const res = await api.put(
      `/users/${activeUser.id}/subuser/${location.state.id}`,
      {
        allowedHours: selected,
      }
    );

    if (res.data.status === "success") {
      enqueueSnackbar(t(`Settings saved successfully`), {
        variant: "success",
        autoHideDuration: 3000,
      });
      return;
    }
    enqueueSnackbar(t("An error occurred, please try again later"), {
      variant: "error",
      autoHideDuration: 3000,
    });
  };

  return (
    <div className={styles.mainSelect} onKeyUp={(e) => goBack(e)}>
      <div className={styles.containerSelect}>
        <div className={styles.channelsContext}>
          <div className={styles.channelsListContainer}>
            <div className={styles.selectChannels}>
              {time.map((item, ind) => {
                let bool = selected === item;
                return (
                  <div onClick={(e) => {
                    selectHours(item);
                  }} key={ind}>
                    <Focusable
                      className={cx(styles.focusableSelect, {
                        [styles.backgroundGreen]: bool,
                      })}
                      onClickEnter={(e) => {
                        selectHours(item);
                      }}
                    >
                      <div
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        {item + t("settings.hours")}
                      </div>
                      {bool && (
                        <Logo
                          className={styles.svgLogo}
                          width="40"
                          height="40"
                        />
                      )}
                    </Focusable>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.middleContainer}>
            <div className={styles.title}>{t("settings.Daily period")}</div>
            <div onClick={okReq} className={styles.buttons} ref={ref}>
              <Focusable className={styles.ok} onClickEnter={okReq}>
                {t("settings.ok")}
              </Focusable>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default DailyPeriod;
