import React from "react";
import { useTranslation } from "react-i18next";
import { Focusable } from "react-js-spatial-navigation";
import { useLocation, useNavigate } from "react-router";
import { keyMap } from "../../../utils/constants";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import styles from "./styles.module.css";
import { useRef } from "react";
import { useEffect } from "react";

const SelectUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const initialFocusedRef = useRef(null);

  function goBack(e) {
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-1);
    }
  }

  const options = [
    {
      label: "Parental control",
      onEnter: () => {
        navigate("/parental_control_options", { state: location.state });
      },
    },
    {
      label: "Edit",
      onEnter: () => {
        navigate("/edit", { state: location.state });
      },
    },
    {
      label: "Delete",
      onEnter: () => {
        navigate("/delete", { state: location.state });
      },
    },
  ];

  useEffect(() => {
    initialFocusedRef.current.parentNode.focus();
  }, []);

  return (
    <div className={styles.mainSelect} onKeyUp={(e) => goBack(e)}>
      <div className={styles.containerSelect}>
        {/* <h2 className={styles.title}>{t(`settings.Select_user_to_change`)}</h2> */}
        <div className={styles.userItem}>
          <AccountBoxIcon />
          <div>{location.state.name}</div>
        </div>
        {options.map((item, i) => (
          <Focusable
            className={styles.focusable}
            key={item.label}
            onClickEnter={item.onEnter}
          >
            <div
              ref={i === 0 ? initialFocusedRef : null}
              className={styles.actionBtn}
              onClick={item.onEnter}
            >
              {t(`settings.${item.label}`)}
            </div>
          </Focusable>
        ))}
      </div>
    </div>
  );
};

export default SelectUser;
