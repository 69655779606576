import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Focusable, FocusableSection } from "react-js-spatial-navigation";
import { keyMap } from "../../utils/constants";
import styles from "./styles.module.css";
import cx from "classnames";
import { R_GetAdminNotifications, R_SeenAdminNotification } from "../../api/AdminNotifications/service";
import { getActiveUser } from "../../utils/helpers";
import classNames from "classnames";

export default function AdminNotifications({
  cancelText,
  title,
  open,
  setOpen,
  content,
  okText,
  handleCancel,
  handleOk,
  className,
}) {
  const okRef = useRef();
  const cancelRef = useRef();
  const [popups, setPopups] = useState([])

  const handleClose = async (popup) => {
    const user = getActiveUser()
    await R_SeenAdminNotification({
      customerId: user.id,
      notificationId: popup._id
    })
    setPopups(prev=>(prev.filter(item=>item._id !== popup._id)))
  };

  
  const handleConfim = async (popup) => {
    const user = getActiveUser()
    await R_SeenAdminNotification({
      customerId: user.id,
      notificationId: popup._id
    })
    setPopups(prev=>(prev.filter(item=>item._id !== popup._id)))
  };
  

  useEffect(() => {
    if (okRef?.current) {
      okRef.current.parentNode.focus();
    }
  }, [okRef.current, open]);

  const handleKeyUp = e => {
    // e.preventDefault();
    // e.stopPropagation();
  };

  const handleKeyDown = e => {
    const activeId = document.activeElement.childNodes[0].id;
    if (e.which === keyMap.ARROW_LEFT) {
      cancelRef.current.parentNode.focus();
    } else if (e.which === keyMap.ARROW_RIGHT) {
      okRef.current.parentNode.focus();
    } else if (e.which === keyMap.ARROW_RIGHT) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    const user = getActiveUser()
    const getNotifications = async () => {
      const res = await R_GetAdminNotifications({ customerId: user.id })
      if (res.status === "success") {
        setPopups(res.data)
      }
    }
    getNotifications()
  }, [])

  return (
    <>
      {popups.map(popup => (
        <div onKeyUp={handleKeyUp} onKeyDown={handleKeyDown}>
          <Dialog
            classes={{ scrollPaper: styles.Dialog, root: styles.className }}
            PaperProps={{
              style: {
                backgroundColor: "rgba(0,0,0,0.5)",
                borderRadius: "20px",
                border: "1px solid gray",
                width: "90vw",
                maxWidth: "100vw"
              },
            }}
            open={true}
            onClose={()=>handleClose(popup)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <span className={styles.dialogTitle}>{popup.title}</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                className={styles.dialogText}
                id="alert-dialog-description"
              >
                <span className={styles.dialogText}>{popup.message}</span>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{display: "flex", justifyContent: "center", alignContent: "center"}}>
              {popup.cancelText && <Focusable
                onClickEnter={()=>handleClose(popup)}
                className={classNames(styles.modalCancelButton, styles.modalButtonCont)}
              >
                <button
                  id="cancel"
                  className={classNames(styles.modalCancelButton, styles.modalButton)}
                  ref={cancelRef}
                  onClick={()=>handleClose(popup)}
                >
                  {popup.cancelText}
                </button>
              </Focusable>}
              {popup.confirmText && <Focusable onClickEnter={()=>handleConfim(popup)} className={styles.modalButtonCont}>
                <button
                  id="ok"
                  className={styles.modalButton}
                  ref={okRef}
                  onClick={()=>handleConfim(popup)}
                >
                  {popup.confirmText}
                </button>
              </Focusable>}
            </DialogActions>
          </Dialog>
        </div>
      ))}
    </>
  );
}
