import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import styles from "./styles.module.css"
import { extractChannels, getActiveUser, getSubuser, saveSubuser } from '../../utils/helpers';
import { Focusable } from 'react-js-spatial-navigation';
import ClearIcon from '@material-ui/icons/Clear';
import { Checkbox } from '@material-ui/core';
import VideoJS from '../../components/playerVideo';
import { useSnackbar } from 'notistack';
import { keyMap } from '../../utils/constants';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { api } from '../../api/config';
import LazyLoadingList from '../../components/LazyLoader';
import { PlayCircleFilled } from '@material-ui/icons';

const initialCategory = { name: 'ALL', id: 1 }

export default function Playlists() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [playlists, setPlaylists] = useState([])
    const activeSub = getSubuser()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const [isFavs, setIsFavs] = useState(false)
    const [channels, setChannels] = useState([])

    const [favorites, setFavorites] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(initialCategory)
    const [selectedPlaylist, setSelectedPlaylist] = useState(playlists?.[0])
    const [filteredChannels, setFilteredChannels] = useState(channels);
    const [categories, setCategories] = useState([initialCategory])
    const [index, setIndex] = useState(0)
    const [search, setSearch] = useState('')
    const [channel, setChannel] = useState(null);
    const [preChannel, setPreChannel] = useState(null);
    const [showChannelName, setShowChannelName] = useState(false);

    const [isFullScr, setIsFullScr] = useState(false);
    const [showChannels, setShowChannels] = useState(false);
    const [paused, setPaused] = useState(false)
    const searchRef = useRef(null)
    const channelContRef = useRef(null)
    const [videoState, setVideoState] = useState({
        isPlaying: true,
        isPaused: false,
        isMuted: false,
        volume: 1.0,
        aspectRatio: 16 / 9
    });
    const videoRef = useRef();
    const [editModal, setEditModal] = useState(null)
    const [deleteModal, setDeleteModal] = useState(null)
    const [openFilterModal, setOpenFilterModal] = useState(false)

    const focusedChannel = useRef(null)

    const playlistsRef = useRef([])
    const categoriesRef = useRef([])
    const clearButtonRef = useRef(null)
    const contRef = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            playlistsRef.current[0]?.parentNode?.focus?.()
        }, 400);
    }, [playlistsRef.current])

    const handleSearch = (search, channels, playlistId, categoryName, isFavs, favoritesProvided) => {
        return (isFavs ? (favoritesProvided || favorites) : channels)?.filter((item) => {
            return (
                (!playlistId || item?.playlistId === playlistId) &&
                (!categoryName || categoryName === 'ALL' || item?.category === categoryName) &&
                (!search || item?.name?.toLowerCase().includes(search.toLowerCase()))
            );
        });
    }


    const handlePlaylistFocus = (i) => {
        playlistsRef.current[i].scrollIntoView({ behavior: "smooth", block: "end" })
    }

    const handleCategoryFocus = (i) => {
        categoriesRef.current[i].scrollIntoView({ behavior: "smooth", block: "end" })
    }


    const handleSelectCategory = (item, providedChannels) => {
        setSelectedCategory(item)
        const filteredChannels = handleSearch(search, providedChannels || channels, selectedPlaylist?.playlistData?.id, item?.name, isFavs)
        setFilteredChannels(filteredChannels)
    }
    const handleSelectPlaylist = (item) => {
        if (item?.playlistData?.id === selectedPlaylist?.playlistData?.id) return
        setSelectedPlaylist(item)
        const newFavs = activeSub.playlists.favorites.filter(favItem => favItem.playlistId === item.playlistData?.id)
        setFavorites(newFavs)
        setChannels(item.channels)
        setCategories([initialCategory, ...item?.categories])
        // setFilteredChannels(item.channels)
        const filteredChannels = handleSearch(search, item?.channels, item?.playlistData?.id, selectedCategory?.name, isFavs, newFavs)
        setFilteredChannels(filteredChannels)
    }

    const handleSearchText = (e) => {
        const text = e.target.value
        setSearch(text)
        const filteredChannels = handleSearch(text, channels, selectedPlaylist?.playlistData?.id, selectedCategory?.name, isFavs)
        setFilteredChannels(filteredChannels)
    }

    const handleKeySearch = (e) => {
        switch (e.which) {
            case keyMap.ARROW_DOWN:
                playlistsRef.current[0].parentNode.focus()
                break;
            case keyMap.ARROW_RIGHT:
                clearButtonRef.current.parentNode.focus()
                break;

            default:
                break;
        }
    }

    const handleFavs = (isFav) => {
        const filteredChannels = handleSearch(search, channels, selectedPlaylist?.playlistData?.id, selectedCategory?.name, isFav)
        setFilteredChannels(filteredChannels)
    }












    const addPlaylist = async (data) => {
        try {
            const { playlistURL } = data
            const response = await fetch(playlistURL);
            const result = await response.text();
            const playlistId = data.id || Date.now()
            const channels = extractChannels(result, playlistId, data.filtered)
            // const epg = extractEPG(result);
            const categories = []
            channels.map(item => {
                const channelsCategory = { name: item.groupTitle === 'Undefined' || !item.groupTitle ? 'Others' : item.groupTitle, playlistId: playlistId }
                if (categories.every(category => category.name !== channelsCategory.name || category.playlistId !== channelsCategory.playlistId)) {
                    categories.push(channelsCategory)
                }
            })
            return {
                playlistData: { playlistURL: data.playlistURL, playlistName: data.playlistName, id: playlistId, channelsCount: channels.length },
                channels,
                categories,
                // epg,
            }
        } catch (error) {
            return {
                error,
            }
        }
    }

    useEffect(() => {
        setLoading(true)
        const storePlaylists = async () => {
            try {
                const playlists = []
                let promises = []
                if (activeSub?.playlists?.list.length) {
                    promises = activeSub?.playlists?.list.map(item => addPlaylist(item))
                    Promise.all(promises).then((playlists) => {
                        setFavorites(activeSub?.playlists?.favorites.filter(item => item.playlistId === playlists[0].playlistData?.id))
                        setPlaylists(playlists)
                        setSelectedPlaylist(playlists[0])
                        setFilteredChannels(playlists[0].channels)
                        setLoading(false)
                    })
                } else {
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
            }
        }
        storePlaylists()
    }, [])

    useEffect(() => {
        if (playlists?.[0]?.channels?.length) {
            setChannels(playlists?.[0]?.channels)
            setCategories([initialCategory, ...playlists?.[0]?.categories])
        }
    }, [playlists])

    const ChannelName = useMemo(() => {
        return <div style={[styles.channelNameWrapper]} >
            <img
                style={{
                    width: 40,
                    height: 20,
                    marginRight: 20,
                    resizeMode: "contain"
                }}
                src={preChannel?.tvgLogo}
            />
            <div style={[{ color: '#fff' }]} >{(preChannel?.index) || ''}.  </div>
            <div style={[{ color: '#fff' }]}>{preChannel?.name}</div>
        </div>
    }, [preChannel])

    const handleStreamError = useCallback((e) => {
        if (!channel?.url) return
        console.log(e);
        if (e?.includes?.('certificate')) {
            enqueueSnackbar(
                t("playlist.certErr"),
                {
                    variant: "error",
                    autoHideDuration: 3000,
                }
            );
        } else {
            enqueueSnackbar(
                t("playlist.streamErr"),
                {
                    variant: "error",
                    autoHideDuration: 3000,
                }
            );
        }
    }, [channel])

    const playerRef = useRef(null)
    const handleSelectChannel = useCallback((item) => {
        playerRef.current?.handleSrc_(
            [
                {
                    src: (item?.url || "").trim(),
                    type: "application/x-mpegURL",
                },
            ],
            true
        );
        setChannel(item);
        setIndex(index);
        if (channel?.url === item.url && channel?.name === item.name) {
            setIsFullScr(true)
        }

        // pagerRef?.current?.setPageWithoutAnimation(index)
    }, [channel, index, playerRef.current])


    const handleChannelKey = (e) => {
        const user = getActiveUser()
        const index = activeSub?.playlists?.favorites?.findIndex?.(item => item.tvgId === focusedChannel.current.tvgId)
        let newFavs = [...(activeSub.playlists.favorites || [])]
        switch (e.which) {
            case keyMap.REMOTE_COLOR_YELLOW:
                if (index > -1) {
                    newFavs.splice(index, 1)
                } else {
                    newFavs.push(focusedChannel.current)
                }
                setFavorites(newFavs.filter(item => item.playlistId === selectedPlaylist.playlistData?.id))
                saveSubuser({ ...activeSub, playlists: { ...(activeSub.playlists || {}), favorites: newFavs } })

                api.put(`/users/${user.id}/subuser/${activeSub.id}`, {
                    playlists: { ...(activeSub.playlists || {}), favorites: newFavs },
                });
                break;
            case keyMap.KEY_Y:
                if (index > -1) {
                    newFavs.splice(index, 1)
                } else {
                    newFavs.push(focusedChannel.current)
                }
                setFavorites(newFavs.filter(item => item.playlistId === selectedPlaylist.playlistData?.id))
                saveSubuser({ ...activeSub, playlists: { ...(activeSub.playlists || {}), favorites: newFavs } })
                api.put(`/users/${user.id}/subuser/${activeSub.id}`, {
                    playlists: { ...(activeSub.playlists || {}), favorites: newFavs },
                });
                break;
            default:
                break;
        }
    }


    const options = {
        autoplay: true,
        // controls: false,
        responsive: false,
        fill: true,
        // controlBar: false,
        // loadingSpinner: true,
        bigPlayButton: false,
        fluid: false,
        aspectRatio: "16:9",
    };

    const handlePlayerReady = useCallback((player) => {
        playerRef.current = player;
        // const i = channels.current.findIndex(
        //   item => item._id === location?.state?.currentChannel._id
        // );
        // handleChannelChange(i === -1 ? 0 : i);
        player.handleSrc_(
            [
                {
                    src: (channel?.url || "").trim(),
                    type: "application/x-mpegURL",
                },
            ],
            true
        );

        // you can handle player events here
        player.on("waiting", () => {
            // console.log('player is waiting!');
        });

        player.on("error", (err) => {
            handleStreamError(err)
        });

        player.on("dispose", () => {
            // console.log('player will dispose!');
        });
    }, [channel]);

    function goBack(e) {
        let code = e.which;
        if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
            if (isFullScr || showChannels) {
                setIsFullScr(false)
                setShowChannels(false)
            } else {
                navigate(-1);
            }
        }
    }

    const emptyDivRef = useRef(null)
    useEffect(() => {
        setTimeout(() => {
            emptyDivRef?.current?.parentNode?.focus()
        }, 200);
    }, [emptyDivRef.current])

    if (loading) {
        return <div className={styles.content} onKeyUp={goBack}>
            <div className={styles.loaderContent}>
                <ClipLoader size={80} color='#87C232' cssOverride={{ borderWidth: 10 }} />
                <Focusable>
                    <div ref={emptyDivRef} ></div>
                </Focusable>
            </div>
        </div>
    }

    if (!playlists.length) {
        return <div className={styles.content} onKeyUp={goBack}>
            <div className={styles.loaderContent}>
                <div style={{ color: "#fff", fontSize: 50, width: "100%", textAlign: 'center', marginBottom: 10 }}>{t("playlist.addingplaylists")}</div>
                <div style={{ color: "#fff", fontSize: 40, width: "100%", textAlign: 'center' }}>{t("playlist.hintAddPlaylist")}</div>
                <div style={{ color: "#fff", fontSize: 40, width: "100%", textAlign: 'center', marginBottom: 10 }}>{t("playlist.hintAddPlaylist2")}</div>
                <Focusable>
                    <div ref={emptyDivRef} ></div>
                </Focusable>
                {/* <Icon.Button
                    name="add"
                    backgroundColor={theme.colors.primary}
                    onPress={() => dispatch(setShowAddModal(true))}
                >
                    <Text style={{ color: "#fff", fontSize: 15 }}>
                        {t("playlist:AddPlaylist")}
                    </Text>
                </Icon.Button> */}
            </div>
        </div>
    }



    return <div className={styles.content} onKeyUp={goBack}>


        <div
            ref={contRef}
            className={styles.container}
            style={{ ...{ width: channel?.url ? '50%' : '100%' }, ...(isFullScr && showChannels ? { width: '50%', backgroundColor: 'rgba(0,0,0,0.3)', zIndex: 10, position: 'absolute', top: 0, left: 0 } : isFullScr ? { width: 0, height: 0 } : {}) }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginTop: 5
            }} >
                <div style={{ padding: "0px 50px", display: "flex", justifyContent: "center", alignItems: "center" }}>

                    {((showChannels && isFullScr) || (!showChannels && !isFullScr)) && <Focusable
                        style={{
                            height: 120,
                            padding: 0,
                            margin: 0,
                            width: channel?.url ? '70%' : '85%'
                        }}
                        overrideStyles={{
                            borderRadius: 4,
                        }}
                        autofocus={true}
                        onClickEnter={() => {
                            searchRef.current.focus();
                        }}>
                        <input
                            onKeyUp={handleKeySearch}
                            className={styles.input}
                            value={search}
                            onChange={handleSearchText}
                            ref={searchRef}
                            placeholder={t("playlist.SearchChannel")}
                        />
                    </Focusable>}
                    <Focusable
                        className={styles.clearIconCont}
                        onClickEnter={() => {
                            handleSearchText({ target: { value: '' } });
                        }}
                    >
                        {/* <div  style={{  }} > */}
                        <ClearIcon ref={clearButtonRef} fontSize={"inherit"} htmlColor={"#fff"} />
                        {/* </div> */}
                    </Focusable>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }} >
                    <div style={{ color: "#fff", fontSize: 35, marginRight: 20 }} >{t("playlist.Favorites")}</div>
                    <Focusable
                        onClickEnter={() => { handleFavs(!isFavs); setIsFavs(!isFavs) }}>
                        <input
                            onClick={() => { handleFavs(!isFavs); setIsFavs(!isFavs) }}
                            className={styles.checkbox}
                            id="remember"
                            checked={isFavs}
                            onChange={e => { handleFavs(!isFavs); setIsFavs(!isFavs) }}
                            type="checkbox"
                        />
                    </Focusable>
                </div>
            </div>
            <div className={styles.horizontalScroll}>
                {playlists.map((playlist, i) => {
                    return <Focusable key={playlist?.playlistData?.id} className={classNames(styles.HitemContainter)} onClickEnter={() => handleSelectPlaylist(playlist)} onFocus={() => handlePlaylistFocus(i)}>
                        <div className={classNames(styles.playlistItem, { [styles.activeHItem]: (selectedPlaylist?.playlistData?.id === playlist?.playlistData?.id) })} ref={(e) => { playlistsRef.current[i] = e }}>
                            {playlist.playlistData.playlistName}  ({(isFavs ? activeSub?.playlists?.favorites?.filter?.(item => item.playlistId === playlist?.playlistData?.id) : playlist?.channels)?.length}{isFavs && <span><FavoriteIcon htmlColor='yellow' fontSize='large' /></span>})
                        </div>
                    </Focusable>
                })}
            </div>
            <div className={styles.horizontalScroll}>
                {categories.map((category, i) => {
                    return <Focusable key={category.name} className={classNames(styles.HitemContainter)} onClickEnter={() => handleSelectCategory(category)} onFocus={() => handleCategoryFocus(i)}>
                        <div className={classNames(styles.categoryItem, { [styles.activeHItem]: (selectedCategory?.name === category?.name) })} ref={(e) => { categoriesRef.current[i] = e }}>
                            {category.name} ({category.name === "ALL" ? (isFavs ? favorites : channels).length : (isFavs ? favorites : channels)?.filter?.(item => item.category === category.name)?.length}{isFavs && <span><FavoriteIcon htmlColor='yellow' fontSize='large' /></span>})
                        </div>
                    </Focusable>
                })}
            </div>
            <div onKeyUp={handleChannelKey} className={styles.channelContainer}>
                {filteredChannels.length > 500 ? <LazyLoadingList
                    data={filteredChannels}
                    renderItem={(channelItem, i) => <Focusable key={channelItem.tvgId} onFocus={() => { focusedChannel.current = channelItem }} onClickEnter={() => handleSelectChannel(channelItem)} className={styles.channel}>
                        <div className={styles.channel} style={{ padding: "15px 50px", margin: "0px" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: '50px' }} >
                                {channel?.tvgId === channelItem.tvgId && <PlayCircleFilled fontSize={"inherit"} htmlColor="#fff"/>}
                                <span style={{ marginRight: "20px" }} >{i + 1}.</span>
                                <img style={{ marginRight: "20px" }} className={styles.logoChannel} src={channelItem.tvgLogo} />
                                <span className={styles.elips}>{channelItem.name}</span>
                            </div>
                            <div style={{ fontSize: "60px", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                {favorites.some(item => (item.tvgId === channelItem.tvgId) && (selectedPlaylist?.playlistData?.id === channelItem.playlistId)) ?
                                    <FavoriteIcon fontSize={"inherit"} htmlColor="yellow" /> :
                                    <FavoriteBorderIcon fontSize={"inherit"} htmlColor="#fff" />
                                }
                            </div>
                        </div>
                    </Focusable>}
                    chunkSize={30}
                /> :
                    filteredChannels.map((channelItem, i) => {
                        return <Focusable key={channelItem.tvgId} onFocus={() => { focusedChannel.current = channelItem }} onClickEnter={() => handleSelectChannel(channelItem)} className={styles.channel}>
                        <div className={styles.channel} style={{ padding: "15px 50px", margin: "0px" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: '50px' }} >
                                {channel?.tvgId === channelItem.tvgId && <PlayCircleFilled fontSize={"inherit"} htmlColor="#fff"/>}
                                <span style={{ marginRight: "20px" }} >{i + 1}.</span>
                                <img style={{ marginRight: "20px" }} className={styles.logoChannel} src={channelItem.tvgLogo} />
                                <span className={styles.elips}>{channelItem.name}</span>
                            </div>
                            <div style={{ fontSize: "60px", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                {favorites.some(item => (item.tvgId === channelItem.tvgId) && (selectedPlaylist?.playlistData?.id === channelItem.playlistId)) ?
                                    <FavoriteIcon fontSize={"inherit"} htmlColor="yellow" /> :
                                    <FavoriteBorderIcon fontSize={"inherit"} htmlColor="#fff" />
                                }
                            </div>
                        </div>
                    </Focusable>
                    })
                }
            </div>
        </div>
        {
            <div className={classNames(styles.videoContainer, { [styles.fullScreen]: isFullScr, })} style={channel?.url ? {} : { height: 1, width: 1 }}>
                {showChannelName && ChannelName}
                <div style={{
                    aspectRatio: videoState.aspectRatio,
                    maxHeight: '100%',
                    width: '100%',
                    backgroundColor: '#000',

                }}>
                    {/* <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            // zIndex: 1000,
                            aspectRatio: videoState.aspectRatio,
                            maxHeight: '100%',
                            width: '100%',
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {true && <SkypeIndicator color={theme.colors.primary} size={50} />}
                    </div> */}
                    {channel?.url && <VideoJS

                        options={options} onReady={handlePlayerReady}
                    // key={channel?.url}
                    // ref={videoRef}
                    // style={{
                    //     aspectRatio: videoState.aspectRatio,
                    //     maxHeight: '100%',
                    //     width: '100%',
                    // }}
                    // source={{
                    //     uri: (channel?.url || "").trim(),
                    // }}
                    // volume={videoState.isMuted ? 0 : 1}
                    // useNativeControls={true}
                    // onError={(e) => {
                    //     handleStreamError(e)
                    // }}
                    // paused={paused}
                    // resizeMode={'contain'}
                    // isLooping={false}
                    // shouldPlay={true}
                    />}
                </div>
            </div>
        }
    </div>
}