import { Rating } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Focusable } from "react-js-spatial-navigation";
import { useNavigate } from "react-router";
import { base } from "../../../utils/constants";
import { getCountOfEpisodes } from "../../../utils/getCountOfEpisodes";
import styles from "./movies.module.css";

const Movies = ({ allMovies, categories }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(()=>{
    const el = document.getElementById(window?.lastFocusedMovie)
    const activeEl = document.activeElement 
    if(el && activeEl.id !== "searchVod"){
      el.firstChild.focus()
    }
  }, [allMovies])

  return (
    <div className={styles.moviesList}>
      {allMovies.map((item) => {
        return (
          <div
            id={item._id}
            key={item._id}
            onClick={() => {
              navigate(`/vod/detail`, {
                state: { movie: item, categories: categories },
              });
            }}
          >
            <Focusable
              className={styles.movieItem}
              onFocus={()=>window.lastFocusedMovie = item._id}
              onClickEnter={() => {
                navigate(`/vod/detail`, {
                  state: { movie: item, categories: categories },
                });
              }}
            >
              <img className={styles.img} src={`${base}${item.postersm}`} alt="" />
              <div className={styles.movieItemInfo}>
                <div className={styles.title}>{item?.title?.en}</div>
                <div className={styles.year}>{item?.year}</div>
                <Rating
                  className={styles.stars}
                  name="half-rating-read"
                  defaultValue={item?.raiting / 2}
                  precision={0.1}
                  readOnly
                />
                <div className={styles.duration}>
                  {item.type === "movie"
                    ? Math.floor(item.duration / 60) + t(`VOD.minutes`)
                    : getCountOfEpisodes(item) + t(`VOD.episodes`)}
                </div>
                <div className={styles.price}>{`${item?.price ?? ''} ${item.priceSymbol ?? ''}`}</div>
                <div className={styles.price}>{item?.description?.en}</div>
              </div>
            </Focusable>

          </div>
        );
      })}
    </div>
  );
};

export default Movies;
