import React from "react";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import MovieIcon from "@material-ui/icons/Movie";
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import styles from "./styles.module.css";
import { Focusable } from "react-js-spatial-navigation";
import cx from "classnames";
import { useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router";
import { getSubuser, logout, removeSubuser, saveSubuserActiveSession } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import { keyMap } from "../../utils/constants";
import AdminNotifications from "../../components/AdminNotifications"


const MainScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const firstRef = useRef(null);
  const menusRef = useRef(null)
  const menuRefs = useRef([])

  useEffect(() => {
    firstRef.current.parentNode.focus();
  }, []);

  const handleFocus = (i) => {
    menuRefs.current[i].scrollIntoView({ behavior: "smooth", block: "end" })
  }

  const handleSwitchuser = () => {
    const usb = getSubuser()
    if (!usb) {
      logout()
      navigate('/')
      return
    }
    saveSubuserActiveSession()
    removeSubuser()
    navigate('/users')
  }

  const routes = [
    {
      icon: <LiveTvIcon fontSize={"large"} htmlColor={"#fff"} />,
      label: "TV",
      route: "/player",
    },
    {
      icon: <MovieIcon fontSize={"large"} htmlColor={"#fff"} />,
      label: "VOD",
      route: "/vod",
    },
    {
      icon: <PlaylistPlayIcon fontSize={"large"} htmlColor={"#fff"} />,
      label: "Playlist",
      route: "/playlist",
    },
    {
      icon: <SettingsOutlinedIcon fontSize={"large"} htmlColor={"#fff"} />,
      label: "Settings",
      route: "/settings",
    },
    {
      icon: <AccountCircleIcon fontSize={"large"} htmlColor={"#fff"} />,
      label: "Switch user",
      onEnter: handleSwitchuser,
    },
  ];

  const handleRoute = item => {
    item.onEnter && item.onEnter();
    !item.disabled && item.route && navigate(item.route);
  };


  const handleKeyUp = (e) => {
    if (e.which === keyMap.REMOTE_BACK || e.which === keyMap.KEY_D) {
      window.tizen && window.tizen.application.getCurrentApplication().exit();
    }
  }

  return (
    <div onKeyUp={handleKeyUp} className={styles.mainScr}>
      <AdminNotifications />
      <div ref={menusRef} className={styles.menusContainer} >
        {routes.map((item, i) => (
          <Focusable
            key={item.label}
            className={styles.focusable}
            onFocus={()=>handleFocus(i)}
            onClickEnter={() => handleRoute(item)}
          >
            <div
              onClick={() => handleRoute(item)}
              ref={(e) => {menuRefs.current[i] = e; if(i === 0){firstRef.current = e }}}
              id={item.label}
              className={cx(styles.actionBtn, {
                [styles.disabledBtn]: item.disabled,
              })}
            >
              {item.icon}
              <span style={{ textAlign: 'center' }} >{t(`MainScreen.${item.label}`)}</span>
              {/* {item.label} */}
            </div>
          </Focusable>
        ))}
      </div>
    </div>
  );
};

export default MainScreen;
