import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./components/layout/layout";
import {
  getSubuser,
  isLogged,
  saveDeviceKey,
  setSubuserAllowedTime,
} from "./utils/helpers";
import { getClientInfo } from "./utils/getClientInfo";
import Settings from "./screens/settings/settings";
import LoginScreen from "./screens/login/LoginScreen.js";
import UsersScreen from "./screens/usersScreen";
import MainScreen from "./screens/mainScreen";
import Player from "./screens/liveScreen/Player";
import PrivateRoute from "./routesHOC/privateRoute";
import EPGFull from "./components/epgFull";
import PublicRoute from "./routesHOC/publicRoute";
import { remoteKeysRegister } from "./utils/constants";
import VodScreen from "./screens/vodScreen";
import MovieDetail from "./screens/vodScreen/movieDetail";
import AddUser from "./screens/settings/AddUser/AddUser";
import ChangePass from "./screens/settings/ChangePass/ChangePass";
import UsersSettings from "./screens/settings/UsersSettings/UsersSettings";
import Options from "./screens/settings/Options/Options";
import SelectChannels from "./screens/settings/SelectChannels/SelectChannels";
import DailyPeriod from "./screens/settings/DailyPeriod/DailyPeriod";
import Warning from "./screens/warning/Warning";
import AgeRestriction from "./screens/settings/AgeRestriction/AgeRestriction";
import { useTranslation } from "react-i18next";
import Lang from "./screens/languagesScreen/Lang";
import SelectUser from "./screens/settings/SelectUser/SelectUser";
import Edit from "./screens/settings/Edit/Edit";
import Delete from "./screens/settings/Delete/Delete";
import jwtDecode from "jwt-decode";
import { Provider } from 'react-redux';

import {
  saveActiveStatusToServer,
  saveSessionEndStatusToServer,
} from "./api/user";
import MoviePlayer from "./screens/vodScreen/moviePlayer/MoviePlayer";
import Playlists from "./screens/playlists/playlists";
import { store } from "./Store/config.js"
const WebSocket = require('ws');

export default function App() {
  const navigate = useNavigate();
  const token = isLogged();
  const mainUser = token && jwtDecode(token);
  const activeUser = getSubuser();

  // const TimeFunc = item => {
  //   const allowedMil = item?.allowedHours * 60 * 1000;

  //   const timeStart = cookies.get(`start_${item.name}`);
  //   const timeLogOut = cookies.get(`LogOut_${item.name}`);

  //   const res = allowedMil - (timeLogOut - timeStart);

  //   cookies.set(`start_${item.name}`, Date.now(), { path: "/" });
  //   // if()

  //   setTimeout(() => {
  //     navigate("/warning", { state: item?.name });
  //   }, res || allowedMil);
  // };

  const tizenDeviceMethods = {
    getModelInfo: async function () {
      await window?.tizen?.systeminfo?.getPropertyValue?.("BUILD", function (build) {
        saveDeviceKey(build)
      }, function (error) {
      });
    },
    getWifiNetwork: async function () {
      await window?.tizen?.systeminfo?.getPropertyValue?.("WIFI_NETWORK", function (build) {
        saveDeviceKey({ wifiNetwork: build })
      }, function (error) {
      });
    },
    getEthernetNetwork: async function () {
      await window?.tizen?.systeminfo?.getPropertyValue?.("ETHERNET_NETWORK", function (build) {
        saveDeviceKey({ ethernetNetwork: build })
      }, function (error) {
      });
    },
  }

  const webOSDeviceMethods = {
    getModelInfo: function () {
      window.webOS.service.request('luna://com.webos.service.tv.systemproperty',
        {
          method: 'getSystemInfo',
          parameters: {
            keys: ['modelName', 'firmwareVersion', 'UHD', 'sdkVersion'],
          },

          onSuccess: function (build) {
            saveDeviceKey({
              model: build.modelName,
              buildVersion: build.sdkVersion,
              manufacturer: "LG",
              platform: "WebOS"
            })
          },
          onFailure: function (res) {
            console.log('system time fail', res);
          },
        });
    },
    getNetwork: function () {
      window.webOS.service.request('luna://com.palm.connectionmanager',
        {
          method: 'getStatus',

          onSuccess: function (res) {
            saveDeviceKey({
              ethernetNetwork: {
                status: res.wired.state,
                ipAddress: res.wired.ipAddress,
              },
              wifiNetwork: {
                status: res.wifi.state,
                ssid: res.wifi.ssid,
                ipAddress: res.wifi.ipAddress,
              },
              isInternetConnectionAvailable: res.isInternetConnectionAvailable
            })
          },
          onFailure: function (res) {
            console.log('system time fail', res);
          },
        });

    },
    getUDID: () => {
      window.webOS.service.request('luna://com.webos.service.sm', {
        method: 'deviceid/getIDs',
        parameters: {
          idType: ['LGUDID'],
        },
        onSuccess: function (inResponse) {
          saveDeviceKey({
            uuid: inResponse.idList[0].idValue,
            uuidType: inResponse.idList[0].idType,
          })
        },
        onFailure: function (inError) {
          return;
        },
      });
    }
  }




  useEffect(() => {
    (async () => {
      if (window.tizen) {
        await tizenDeviceMethods.getModelInfo()
        await tizenDeviceMethods.getWifiNetwork()
        await tizenDeviceMethods.getEthernetNetwork()
      } else if (window.webOS) {
        webOSDeviceMethods.getModelInfo()
        webOSDeviceMethods.getNetwork()
        webOSDeviceMethods.getUDID()
      } else {
        const info = getClientInfo()
        saveDeviceKey({
          model: info.isMobile ? "Mobile" : "PC",
          manufacturer: info.browser,
          platform: info.os
        })
      }
    })()


    window.addEventListener("unload", (e) => {
      const subuser = getSubuser();
      if (!window.tizen) {
        localStorage.removeItem("seenInstallApp")
      }
      if (subuser?.name && subuser?.allowedHours) {
        saveSessionEndStatusToServer({
          userId: mainUser.id,
          personalID: subuser.id,
        });
      }
    });

    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        const subuser = getSubuser();
        if (subuser?.name && subuser?.allowedHours) {
          saveSessionEndStatusToServer({
            userId: mainUser.id,
            personalID: subuser.id,
          });
        }
      } else {
        const subuser = getSubuser();
        if (subuser?.name && subuser?.allowedHours) {
          setSubuserAllowedTime(subuser, navigate, mainUser);
          saveActiveStatusToServer({
            userId: mainUser.id,
            personalID: subuser.id,
          });
        }
      }
    });

  }, []);

  useEffect(() => {
    if (window?.tizen?.tvinputdevice?.registerKeyBatch) {
      window.tizen.tvinputdevice.registerKeyBatch(remoteKeysRegister);
    } else if (window?.tizen?.tvinputdevice?.registerKey) {
      remoteKeysRegister.forEach((item) => {
        window.tizen.tvinputdevice.registerKey(item);
      });
    }
  }, []);

  // return <iframe autoFocus src="https://player.rippletv.io?pwa=true" style={{border: "none", width: "100vw", height: "100vh"}} allowfullscreen></iframe>


  return (
    <Provider store={store}>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <PublicRoute>
              <Layout>
                <LoginScreen />
              </Layout>
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/users"
          element={
            <PrivateRoute>
              <Layout>
                <UsersScreen />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/main"
          element={
            <PrivateRoute>
              <Layout>
                <MainScreen />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/playlist"
          element={
            <PrivateRoute>
              <Layout>
                <Playlists />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/vod"
          element={
            <PrivateRoute>
              <Layout>
                <VodScreen />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/vod/detail"
          element={
            <PrivateRoute>
              <Layout>
                <MovieDetail />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/moviePlayer"
          element={
            <PrivateRoute>
              <Layout>
                <MoviePlayer />
              </Layout>
            </PrivateRoute>
          }
        />
        {/* Aram */}
        <Route
          exact
          path="/player"
          element={
            <PrivateRoute>
              <Layout>
                <Player />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/fullEpg"
          element={
            <PrivateRoute>
              <EPGFull />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/settings"
          element={
            <PrivateRoute>
              <Layout>
                <Settings />
              </Layout>
            </PrivateRoute>
          }
        />

        {/* Aram */}
        <Route
          exact
          path="/languages"
          element={
            <PrivateRoute>
              <Layout>
                <Lang />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/add_users"
          element={
            <PrivateRoute>
              <Layout>
                <AddUser />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/change_password"
          element={
            <PrivateRoute>
              <Layout>
                <ChangePass />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/users_settings"
          element={
            <PrivateRoute>
              <Layout>
                <UsersSettings />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/select_user"
          element={
            <PrivateRoute>
              <Layout>
                <SelectUser />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/parental_control_options"
          element={
            <PrivateRoute>
              <Layout>
                <Options />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/edit"
          element={
            <PrivateRoute>
              <Layout>
                <Edit />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/delete"
          element={
            <PrivateRoute>
              <Layout>
                <Delete />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/select_channels"
          element={
            <PrivateRoute>
              <Layout>
                <SelectChannels />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/daily_period"
          element={
            <PrivateRoute>
              <Layout>
                <DailyPeriod />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/age_restriction"
          element={
            <PrivateRoute>
              <Layout>
                <AgeRestriction />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/warning"
          element={
            <PrivateRoute>
              <Layout>
                <Warning />
              </Layout>
            </PrivateRoute>
          }
        />

        {/* Aram */}
      </Routes>
    </Provider>
  );
}
