import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Focusable } from "react-js-spatial-navigation";
import { useNavigate } from "react-router";
import { api } from "../../../api/config";
import { keyMap } from "../../../utils/constants";
import { getActiveUser } from "../../../utils/helpers";
import cx from "classnames";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const AddUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nameInput = useRef(null);
  const pinInput = useRef(null);
  const addRef = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const inputsValues = useRef({});
  const [error, setError] = useState(false)

  useEffect(() => {
    nameInput.current.parentNode.focus();
  }, []);

  function goBack(e) {
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-1);
    }
  }

  function handleClick(ref) {
    ref.current.focus();
  }

  function handleEnter(e, ref) {
    e.preventDefault();

    inputsValues.current[e.target.name] = e.target.value;
    let code = e.which;
    if (code === keyMap.REMOTE_OK) {
      setTimeout(() => {
        if (ref.current.id === "myButton") {
          nameInput.current.focus();
        } else {
          ref.current.parentNode.nextSibling.focus();
        }
      }, 10);
    }
    
    setError(null)
    if (code === keyMap.ARROW_UP && ref.current.name === "pin") {
      nameInput.current.parentNode.focus();
    }

    if (code === keyMap.ARROW_DOWN) {
      ref.current.name === "name"
        ? pinInput.current.parentNode.focus()
        : ref.current.name === "pin"
        ? addRef.current.parentNode.focus()
        : nameInput.current.parentNode.focus();
    }
  }

  const createUser = async () => {
    const activeUser = getActiveUser();
    if(!inputsValues.current.name || !inputsValues.current.pin ){
      setError(t('fieldsRequired'))
      return
    }
    if(`${+inputsValues.current.pin}`.length > 4 || isNaN(+inputsValues.current.pin)){
      setError(t('pinError'))
      return
    }
    const res = await api.post(`/users/${activeUser.id}/subuser`, {
      name: inputsValues.current.name,
      pin: inputsValues.current.pin,
    });
    if (res?.data?.status === "success") {
      enqueueSnackbar(
        t(`User {{name}} successfully created`, {
          name: `${inputsValues.current.name}`,
        }),
        {
          variant: "success",
          autoHideDuration: 3000,
        }
      );
      return;
    }
    enqueueSnackbar(res?.message, {
      variant: "error",
      autoHideDuration: 3000,
    });
  };

  return (
    <div className={styles.mainAddUser} onKeyUp={(e) => goBack(e)}>
      <div className={styles.containerAddUser}>
        <div className={styles.addUser}>{t(`settings.Add User`)}</div>
        <div className={styles.form}>
          <Focusable
            className={styles.focusable}
            onClickEnter={() => handleClick(nameInput)}
          >
            <input
              name="name"
              className={styles.name}
              onClick={() => handleClick(nameInput)}
              // placeholder="Name*"
              placeholder={t(`settings.Name*`)}
              onKeyUp={(e) => handleEnter(e, nameInput)}
              ref={nameInput}
            />
          </Focusable>
          <Focusable
            className={styles.focusable}
            onClickEnter={() => handleClick(pinInput)}
          >
            <input
              name="pin"
              type="password"
              onClick={() => handleClick(pinInput)}
              className={styles.pin}
              placeholder={t(`settings.Pin*`)}
              onKeyUp={(e) => handleEnter(e, pinInput)}
              ref={pinInput}
            />
          </Focusable>
          {(!!error) && <>
            <div className={styles.helperText}> {error} </div>
          </> }
          <Focusable
            className={cx(styles.focusableBtn)}
            onClickEnter={() => {
              createUser();
            }}
          >
            <div onClick={() => {
              createUser();
            }} ref={addRef}>{t('Add_User')}</div>
          </Focusable>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
