import { Rating } from "@material-ui/lab";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Focusable } from "react-js-spatial-navigation";
import { useLocation, useNavigate, useParams } from "react-router";
import { ClipLoader } from "react-spinners";
import Modal from "../../../components/Modal";
import Serial from "../../../components/Vod/Serial/Serial";
import { base, keyMap } from "../../../utils/constants";
import { getCountOfEpisodes } from "../../../utils/getCountOfEpisodes";
import data from "../../../utils/VODFakeData.json";
import styles from "./styles.module.css";

const MovieDetail = () => {
  const { t, i18n } = useTranslation();
  const selectLanguageCode = i18n.language;
  const navigate = useNavigate();
  const focus = useRef(null);
  const [movie, setMovie] = useState({});
  const [genre, setGenre] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({ bool: false, episode: '' });
  // const params = useParams();
  const { state } = useLocation();
  const language = "en";

  useEffect(() => {
    setMovie(state.movie);

    let movieGanre = state?.movie?.genre.map((el) => {
      return state?.categories.find((obj) => +obj.id === el);
    });

    setGenre(movieGanre.map((item) => item.label).join(", "));
    setTimeout(() => {
      focus.current && focus.current.parentNode.focus();
    }, 600);
  }, [state]);

  const handleOpenModal = (episode, season) => {
    let info = {
      url: episode.url,
      name: season ? `${(movie?.title?.[selectLanguageCode] || movie?.title?.en)}: ${episode.label}` : (movie?.title?.[selectLanguageCode] || movie?.title?.en),
    }
    if (movie.price > 0) {
      setOpenModal({ bool: true, state: info });
    } else {
      handleShowMovie(info)
    }
  };

  const handleShowMovie = (state) => {
    navigate("/moviePlayer", { state });
  }

  return (
    <div
      className={styles.mainDetail}
      onKeyUp={(e) => {
        let code = e.which;
        if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
          navigate(-1);
        }
      }}
    >
      <div className={styles.containerDetail}>
        {isLoading ? (
          <div className={styles.loader}>
            <ClipLoader
              size={100}
              color={"#fff"}
              cssOverride={{
                borderWidth: "5px",
              }}
            />
          </div>
        ) : (
          <div className={styles.constextDetail}>
            <img className={styles.img} src={base + movie.posterlg} alt="" />
            <div className={styles.flex}>
              <div className={styles.leftSide}>
                <div className={styles.title}>
                  {movie?.title?.[selectLanguageCode] || movie?.title?.en}
                </div>
                <div className={styles.year}>{movie?.year}</div>

                <Rating
                  className={styles.stars}
                  name="half-rating-read"
                  value={movie.raiting / 2}
                  precision={0.1}
                  size={"large"}
                  readOnly
                />
                <div className={styles.price}>
                  {`${movie?.price ?? ''} ${movie?.priceSymbol ?? ''}`}
                </div>
                <div className={styles.genre}>{genre}</div>
                <div className={styles.durationSeconds}>
                  {movie.type === "movie"
                    ? Math.floor(movie.duration / 60) + " " + t(`VOD.minutes`)
                    : getCountOfEpisodes(movie) + " " + t(`VOD.episodes`)}
                </div>
              </div>
              <div className={styles.rightSide}>
                <div className={styles.descriptionCont}>
                  <div className={styles.descriptionNest}>
                    {movie?.description?.[selectLanguageCode] ||
                      movie?.description?.en}
                  </div>
                </div>
                {movie.type === "movie" ? (
                  <Focusable
                    className={styles.divBtn}
                    onClickEnter={() => handleOpenModal(movie)}
                  >
                    <button onClick={() => handleOpenModal(movie)} ref={focus}>Watch now</button>
                  </Focusable>
                ) : movie.type === "serial" ? (
                  <Serial
                    seasons={movie.seasons}
                    handleOpenModal={handleOpenModal}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        cancelText="cancel"
        title={`The rental fee for the movie ${movie.price === 0 ? "free" : movie.price + movie.priceSymbol
          } will be added to your paycheck for this month`}
        open={openModal.bool}
        setOpen={(bool) => setOpenModal({ ...openModal, bool })}
        // content
        okText="Agree"
        handleCancel={() => {
          setOpenModal({ ...openModal, bool: false });
        }}
        handleOk={() => handleShowMovie(openModal.state)}
        className={"newClass"}
      />
    </div>
  );
};

export default MovieDetail;
