import Hls from "hls.js";
import React, { useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-hls.js";
import { isLogged } from "../utils/helpers";
// import '@videojs/http-streaming'

const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const intervalIdRef = React.useRef(null);
  const { onReady } = props;
  const [aspectRatio, setAspectRatio] = useState("4:3")
  const options = {
    autoplay: true,
    responsive: false,
    muted: false, // initial play does not work on browser
    fill: true,
    bigPlayButton: false,
    fluid: true,
    html5: {
      hlsjsConfig: {
        // debug: true,
        xhrSetup: xhr => {
          xhr.setRequestHeader('Authorization', isLogged())
        }
      
      }
  }
  };

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));
      // player.hls.xhr ??= {}
      // player.hls.xhr.beforeRequest = function (options) {
      //   // Add your custom headers here
      //   options.headers = {
      //     ...options.headers,
      //     Authorization: 'header-value',
      //   };
  
      //   return options;
      // };
  
      // playerRef.current.src(options.sources);
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    // important for start from current live position
    //  playerRef.current.currentTime(0)

    //  setInterval(()=>{
    //   const err =  player.error()
    //   const isPlaying = !player?.paused()
    //   if(err || !isPlaying){
    //     player.handleSrc_(
    //       [
    //         {
    //           src: player.currentSrc(),
    //           type: 'application/x-mpegURL',
    //         },
    //       ],
    //       true
    //     );
    //   }
    // }, 5000)

    // player events

    //  player.tech_.on(Hls.Events.ERROR, function (e) {
    //      player.handleSrc_(
    //       [
    //         {
    //           src: player.currentSrc(),
    //           type: 'application/x-mpegURL',
    //         },
    //       ],
    //       true
    //     );

    //  })

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div id="videoCont" data-vjs-player style={{backgroundColor: "#000", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <video ref={videoRef} style={{maxWidth: "100vw", maxHeight: "100vh"}} className="video-js vjs-big-play-centered" />
    </div>
  );
};

export default VideoJS;
