import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { base, baseApi } from '../../../utils/constants';
import moment from 'moment';
import { api } from '../../../api/config';
import { clearedSymbols, getEPGTimeWithLocalTimezone, parse, parseToHourMinute, shiftEpgTime } from '../../../utils/helpers';

export default function EPGList({ currentFocusedChannel, setCurrentChannelsPrograms, currentChannel }) {
  const [epg, setEpg] = useState();
  const [currentProgram, setCurrentProgram] = useState();
  const getEpg = async () => {
    try {
    const {
      data: { epg: fullEpg },
    } = await api.post(`/epg/${currentFocusedChannel.epgId.epgId}/${currentFocusedChannel.epgId.dbId}`, {reserveEpg: currentFocusedChannel.reserveEpgId});
    for(let i=0; i<fullEpg.length; i++){
      fullEpg[i].start = shiftEpgTime(fullEpg[i].start)
      fullEpg[i].stop = shiftEpgTime(fullEpg[i].stop)
    }
    const currentProgramIndex = fullEpg.findIndex(item => {
      // return +item.start.split(' ')[0] > 20220317131500;
      return +item.start.split(' ')[0] < moment().format('YYYYMMDDHHmmss') && +item.stop.split(' ')[0] > moment().format('YYYYMMDDHHmmss');
    });
    setEpg(fullEpg);
    const currentStart = parseToHourMinute(
      fullEpg?.[currentProgramIndex]?.start
    );
    const currentStop = parseToHourMinute(fullEpg?.[currentProgramIndex]?.stop);
    const nextStart = parseToHourMinute(
      fullEpg?.[currentProgramIndex + 1]?.start
    );
    const nextStop = parseToHourMinute(
      fullEpg?.[currentProgramIndex + 1]?.stop
    );
    setCurrentChannelsPrograms({
      fullEpg,
      currentProgramIndex
    })
    setCurrentProgram({
      currentProgramIndex,
      current: {
        ...fullEpg?.[currentProgramIndex],
        start: currentStart,
        stop: currentStop,
      },
      next: {
        ...fullEpg?.[currentProgramIndex + 1],
        start: nextStart,
        stop: nextStop,
      },
    });
  } catch (error) {
    console.log('error', error);
    setCurrentChannelsPrograms(null)
    setCurrentProgram(null)
  }
  };

  const timeoutId = useRef()

  useEffect(() => {
    if (currentFocusedChannel?.epgId?.epgId && currentFocusedChannel?.epgId?.dbId) {
      if(timeoutId.current) clearTimeout(timeoutId.current)
      timeoutId.current = setTimeout(()=>{
        getEpg();
      }, 600)
    } else {
      setCurrentChannelsPrograms(null)
      setCurrentProgram(null);
    }
  }, [currentFocusedChannel, currentChannel]);

  return (
    <div className={styles.epgContainerInner}>
      <img height={80} src={`${base}/img/channels/${currentFocusedChannel?.image}`} />
      &nbsp;
      <div className={styles.epgCurrentValues}>
        <div className={styles.currentPlaying}>
          {currentProgram ? (
            <span>
              {/* <span className={styles.nowBadge} >Now</span> */}
              {' '}
              {currentProgram?.current?.start} - {currentProgram?.current?.stop}
              &nbsp;
              {currentProgram?.current?.title?.[0]?.value}{' '}
            </span>
          ) : (
            ''
          )}
        </div>
        <div>
          {currentProgram ? (
            <span>
              {' '}
              {currentProgram?.next?.start} - {currentProgram?.next?.stop}{' '}
              &nbsp;
              {currentProgram?.next?.title?.[0]?.value}{' '}
            </span>
          ) : (
            <span>no EPG found</span>
          )}
        </div>
      </div>
    </div>
  );
}
