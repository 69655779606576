import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Focusable } from "react-js-spatial-navigation";
import { useNavigate } from "react-router";
import { keyMap } from "../../../utils/constants";
import styles from "./search.module.css";

const Search = ({ setSearch, allMovies }) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const inputFocus = useRef(null);

  // const handleSearch = value => {
  //   let searchedArr = movies.filter(item =>
  //     item.title.en.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setSearch(searchedArr);
  // };

  useEffect(() => {
    if (!window?.lastFocusedMovie) {
      inputFocus.current.parentNode.focus();
    }
  }, []);

  function handleClick() {
    inputFocus.current.focus();
  }

  function handleEnter(e) {
    let code = e.which;
    if (code === keyMap.REMOTE_OK || code === 13) {
      setTimeout(() => {
        setSearch(text);
        inputFocus.current.parentNode.focus();
      }, 10)
    }else if(code === keyMap.ARROW_DOWN){
      const el = document.getElementById(allMovies?.[0]?._id)
      if(el){
        el.firstChild.focus()
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setSearch(text);
    }, 600)
  }, [text])

  return (
    <div onClick={() => handleClick()} className={styles.search}>
      <Focusable onClickEnter={() => handleClick()}>
        <input
          ref={inputFocus}
          onKeyUp={(e) => handleEnter(e)}
          type="text"
          id="searchVod"
          // placeholder="Search"
          placeholder={t(`VOD.Search`)}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Focusable>
    </div>
  );
};

export default Search;
