import React, { useEffect, useState } from 'react';
import { emailValidator } from '../../helpers/emailValidator.js';
import { passwordValidator } from '../../helpers/passwordValidator.js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getCreds, removeCreds, saveCreds, saveToken } from '../../utils/helpers.js';
import cx from 'classnames';
import {
  Focusable,
} from 'react-js-spatial-navigation';
import { api } from '../../api/config.js';
import styles from './style.module.css'
import { ClipLoader } from 'react-spinners';
import { keyMap } from '../../utils/constants.js';
import { useTranslation } from 'react-i18next';
// import logo from "../../assets/images/iconTransparent.png"
import { useSnackbar } from 'notistack';


export default function LoginScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [loading, setLoading] = useState(false)
  const [remember, setRemember] = useState(true)
  const { enqueueSnackbar} = useSnackbar();
  useEffect(()=>{
    const creds = getCreds()
    if(creds?.email && creds?.password){
      setEmail({ value: creds.email, error: '' })
      setPassword({ value: creds.password, error: '' })
    }
  }, [])

  const onLoginPressed = () => {
    document.getElementById('loginButton').blur();
    setLoading(true)
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);

    if (emailError || passwordError) {
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      document.getElementById('login').focus();
      setLoading(false)
      return;
    }
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    api
      .post(`/users/login`, {
        email: email.value,
        password: password.value,
        remember
      })
      .then(res => {
        setLoading(false)
        if (res.status === 'error') {
          setEmail({ ...email, error: t(res.message) });
          document.getElementById('login').focus();
          return
        }
        if(remember){
          saveCreds({email: email.value, password: password.value})
        } else {
          removeCreds()
        }
        
        saveToken(res.data.token);
        // const userInfo = jwtDecode(res.data.token)
        navigate('/users');
      })
      .catch(err => {
        setLoading(false)
        setEmail({ ...email, error: t('Incorrect login or password') });
        document.getElementById('login').focus();
      });
  };

  const handleFocus = id => {
    document.getElementById(id).focus();
  };

  const handlekeyPress = (e, id) => {
    if (e.which === keyMap.REMOTE_BACK || e.which === keyMap.KEY_D) {
      window.tizen && window.tizen.application.getCurrentApplication().exit();
    }
    const nextKeys = [keyMap.ARROW_DOWN, keyMap.REMOTE_OK]
    const prevKeys = [keyMap.ARROW_UP]
    if (e.which === keyMap.REMOTE_OK && id === "remember") {
      setRemember(!remember)
      return
    }
    if (nextKeys.includes(e.which)) {
      let nextId = id === 'login' ? 'pass' : id === 'pass' ? "remember" : id === 'remember' ? 'loginButton' : ''
      document.getElementById(nextId).parentNode.focus();
    } else if (prevKeys.includes(e.which)) {
      let nextId = id === 'loginButton' ? "remember" : id === 'remember' ? 'pass' : id === 'pass' ? 'login' : ''
      document.getElementById(nextId).parentNode.focus();
    }
  };

  const [count, setCount] = useState(0)

  const handleKeyUp = (e) => {
    if (e.which === keyMap.REMOTE_BACK || e.which === keyMap.KEY_D) {
      if(count === 2){
        window.tizen && window.tizen.application.getCurrentApplication().exit();
      }else{
        setCount(prev=>prev+1)
        enqueueSnackbar(t("settings.exitText"), {
          variant: 'info',
          autoHideDuration: 3000,
        });
      }
    }
  }

  return (
    <div className={styles.background} onKeyUp={handleKeyUp}>
      {/* <div className={styles.container} > */}
        <div className={styles.logo} style={{width: window.tizen ? "auto" : "40%"}} />
        <div className={styles.wrapper}>
          {/* <h1 className={styles.title}>
          {t(`login.Welcome`)}
          </h1> */}
          <Focusable
            id="focusLogin"
            onClickEnter={() => {
              handleFocus('login');
            }}>
            <input
              onClick={() => {
                handleFocus('login');
              }}
              id="login"
              className={cx(styles.input)}
              onKeyUp={e => {
                handlekeyPress(e, 'login');
              }}
              autoFocus
              // placeholder="Login"
              placeholder={t(`login.Login`)}
              value={email.value}
              onChange={e => setEmail({ value: e.target.value, error: '' })}
            />
          </Focusable>
          <Focusable
            onClickEnter={() => {
              handleFocus('pass');
            }}>
            <input
              onClick={() => {
                handleFocus('pass');
              }}
              className={cx(styles.input)}
              onKeyUp={e => {
                handlekeyPress(e, 'pass');
              }}
              id="pass"
              // placeholder="Password"
              placeholder={t(`login.Password`)}
              value={password.value}
              onChange={e => setPassword({ value: e.target.value, error: '' })}
              type="password"
            />
          </Focusable>
          <div className={styles.checkboxContainer}>
            <span className={styles.label}>{t('login.rememberme')}</span>
            <Focusable
              onClickEnter={() => {
                setRemember(!remember)
              }}>
              <input
                onClick={() => {
                  setRemember(!remember)
                }}
                className={cx(styles.checkbox)}
                onKeyUp={e => {
                  handlekeyPress(e, 'remember');
                }}
                id="remember"
                // placeholder="Password"
                checked={remember}
                onChange={e => setRemember(e.target.checked)}
                type="checkbox"
              />
            </Focusable>
          </div>
          {(!!email.error || !!password.error) && <span className={styles.helperText}> {email.error} <br /> {password.error} </span>}
          <Focusable onClickEnter={onLoginPressed}>
            <button
              className={cx(styles.submit)}
              id="loginButton"
              onClick={onLoginPressed}
              onKeyUp={e => {
                handlekeyPress(e, 'loginButton');
              }}
            >
              {loading && <ClipLoader size={50} color='#fff' cssOverride={{ borderWidth: 3 }} />} <span style={{ marginLeft: loading ? 20 : 0, whiteSpace: "nowrap" }}>{t(`login.login`)}</span>
            </button>
          </Focusable>
        </div>
      {/* </div> */}
    </div>
  );
}
