import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Focusable } from "react-js-spatial-navigation";
import { useLocation, useNavigate } from "react-router";
import { keyMap } from "../../../utils/constants";
import styles from "./options.module.css";

const Options = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const focudDiv = useRef(null);
  const location = useLocation();

  useEffect(() => {
    focudDiv.current.parentNode.focus();
  }, []);

  function goBack(e) {
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-1);
    }
  }

  const options = [
    {
      label: "Select channels",
      disabled: false,
      onEnter: () => {
        navigate("/select_channels", { state: location.state });
      },
    },
    {
      label: "Daily period",
      disabled: false,
      onEnter: () => {
        navigate("/daily_period", { state: location.state });
      },
    },
    {
      label: "Age-Restriction",
      disabled: false,
      onEnter: () => {
        navigate("/age_restriction", { state: location.state });
      },
    },
  ];

  return (
    <div className={styles.mainOptions} onKeyUp={(e) => goBack(e)}>
      <div className={styles.containerOptions}>
        <div className={styles.control}>{t(`settings.Parental control`)}</div>
        {options.map((item, i) => (
          <Focusable
            key={i + Math.random()}
            className={styles.focusable}
            onClickEnter={item.onEnter}
          >
            <div onClick={item.onEnter} className={styles.btnDiv} ref={i === 0 ? focudDiv : null}>
              {t(`settings.${item.label}`)}
            </div>
          </Focusable>
        ))}
      </div>
    </div>
  );
};

export default Options;
