import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { getActiveUser, getSubuser, isLogged } from "../../utils/helpers";
import styles from "./styles.module.css";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import SpatialNavigation, { Focusable } from "react-js-spatial-navigation";
import { useLocation, useNavigate } from "react-router";
import { useRef } from "react";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { saveActiveStatusToServer } from "../../api/user";

export default function Header() {
  // const navigate = useNavigate();
  // const [timeLeft, setTimeLeft] = useState();
  const { t } = useTranslation();
  const cookies = new Cookies();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState({ name: "" });
  const [time, setTime] = useState(
    moment().format("dddd, MMMM Do YYYY, HH:mm")
  );
  const intervalId = useRef(null);
  const token = isLogged();
  const mainUser = token && jwtDecode(token);
  const subuser = getSubuser();
  const [progressPercent, setProgressPercent] = useState(0);
  const [haveInterval, setHaveInterval] = useState(false);
  const [remining, setRemining] = useState(0);

  //   useEffect(() => {
  //     if (!subuser?.allowedHours || !subuser) {
  //       return;
  //     }

  //     const allowedHours = cookies.get(`@${subuser?.name}_time`);
  //     if (!allowedHours) {
  //       cookies.set(`@${subuser?.name}_time`, subuser?.allowedHours * 60, {
  //         path: "/",
  //       });
  //       setTimeLeft(cookies.get(`@${subuser?.name}_time`));
  //       return;
  //     }
  //     setTimeLeft(allowedHours);
  //   }, [location.pathname]);

  //   // ------------

  //   useEffect(() => {
  //     if (!subuser?.allowedHours || !subuser) {
  //       return;
  //     }
  //     if (timeLeft <= 0) {
  //       backPage();
  //       return;
  //     }
  //     const timerId = setInterval(() => {
  //       setTimeLeft(timeLeft => timeLeft - 1);
  //     }, 1000);
  //     return () => {
  //       clearInterval(timerId);
  //     };
  //   }, [timeLeft]);

  //   useEffect(() => {
  //     cookies.set(`@${subuser?.name}_time`, timeLeft, {
  //       path: "/",
  //     });
  //   }, [timeLeft]);


  //   const backPage = () => {
  //     if (
  //       location.pathname !== "/" ||
  //       location.pathname !== "users" ||
  //       location.pathname !== "/warning"
  //     ) {
  //       navigate("/warning", { state: subuser?.name });
  //     }
  //   };

  // -----------------------
  // -----------------------
  // -----------------------
  // -----------------------

  useEffect(() => {
    setTime(moment().format("dddd MMMM Do YYYY HH:mm"));
    const secondsToRound = 60 - +moment().format("ss");

    setTimeout(() => {
      setTime(moment().format("dddd MMMM Do YYYY HH:mm"));
      intervalId.current = setInterval(() => {
        setTime(moment().format("dddd MMMM Do YYYY HH:mm"));
      }, 60000);
    }, secondsToRound * 1000);

    return () => {
      const intervalProgressId = cookies.get("intervalId") || localStorage.getItem('intervalId');
      if (intervalProgressId) {
        clearInterval(intervalProgressId);
      }
      intervalId.current && clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    const user = getSubuser();
    let intervalId;
    if (user) {
      setUserInfo(user);
      if (user?.allowedHours && !haveInterval) {
        const allowedMillis = user?.allowedHours * 60 * 60 * 1000;
        const activeMillis = user?.activeMillis || 0;
        const timeoutMillis = allowedMillis - activeMillis;

        setRemining(timeoutMillis);

        const now = new Date();
        const endOfDay = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59,
          999
        );

        // cookies.set(`start_${user.name}`, Date.now(), {
        //   path: "/",
        //   expires: endOfDay,
        // });

        // saveActiveStatusToServer({
        //   userId: mainUser.id,
        //   personalID: subuser.id,
        // });

        intervalId = setInterval(() => {
          const user = getSubuser();
          //  ????
          if (!user) {
            setRemining(0);
          }
          setRemining((prev) => {
            if (((prev - 1000) * 100) / allowedMillis <= 0) {
              clearInterval(intervalId);
              setHaveInterval(false);
              return 0;
            }
            setProgressPercent(((prev - 1000) * 100) / allowedMillis);
            return prev - 1000;
          });
        }, 1000);
        localStorage.setItem('intervalId', intervalId)
        cookies.set("intervalId", intervalId, { path: "/", expires: endOfDay });

        setHaveInterval(true);
      }
    } else {
      setUserInfo(null);
    }
  }, [location.pathname]);

  if(location.pathname.includes('moviePlayer')){
    return null
  }

  return (
    <div className={styles.header}>
      <div>
        {/* Name &nbsp; Surname */}
        {userInfo?.name}
      </div>
      <div>{time}</div>
      <div>
        {!!progressPercent && !!haveInterval && !!remining && (
          <>
            <div className={styles.remaining}>
              {t("remaining")}:{" "}
              {moment
                .utc(
                  moment.duration(remining, "milliseconds").as("milliseconds")
                )
                .format("HH:mm:ss")}
            </div>
            <div className={styles.progressCont}>
              <div
                className={styles.progress}
                style={{ width: `${progressPercent}%` }}
              ></div>
            </div>
          </>
        )}

        {/* <Focusable onClickEnter={handleMenu} >
                <IconButton>
                    <Menu fontSize='large' />
                </IconButton>
            </Focusable> */}
      </div>
    </div>
  );
}
