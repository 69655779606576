import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Focusable } from "react-js-spatial-navigation";
import { useNavigate } from "react-router";
import { keyMap } from "../../utils/constants";
import styles from "./lang.module.css";
import Flag from 'react-world-flags'
import { getActiveUser, getSubuser, saveSubuser } from "../../utils/helpers";
import { api } from "../../api/config";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { CheckBox, CheckBoxOutlined } from "@material-ui/icons";
import { changeMomentLanguage } from "../../utils/changeMomentLanguage";

const Lang = () => {
  const {enqueueSnackbar} = useSnackbar()
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const ref = useRef();
  let loadBool = false;
  const subuser = getSubuser()

  useEffect(() => {
    ref.current.firstChild.focus();
  }, []);

  const languagesArr = [
    {
      label: "Հայերեն",
      lang: "am",
      countryCode: "am"
    },
    {
      label: "Русский",
      lang: "ru",
      countryCode: "ru"
    },
    {
      label: "English",
      lang: "en",
      countryCode: "us"
    },
  ];

  const switcher = async lang => {
    changeMomentLanguage(lang);
    i18n.changeLanguage(lang);
    const activeUser = getActiveUser();
    const res = await api.put(
      `/users/${activeUser.id}/subuser/${subuser.id}`,
      {
        language: lang,
      }
    );

    if (res.data.status === "success") {
      enqueueSnackbar(t(`Settings saved successfully`), {
        variant: "success",
        autoHideDuration: 3000,
      });
      saveSubuser({...subuser, language: lang})
      navigate(-1)
      return;
    }
    enqueueSnackbar(t("An error occurred, please try again later"), {
      variant: "error",
      autoHideDuration: 3000,
    });
  };

  function goBack(e) {
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-1);
    }
  }

  return (
    <div className={styles.mainLang} onKeyUp={e => goBack(e)}>
      <div className={styles.containerLang}>
        <div className={styles.title}>{t(`Lang.languages`)}</div>
        <div className={styles.flex} ref={ref}>
          {languagesArr.map((item, ind) => {
            loadBool = true;
            console.log(subuser.language, item.lang)
            return (
              <Focusable
                key={ind}
                className={classNames(styles.langItem, {[styles.selected]: subuser.language === item.lang})}
                onClickEnter={() => switcher(item.lang)}
              >
                <div onClick={() => switcher(item.lang)} className={classNames(styles.item)}>
                  {subuser.language === item.lang && <span className="top-right-corner"><CheckBoxOutlined/></span>}
                  <div><Flag height="50" code={ item.countryCode } /></div>
                  <div>{item.label}</div>
                </div>
              </Focusable>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Lang;
