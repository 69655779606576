import React, { useState } from "react";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import styles from "./styles.module.css";
import { Focusable } from "react-js-spatial-navigation";
import cx from "classnames";
import { useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router";
import {
  getDevice,
  getSubuser,
  isLogged,
  removeSubuser,
  saveSubuser,
} from "../../utils/helpers";
import jwtDecode from "jwt-decode";
import { api } from "../../api/config";
import { useSnackbar } from "notistack";
import { keyMap, subuserRoles } from "../../utils/constants";
import Cookies from "universal-cookie";
import { Lock } from "@material-ui/icons";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { changeMomentLanguage } from "../../utils/changeMomentLanguage";
import { saveActiveStatusToServer } from "../../api/user";

const MainScreen = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [subusers, setSubusers] = useState(null);
  const [pinInput, setPinInput] = useState(null);
  const [pin, setPin] = useState("");
  const inpRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation();

  const handleSubuserLogin = (subuser) => {
    try {
      
      const token = isLogged();
      const mainUser = token && jwtDecode(token);
      // const activeUser = getSubuser();
      saveActiveStatusToServer({
        userId: mainUser.id,
        personalID: subuser.id,
      });
      saveSubuser(subuser);
      if (subuser.language) {
        i18n.changeLanguage(subuser.language);
        changeMomentLanguage(subuser.language);
      }
      const timeoutId = cookies.get("timeoutId", { path: "/" });
      if (subuser?.allowedHours) {
        const allowedMillis = subuser?.allowedHours * 60 * 60 * 1000;
        const activeMillis = subuser?.activeMillis || 0;
        const timeoutMillis = allowedMillis - activeMillis;
  
        if (timeoutMillis < 0) {
          navigate("/warning", { state: subuser?.name });
          return;
        }
        const timeoutId = setTimeout(() => {
          localStorage.removeItem('timeoutId')
          cookies.remove("timeoutId", { path: "/" });
          navigate("/warning", { state: subuser?.name });
        }, timeoutMillis);
        localStorage.setItem('timeoutId', timeoutId)
        cookies.set("timeoutId", timeoutId, { path: "/" });
      }
      setTimeout(() => {
        !subuser.disabled && navigate("/main");
      });
    } catch (error) {
      // alert(error.toString())
    }
  };

  const getSubusers = async (userInfo) => {
    try {
      setLoading(true)
      const {
        data: { subusers },
      } = await api.get(`/users/${userInfo.id}/subuser`);
      setSubusers(subusers);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setSubusers("error");
    }
  };

  useEffect(() => {
    const token = isLogged();
    if (token) {
      const userInfo = jwtDecode(token);
      getSubusers(userInfo);
    }
  }, []);
  useEffect(()=>{
    if(subusers?.length){
      setTimeout(()=>{
        const el = document.getElementById(subusers?.[0]?.id)
        if(el){
          el.firstChild.focus()
        }
      }, 400)
    }
  }, [subusers?.length])
  useEffect(() => {
    if (subusers) {
      
      if (Array.isArray(subusers)) {
        if (!subusers.length) {
          navigate("/main");
        } else if (subusers.length === 1 && !subusers[0].pin) {
          handleSubuserLogin(subusers[0]);
          navigate("/main");
        }
      } else {
        navigate("/main");
        // enqueueSnackbar(`you have no subusers`, {
        //     variant: 'info',
        //     autoHideDuration: 3000,
        // });
      }
    }
  }, [subusers]);

  const firstRef = useRef(null);

  useEffect(() => {
    firstRef.current && firstRef.current.parentNode.focus();
  }, [subusers]);

  const handleEnter = (item) => {
    if (item.pin) {
      setPinInput(item.name);
      setTimeout(() => {
        if (inpRef.current) {
          inpRef.current.focus();
        }
      }, 0);
      return;
    }
    handleSubuserLogin(item);
    !item.disabled && navigate("/main");
  };

  const handlePinCheck = (pin, item) => {
    if (+pin === +item.pin) {
      handleSubuserLogin(item);
    }
  };

  const handleKeyUp = (e, item) => {
    const backKeys = [keyMap.ARROW_BACK, keyMap.KEY_D, keyMap.ARROW_UP];
    if (backKeys.includes(e.which)) {
      const doc = document.getElementById(item.name);
      setPin("");
      doc.parentNode.focus();
      setPinInput(null);
    }
  };

  // const info = useRef(getDevice()) 

  return (
    <div className={styles.mainScr}>
      <div className={styles.containerUser}>
        <div className={styles.usersDiv}>
          {/* /!\ HELPER /!\ uncomment if need to output device info /!\ HELPER /!\ */}
        {/* <div style={{fontSize: "30px", color: 'red'}}>
          {Object.entries(info.current || {nothing: ""}).map(item=>item.join(":")).join(", \n")}
        </div> */}
          {loading ? <ClipLoader size={100} color='#fff' cssOverride={{ borderWidth: 5 }} /> : 
          subusers?.map?.((item, i) => (
            <div id={item.id} key={item.id} style={{ padding: "20px" }}>
              <Focusable
                className={styles.focusable}
                onClickEnter={() => handleEnter(item)}
              >
                <div
                onClick={() => handleEnter(item)}
                  ref={i === 0 ? firstRef : null}
                  id={item.name}
                  className={cx(styles.actionBtn, {
                    [styles.disabledBtn]: item.disabled,
                  })}
                >
                  {item.pin && (
                    <span className={styles.lockIcon}>
                      <Lock />
                    </span>
                  )}
                  {item?.role === subuserRoles.subAdmin && (
                    <span className={styles.adminIcon}>
                      <VerifiedUserIcon />
                    </span>
                  )}
                  <AccountBoxIcon />
                  <span className={styles.name}>{item.name}</span>
                </div>
              </Focusable>
              {pinInput === item.name && (
                <>
                  <Focusable>
                    <input
                      ref={inpRef}
                      onKeyUp={(e) => {
                        handleKeyUp(e, item);
                      }}
                      maxLength={4}
                      autoFocus
                      className={styles.input}
                      value={pin}
                      onChange={(e) => {
                        setPin(e.target.value);
                        handlePinCheck(e.target.value, item);
                      }}
                      type="password"
                    />
                  </Focusable>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainScreen;
