export const base = "https://api.rippletv.io"
// export const base = "http://176.124.99.50:50070"
// export const base = 'http://127.0.0.1:50120';
export const APP_VERSION = "v2.2.1"
export const baseApi = `${base}/api/v1`;

export const remoteKeysRegister = [
    'ChannelUp', 
    'ChannelDown', 
    '0', 
    '1', 
    '2', 
    '3', 
    '4', 
    '5', 
    '6', 
    '7', 
    '8', 
    '9', 
    'ColorF0Red', 
    'ColorF1Green', 
    'ColorF2Yellow', 
    'ColorF3Blue'
]

export const keyMap = window?.tizen ? {
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,

    REMOTE_BACK: 10009,
    REMOTE_OK: 13,
    REMOTE_CHANNEL_UP: 427,
    REMOTE_CHANNEL_DOWN: 428,
    REMOTE_VOLUME_UP: 447,
    REMOTE_VOLUME_DOWN: 448,
    REMOTE_VOLUME_MUTE: 449,
    REMOTE_COLOR_RED: 403,
    REMOTE_COLOR_GREEN: 404,
    REMOTE_COLOR_YELLOW: 405,
    REMOTE_COLOR_BLUE: 406,
    REMOTE_INFO: 457,
    REMOTE_CHANNEL_LIST: 10073,
    REMOTE_MEDIAFASTFORWARD: 417,
    REMOTE_MEDIAREWIND: 412,
    REMOTE_MEDIAPLAY: 415,
    REMOTE_MEDIAPLAYPAUSE: 10252,

    REMOTE_0: 48,
    REMOTE_1: 49,
    REMOTE_2: 50,
    REMOTE_3: 51,
    REMOTE_4: 52,
    REMOTE_5: 53,
    REMOTE_6: 54,
    REMOTE_7: 55,
    REMOTE_8: 56,
    REMOTE_9: 57,

    KEY_D: 68,
    KEY_Y: 89,
    KEY_R: 82,
} : {
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,

    REMOTE_BACK: 461,
    REMOTE_OK: 13,
    REMOTE_CHANNEL_UP: 427,
    REMOTE_CHANNEL_DOWN: 428,
    REMOTE_VOLUME_UP: 447,
    REMOTE_VOLUME_DOWN: 448,
    REMOTE_VOLUME_MUTE: 449,
    REMOTE_COLOR_RED: 403,
    REMOTE_COLOR_GREEN: 404,
    REMOTE_COLOR_YELLOW: 405,
    REMOTE_COLOR_BLUE: 406,
    REMOTE_INFO: 457,
    REMOTE_CHANNEL_LIST: 10073,
    REMOTE_MEDIAFASTFORWARD: 417,
    REMOTE_MEDIAREWIND: 412,
    REMOTE_MEDIAPLAY: 415,
    REMOTE_MEDIAPLAYPAUSE: 19,

    REMOTE_0: 48,
    REMOTE_1: 49,
    REMOTE_2: 50,
    REMOTE_3: 51,
    REMOTE_4: 52,
    REMOTE_5: 53,
    REMOTE_6: 54,
    REMOTE_7: 55,
    REMOTE_8: 56,
    REMOTE_9: 57,

    KEY_D: 68,
    KEY_Y: 89,
    KEY_R: 82,
} 

export const keymapNumbers= [
   48,
   49,
   50,
   51,
   52,
   53,
   54,
   55,
   56,
   57,
]

export const keymapNumbersWeb = new Array(10).fill(null).map((_, i) => i+96)

export const Hls = {
    Events: {
        "MEDIA_ATTACHING": "hlsMediaAttaching",
        "MEDIA_ATTACHED": "hlsMediaAttached",
        "MEDIA_DETACHING": "hlsMediaDetaching",
        "MEDIA_DETACHED": "hlsMediaDetached",
        "BUFFER_RESET": "hlsBufferReset",
        "BUFFER_CODECS": "hlsBufferCodecs",
        "BUFFER_CREATED": "hlsBufferCreated",
        "BUFFER_APPENDING": "hlsBufferAppending",
        "BUFFER_APPENDED": "hlsBufferAppended",
        "BUFFER_EOS": "hlsBufferEos",
        "BUFFER_FLUSHING": "hlsBufferFlushing",
        "BUFFER_FLUSHED": "hlsBufferFlushed",
        "MANIFEST_LOADING": "hlsManifestLoading",
        "MANIFEST_LOADED": "hlsManifestLoaded",
        "MANIFEST_PARSED": "hlsManifestParsed",
        "LEVEL_SWITCH": "hlsLevelSwitch",
        "LEVEL_SWITCHING": "hlsLevelSwitching",
        "LEVEL_SWITCHED": "hlsLevelSwitched",
        "LEVEL_LOADING": "hlsLevelLoading",
        "LEVEL_LOADED": "hlsLevelLoaded",
        "LEVEL_UPDATED": "hlsLevelUpdated",
        "LEVEL_PTS_UPDATED": "hlsLevelPtsUpdated",
        "AUDIO_TRACKS_UPDATED": "hlsAudioTracksUpdated",
        "AUDIO_TRACK_SWITCH": "hlsAudioTrackSwitch",
        "AUDIO_TRACK_SWITCHING": "hlsAudioTrackSwitching",
        "AUDIO_TRACK_SWITCHED": "hlsAudioTrackSwitched",
        "AUDIO_TRACK_LOADING": "hlsAudioTrackLoading",
        "AUDIO_TRACK_LOADED": "hlsAudioTrackLoaded",
        "SUBTITLE_TRACKS_UPDATED": "hlsSubtitleTracksUpdated",
        "SUBTITLE_TRACK_SWITCH": "hlsSubtitleTrackSwitch",
        "SUBTITLE_TRACK_LOADING": "hlsSubtitleTrackLoading",
        "SUBTITLE_TRACK_LOADED": "hlsSubtitleTrackLoaded",
        "SUBTITLE_FRAG_PROCESSED": "hlsSubtitleFragProcessed",
        "INIT_PTS_FOUND": "hlsInitPtsFound",
        "FRAG_LOADING": "hlsFragLoading",
        "FRAG_LOAD_PROGRESS": "hlsFragLoadProgress",
        "FRAG_LOAD_EMERGENCY_ABORTED": "hlsFragLoadEmergencyAborted",
        "FRAG_LOADED": "hlsFragLoaded",
        "FRAG_DECRYPTED": "hlsFragDecrypted",
        "FRAG_PARSING_INIT_SEGMENT": "hlsFragParsingInitSegment",
        "FRAG_PARSING_USERDATA": "hlsFragParsingUserdata",
        "FRAG_PARSING_METADATA": "hlsFragParsingMetadata",
        "FRAG_PARSING_DATA": "hlsFragParsingData",
        "FRAG_PARSED": "hlsFragParsed",
        "FRAG_BUFFERED": "hlsFragBuffered",
        "FRAG_CHANGED": "hlsFragChanged",
        "FPS_DROP": "hlsFpsDrop",
        "FPS_DROP_LEVEL_CAPPING": "hlsFpsDropLevelCapping",
        "ERROR": "hlsError",
        "DESTROYING": "hlsDestroying",
        "KEY_LOADING": "hlsKeyLoading",
        "KEY_LOADED": "hlsKeyLoaded",
        "STREAM_STATE_TRANSITION": "hlsStreamStateTransition"
    }
}




export const subuserRoles = {
    subAdmin: 'subAdmin'
}