import React, { useState } from "react";
import Header from "../header/header";
import styles from "./style.module.css";
import PWAInstallModal from "../PWAInstallModal";

export default function Layout({ children }) {

  const [open, setOpen] = useState(!window.tizen && !localStorage.getItem("seenInstallApp"))
  return (
    <div className={styles.layout}>
      <PWAInstallModal open={open} setOpen={setOpen} />
      <Header />
      {children}
    </div>
  );
}
