import jwtDecode from "jwt-decode";
import moment from "moment";
import Cookies from "universal-cookie";
import { saveSessionEndStatusToServer } from "../api/user";

const cookies = new Cookies();

export const saveDevice = (token) => {
  if (window.tizen || window.webos) {
    token && localStorage.setItem('@deviceInfo', JSON.stringify(token))
  }
}

export const saveDeviceKey = (token) => {
  let info
  const infos = localStorage.getItem('@deviceInfo')
  if (infos) {
    info = JSON.parse(infos)
  }
  token && localStorage.setItem('@deviceInfo', JSON.stringify({ ...(info || {}), ...token }))
}

export const getDevice = () => {
  const device = localStorage.getItem('@deviceInfo')
  if (device) {
    return JSON.parse(device)
  } else {
    return {}
  }
}

export const saveSubuser = (token) => {
  if (window.tizen || window.webos) {
    token && localStorage.setItem('@subuserinfo', JSON.stringify(token))
  } else {
    cookies.set("@subuserinfo", token, { path: "/" });
  }
};

export const getSubuser = () => {
  if (window.tizen || window.webos) {
    const subuser = localStorage.getItem("@subuserinfo")
    if (subuser) {
      return JSON.parse(subuser)
    }
  } else {
    let subuser = cookies.get('@subuserinfo')
    return subuser
  }
};

export const removeSubuser = () => {
  if (window.tizen || window.webos) {
    localStorage.removeItem('@subuserinfo')
  } else {
    cookies.remove("@subuserinfo", { path: "/" });
  }
};

export const isLogged = () => {
  if (window.tizen || window.webos) {
    return localStorage.getItem('@token');
  } else {
    return cookies.get("@token")
  }
};

export const getActiveUser = () => {
  let token;
  if (window.tizen || window.webos) {
    token = localStorage.getItem('@token')
  } else {
    token = cookies.get('@token')
  };

  if (token) {
    return jwtDecode(token)
  }
}

export const logout = () => {
  // saveSubuserActiveSession();
  removeSubuser();
  if (window.TopTVAppData.token) {
    delete window.TopTVAppData.token
  }
  if (window.tizen || window.webos) {
    localStorage.removeItem('@token');
  } else {
    cookies.remove("@token", { path: "/" });
  }
};

export const saveCreds = (creds) => {
  if (window.tizen || window.webos) {
    localStorage.setItem('@creds', JSON.stringify(creds));
  } else {
    cookies.set("@creds", creds, { path: "/" });
  }
}

export const removeCreds = (creds) => {
  if (window.tizen || window.webos) {
    localStorage.removeItem('@creds');
  } else {
    cookies.remove("@creds", { path: "/" });
  }
}

export const getCreds = () => {
  if (window.tizen || window.webos) {
    const data = localStorage.getItem('@creds');
    if (data) {
      return JSON.parse(data)
    }
  } else {
    return cookies.get("@creds");
  }

}

export const saveToken = (token) => {
  if (window.tizen || window.webos) {
    localStorage.setItem('@token', token);
  } else {
    return cookies.set('@token', token, { path: "/" });
  }
};

export const saveLastChannel = (channel) => {
  localStorage.setItem('@lastWatchedChannel', JSON.stringify(channel));
};

export const getLastChannel = () => {
  try {
    const last = localStorage.getItem('@lastWatchedChannel');
    if (last) {
      return JSON.parse(last)
    }
    return cookies.get("@lastWatchedChannel");
  } catch (error) {
    return window.TopTVAppData.lastChannel
  }
};

export const saveSubuserActiveSession = () => {

  const token = isLogged();
  const mainUser = token && jwtDecode(token);
  const activeUser = getSubuser();
  if (mainUser?.id && activeUser?.id) {
    saveSessionEndStatusToServer({
      userId: mainUser?.id,
      personalID: activeUser?.id,
    });
  }
};

export const setSubuserAllowedTime = (subuser, navigate, mainUser) => {
  const allowedMillis = subuser?.allowedHours * 60 * 60 * 1000;
  const activeMillis = subuser?.activeMillis || 0;
  const timeoutMillis = allowedMillis - activeMillis;

  const timeoutId = setTimeout(() => {
    if (window.tizen || window.webos) {
      localStorage.removeItem('timeoutId')
    } else {
      cookies.remove("timeoutId", { path: "/" });
    }
    navigate("/warning", { state: subuser?.name });
  }, timeoutMillis);

  const now = new Date();

  const endOfDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59,
    999
  );
  if (window.tizen || window.webos) {
    localStorage.setItem('timeoutId', timeoutId)
  } else {
    cookies.set("timeoutId", timeoutId, { path: "/", expires: endOfDay });
  }
};

export const parse = (str) => {
  if (!str) return {};
  // example "20220317014000 +0400"
  // if(!/^(\d){8}$/.test(str)) return "invalid date";
  let Y = str.substr(0, 4),
    M = str.substr(4, 2),
    D = str.substr(6, 2),
    h = str.substr(8, 2),
    m = str.substr(10, 2),
    s = str.substr(12, 2),
    timezone = str.substr(14);
  return { date: new Date(Y, M, D, h, m, s), timezone, Y, M, D, h, m, s };
};

export const shiftEpgTime = (epgTime, shiftHours) => {
  if (!epgTime) return;
  const { Y, M, D, h, m, s } = parse(epgTime);
  const offset = new Date().getTimezoneOffset();
  const offsetHours = shiftHours ? shiftHours : offset / 60;

  let byMoment = `${Y}${M}${D}${h}${m}${s}`;
  if (offsetHours < 0) {
    byMoment = moment(`${Y}${M}${D}T${h}${m}${s}`)
      .add(Math.abs(offsetHours), "hours")
      .format("YYYYMMDDHHmmss");
  } else if (offsetHours > 0) {
    byMoment = moment(`${Y}${M}${D}T${h}${m}${s}`)
      .subtract(Math.abs(offsetHours), "hours")
      .format("YYYYMMDDHHmmss");
  }
  return byMoment;
};

export const parseToHourMinute = (str) => {
  if (typeof str !== "string") return "";
  // example "20220317014000 +0400"
  // if(!/^(\d){8}$/.test(str)) return "invalid date";
  let h = str.substr(8, 2),
    m = str.substr(10, 2);
  return `${h}:${m}`;
};

export const clearedSymbols = (str) => {
  try {
    const keyMap = {
      "&quot;": " ",
    };
    let clearedStr = str;
    Object.entries(keyMap).forEach(([key, value]) => {
      clearedStr = clearedStr.replaceAll(key, value);
    });
    return clearedStr;
  } catch (error) {
    return str;
  }
};

export const getSingleItemByAnOption = (arr, key, val) => {
  return arr.find((item) => item[key] === val);
};

export const generateM3U8URL = (options) => {
  return `${options.baseArchiver}/archive/indexByDate?startTime=${options.startTime}&endTime=${options.endTime}&day=${options.day}&archiveName=${options.archiveName}&date=${options.date}&userOffset=${options.offset}`;
};

export const checkIfValidM3U8 = (urlS, i) => {
  const videoExtensions = ['.mp4', '.mkv', '.flv', '.wmv', '.mov', '.avi', '.avchd', '.webm', '.mpg', '.mpeg', '.m4v', '.swf', '.h264', '.3gp', '.3g2', '.mxf', '.vob', '.rm', '.asf', '.amv', '.m2v', '.svi', '.qt', '.divx', '.f4v', '.m4p', '.ogv', '.yuv', '.rmvb', '.mp2', '.mpe', '.mpv'];
  const url = urlS.trim()
  if (url.endsWith('.m3u8') || url.endsWith('.m3u8/')) {
    return true
  } else {
    const isOne = videoExtensions.filter(item => url.endsWith(item) || url.endsWith(`${item}/`))
    if (isOne.length) {
      return true
    }
    if (url.includes('?')) {
      const parts = url.split('?')
      return parts[0].endsWith('.m3u8') || parts[0].endsWith('.m3u8/') || (videoExtensions.filter(item => parts[0].endsWith(item) || parts[0].endsWith(`${item}/`)).length > 0)
    } else {
      return false
    }
  }
}

export function parseInfoString(inputObject) {
  let outputObject = {};

  // Check if the input has 'info' property
  if (inputObject.info) {
    let info = inputObject.info;

    // Extract tvg-name, tvg-logo, tvg-id, group-title
    let tvgNameMatch = info.match(/tvg-name="([^"]+)"/);
    let tvgLogoMatch = info.match(/tvg-logo="([^"]+)"/);
    let tvgIdMatch = info.match(/tvg-id="([^"]+)"/);
    let groupTitleMatch = info.match(/group-title="([^"]+)"/);

    if (tvgNameMatch) outputObject.tvgName = tvgNameMatch[1];
    if (tvgLogoMatch) outputObject.tvgLogo = tvgLogoMatch[1];
    if (tvgIdMatch) outputObject.tvgId = tvgIdMatch[1];
    if (groupTitleMatch) outputObject.groupTitle = groupTitleMatch[1];

    // Extract name after the last comma
    let name = info.split(',').pop().trim();
    if (name) outputObject.name = name;
  }

  // Check if the input has 'url' property
  if (inputObject.url) {
    outputObject.url = inputObject.url;
  }

  return outputObject;
}



const getNextLine = (lines, index) => {
  if (lines[index + 1]?.startsWith('http')) {
    return lines[index + 1]
  } else if (index + 1 === lines.length) {
    return null
  } else {
    return getNextLine(lines, index + 1)
  }
}


export const extractChannels = (data, playlistId, filtered) => {
  const channels = [];
  const lines = data.split('\n');

  for (let i = 0; i < lines.length; i++) {
    if (lines[i].startsWith("#EXTINF:")) {
      const channel = {
        info: lines[i],
        url: getNextLine(lines, i),
      };
      if (channel?.url) {
        if (filtered) {
          if (checkIfValidM3U8(channel?.url)) {
            channels.push(parseInfoString(channel));
          }
        } else {
          channels.push(parseInfoString(channel));
        }
      }


    }
  }
  const finalChannels = channels.map((item, i) => ({ ...item, index: item.index = i + 1, category: item.groupTitle === 'Undefined' || !item.groupTitle ? 'Others' : item.groupTitle, playlistId, id: Date.now() }))
  return finalChannels;
};

export function getPlatformFromUserAgent() {
  const userAgent = window.navigator?.userAgent;

  // Check for common platforms
  if (/Windows NT/.test(userAgent)) {
    return 'Windows';
  } else if (/Mac OS X/.test(userAgent)) {
    return 'Mac OS';
  } else if (/Android/.test(userAgent)) {
    return 'Android';
  } else if (/Linux/.test(userAgent)) {
    return 'Linux';
  } else if (/iPhone|iPad|iPod/.test(userAgent)) {
    return 'iOS';
  }

  return null;
}