import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Focusable } from "react-js-spatial-navigation";
import { useLocation, useNavigate } from "react-router";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { keyMap } from "../../../utils/constants";
import styles from "./styles.module.css";
import { getActiveUser } from "../../../utils/helpers";
import { api } from "../../../api/config";
import { useSnackbar } from "notistack";

const Delete = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const initialFocusedRef = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function goBack(e) {
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-1);
    }
  }

  const buttons = [
    {
      label: "Cancel",
      onEnter: () => {
        navigate(-1);
      },
      bg: "#87C232",
    },
    {
      label: "Delete",
      onEnter: () => {
        navigate(-2);
        deleteUser();
      },
    },
  ];

  const deleteUser = async () => {
    const activeUser = getActiveUser();
    const res = await api.delete(
      `/users/${activeUser.id}/subuser?subId=${location.state.id}`
    );
    if (res?.data?.status === "success") {
      enqueueSnackbar(t(`User {{name}} deleted`), {
        variant: "success",
        autoHideDuration: 3000,
      });

      return;
    }
    enqueueSnackbar(res?.message, {
      variant: "error",
      autoHideDuration: 3000,
    });
  };

  useEffect(() => {
    initialFocusedRef.current.parentNode.focus();
  }, []);

  return (
    <div className={styles.mainDelete} onKeyUp={(e) => goBack(e)}>
      <div className={styles.containerDelete}>
        <h2 className={styles.title}>{t(`settings.Select_user_to_delete`)}</h2>
        <div className={styles.userItem}>
          <AccountBoxIcon />
          <div>{location.state.name}</div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxCont}>
            Are you sure you want to delate this subuser?
            <div  className={styles.buttons}>
              {buttons.map((item, i) => (
                <div onClick={item.onEnter} key={item.label} >

                <Focusable
                  className={styles.focusable}
                  onClickEnter={item.onEnter}
                >
                  <div
                    ref={i === 0 ? initialFocusedRef : null}
                    className={styles.actionBtn}
                  >
                    {t(`settings.${item.label}`)}
                  </div>
                </Focusable>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delete;
