import React from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Focusable } from "react-js-spatial-navigation";
// import { getArrayOfMovieObject } from "../../../utils/getArrayOfMovieObjects";
import styles from "./category.module.css";

const CategoryItems = ({ categories, setGanresName }) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  const handleFocus = (ind, arr) => {
    if (ind === arr.length - 2) {
      // scrollBy -----------------------------------
      // ref.current.scrollTo(3000, 0);
    }
    if (ind === 1) {
      ref.current.scrollTo(0, 0);
    }
  };

  return (
    <div ref={ref} className={styles.slider}>
      {categories.map((item, ind, arr) => {
        return (
          <Focusable
            onFocus={() => handleFocus(ind, arr)}
            key={item.label}
            className={styles.sliderItem}
            onClickEnter={() => {
              setGanresName(item);
            }}
          >
            {/* targmanutyun@ chkar dra hamar useTranslation i mej chem qcel item.label @ */}
            {/* {t(`VOD.${item.label}`)}  */}
            <div onClick={() => {
              setGanresName(item);
            }} className={styles.width}>{item.label}</div>
          </Focusable>
        );
      })}
    </div>
  );
};

export default CategoryItems;
