import React, { useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Focusable, FocusableSection } from "react-js-spatial-navigation";
import { keyMap } from "../../utils/constants";
import styles from "./styles.module.css";
import cx from "classnames";

export default function Modal({
  cancelText,
  title,
  open,
  setOpen,
  content,
  okText,
  handleCancel,
  handleOk,
  className,
}) {
  const okRef = useRef();
  const cancelRef = useRef();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (okRef?.current) {
      okRef.current.parentNode.focus();
    }
  }, [okRef.current, open]);

  const handleKeyUp = e => {
    // e.preventDefault();
    // e.stopPropagation();
  };

  const handleKeyDown = e => {
    const activeId = document.activeElement.childNodes[0].id;
    if (e.which === keyMap.ARROW_LEFT) {
      cancelRef.current.parentNode.focus();
    } else if (e.which === keyMap.ARROW_RIGHT) {
      okRef.current.parentNode.focus();
    } else if (e.which === keyMap.ARROW_RIGHT) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div onKeyUp={handleKeyUp} onKeyDown={handleKeyDown}>
      <Dialog
        classes={{ scrollPaper: styles.Dialog, root: styles.className }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            // border: "3px solid #87c232 !important",
            // padding: "20px !important",
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className={styles.dialogText}>{title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={styles.dialogText}
            id="alert-dialog-description"
          >
            <span className={styles.dialogText}>{content}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Focusable
            onClickEnter={handleCancel}
            className={styles.modalButtonCont}
          >
            <button
              id="cancel"
              className={styles.modalButton}
              ref={cancelRef}
              onClick={handleCancel}
            >
              {cancelText}
            </button>
          </Focusable>
          <Focusable onClickEnter={handleOk} className={styles.modalButtonCont}>
            <button
              id="ok"
              className={styles.modalButton}
              ref={okRef}
              onClick={handleOk}
            >
              {okText}
            </button>
          </Focusable>
        </DialogActions>
      </Dialog>
    </div>
  );
}
