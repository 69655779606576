import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Focusable } from "react-js-spatial-navigation";
import { useLocation, useNavigate } from "react-router";
import { keyMap } from "../../../utils/constants";
import cx from "classnames";
import styles from "./styles.module.css";
import { ReactComponent as Logo } from "../../../assets/images/lock.svg";
import { getActiveUser } from "../../../utils/helpers";
import { api } from "../../../api/config";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const AgeRestriction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ref = useRef(null);
  const [selected, setSelected] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const location = useLocation();
  const ages = [18, 16, 12, 7];

  useEffect(() => {
    if (location.state.ageRectriction?.length) {
      setSelected(location.state.ageRectriction);
    }
  }, [location]);

  useEffect(() => {
    ref.current.firstChild.focus();
  }, []);

  function goBack(e) {
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-2);
    }
  }

  const selectAges = item => {
    if (!selected.includes(item)) {
      setSelected([...selected, item]);
    } else {
      setSelected(selected.filter(el => el !== item));
    }
  };

  const restriction = arr => {
    let newArr = arr.sort((a, b) => b - a);
    if (newArr.length === 0) {
      return "";
    } else if (newArr.length === 1) {
      return (
        <>
          <div>{`${newArr[0]}+ content won't be`}</div>
          <div>viewable on this account.</div>
        </>
      );
    } else if (newArr.length === 2) {
      return (
        <>
          <div>{` ${newArr[1]}+, and ${newArr[0]}+ content won't be`}</div>
          <div>viewable on this account.</div>
        </>
      );
    } else if (newArr.length === 3) {
      return (
        <>
          <div>{`${newArr[2]}+, ${newArr[1]}+, and ${newArr[0]}+ content won't be`}</div>
          <div>viewable on this account.</div>
        </>
      );
    }
  };

  const okReq = async () => {
    const activeUser = getActiveUser();
    const res = await api.put(
      `/users/${activeUser.id}/subuser/${location.state.id}`,
      {
        ageRectriction: selected,
      }
    );

    if (res.data.status === "success") {
      enqueueSnackbar(t(`Settings saved successfully`), {
        variant: "success",
        autoHideDuration: 3000,
      });
      return;
    }
    enqueueSnackbar(t("An error occurred, please try again later"), {
      variant: "error",
      autoHideDuration: 3000,
    });
  };

  return (
    <div className={styles.mainAge} onKeyUp={e => goBack(e)}>
      <div className={styles.containerAge}>
        <div className={styles.ageContext}>
          <div className={styles.ageListContainer}>
            <div>
              {ages.map(item => {
                let bool = selected.includes(item);
                return (
                  
                  <div key={item} onClick={() => selectAges(item)}>
                  <Focusable
                    
                    className={cx(styles.focusableAge, {
                      [styles.selectedItem]: bool,
                    })} 
                    onClickEnter={() => selectAges(item)}
                  >
                    

                    <div  className={styles.itemPlus}> {item + "+"}</div>
                    <div className={styles.logoDiv}>
                      {bool && (
                        <Logo
                          className={styles.svgLogo}
                          width="40"
                          height="40"
                        />
                      )}
                    </div>
                  </Focusable>
                    </div>
                );
              })}
            </div>
            <Focusable
              className={styles.clearAll}
              onClickEnter={() => setSelected([])}
            >
            <div onClick={() => setSelected([])} >
              {t("settings.Clear all")}
            </div>
            </Focusable>
          </div>
          <div className={styles.middleAge}>
            <div className={styles.title}>{t("settings.Age-Restriction")}</div>
            <div className={styles.text}>{restriction(selected)}</div>
            <div className={styles.buttons} onClick={okReq} ref={ref}>
              <Focusable className={styles.ok} onClickEnter={okReq}>
                {t("settings.ok")}
              </Focusable>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default AgeRestriction;
