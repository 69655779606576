import React from "react";
import { useLocation, useNavigate } from "react-router";
import VODPlayer from "../../../components/VODMoviePlayer";
import { keyMap } from "../../../utils/constants";
import styles from "./style.module.css";

const MoviePlayer = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  function goBack(e) {
    if(e === 'modal'){
      navigate(-1);
      return
    }
    let code = e.which;
    if (code === keyMap.KEY_D || code === keyMap.REMOTE_BACK) {
      navigate(-1);
    }
  }

  return (
    <div className={styles.mainPlayer} onKeyUp={(e) => goBack(e)}>
      <div className={styles.containerPlayer}>
        <VODPlayer info={state} url={state?.url} handleBackClick={()=>goBack('modal')} />
      </div>
    </div>
  );
};

export default MoviePlayer;
