import React, { useEffect, useRef, useState } from 'react';
import SpatialNavigation, { Focusable } from 'react-js-spatial-navigation';
import { useNavigate } from 'react-router';
import cx from 'classnames';
import { base, keyMap } from '../../utils/constants';
import InfiniteScrollLoop from '../inifiniteScroll/infiniteScroll';
import styles from './styles.module.css';
import { getActiveUser, getSubuser, saveSubuser } from '../../utils/helpers';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import RestoreIcon from '@material-ui/icons/Restore';
import { api } from '../../api/config';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function ChannelsList({
  list,
  currentChannel,
  handleSelectChannel,
  classes,
  currentFocusedChannel,
  setCurrentFocusedChannel,
  isInPlayer
}) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [channelsList, setChannelsList] = useState([])
  const [gotList, setGotList] = useState(false)
  const [gotFocused, setGotFocused] = useState(false)
  const activeSub = getSubuser()
  const user = getActiveUser()
  const [favs, setFavs] = useState(false)
  const noFavsRef = useRef(null)



  const handleFavChange = (e) => {
    setFavs(prev => !prev)
  }


  useEffect(() => {
    if (list && list.length && !gotList) {
      const user = getSubuser()
      if (user?.allowedChannels?.length) {
        setChannelsList(list.filter(item => user.allowedChannels.includes(item._id)).map((item, i) => ({ ...item, index: i })))
      } else {
        setChannelsList(list.map((item, i) => ({ ...item, index: i })))
      }
      setGotList(true)
    }
  }, [list])



  const channelsRef = useRef([])

  useEffect(() => {
    if (gotFocused) return
    channelsList.forEach((item, i) => {

      if (item._id === currentChannel._id) {
        channelsRef.current[i].parentNode.parentNode.focus()
        if (!currentFocusedChannel) {
          setCurrentFocusedChannel(item)
          setGotFocused(true)
        }
        // channelsRef.current[i].parentNode.focus()
      }
    })
  }, [channelsList, currentChannel])

  const handleOpenFullEpg = (e) => {
    if (e.which === 39) {
      const activeChannel = channelsList.find(item => item._id === document.activeElement.childNodes[0].childNodes[0].id)
      navigate('/fullEpg', { state: activeChannel })
    }
  }

  const handleKeyUp = (e) => {
    if (e.which === keyMap.REMOTE_COLOR_RED || e.which === keyMap.KEY_R) {
      handleFavChange()
    }
    if (isInPlayer) return
    const index = channelsList.findIndex(item => item._id === document.activeElement.childNodes[0].childNodes[0].id)
    setCurrentFocusedChannel(channelsList[index])
    // if (channelsList.length < 15) return
    // if (index === 0 && e.which === keyMap.ARROW_UP) {
    //   let copyList = [...channelsList];
    //   let lastItem = copyList.pop()
    //   copyList.unshift(lastItem)
    //   setChannelsList(copyList)
    // }
    // if (index === channelsList.length - 1 && e.which === keyMap.ARROW_DOWN) {
    //   let copyList = [...channelsList];
    //   let item = copyList.shift()
    //   copyList.push(item)
    //   setChannelsList(copyList)
    // }
  }

  useEffect(() => {
    const activeChannel = channelsList.find(item => item._id === document.activeElement.childNodes[0].childNodes[0].id)
    setCurrentFocusedChannel(activeChannel)
  }, [])

  // useEffect(()=>{
  //   const activeChannel = channelsList.find(item=>item._id === document.activeElement.childNodes[0].id)
  //   if(!activeChannel){
  //     if(currentFocusedChannel){
  //       const el = document.getElementById(currentFocusedChannel._id)
  //       if(el){
  //         el.parentNode.focus()
  //       }
  //     }
  //   }
  // }, [currentFocusedChannel])


  const handleFocus = (item, i) => {
    if (channelsList.length < 15 || favs) return
    setCurrentFocusedChannel(channelsList[i])
    if (i === 0) {
      let copyList = [...channelsList];
      let lastItem = copyList.pop()
      copyList.unshift(lastItem)
      setChannelsList(copyList)
    }
    if (i === channelsList.length - 1) {
      let copyList = [...channelsList];
      let item = copyList.shift()
      copyList.push(item)
      setChannelsList(copyList)
    }
  }


  const channelsListF = useMemo(() => {
    return favs ? channelsList.filter(item => activeSub?.favoriteChannels?.includes(item._id)) : channelsList
  }, [favs, channelsList, activeSub?.favoriteChannels])

  useEffect(() => {
    if(favs && !activeSub?.favoriteChannels?.length){
      noFavsRef.current?.parentNode?.focus && noFavsRef.current.parentNode.focus()
      return
    }  
    if(!favs && !activeSub?.favoriteChannels?.length && channelsRef.current?.[1]?.parentNode?.parentNode){
      channelsRef.current[1].parentNode.parentNode.focus()
      setCurrentFocusedChannel(channelsListF[1])
      setGotFocused(true)
      return
    }  
    if(favs && channelsRef.current?.[0]?.parentNode?.parentNode){
      channelsRef.current[0].parentNode.parentNode.focus()
      setCurrentFocusedChannel(channelsListF[0])
      setGotFocused(true)
      return
    }
   
  }, [favs])

  const onChannelClick = (item, i) => {
    let index = i
    if (favs) {
      index = channelsList.findIndex(channel => item._id === channel._id)
    }
    if (index > -1) {
      handleSelectChannel(item, i);
    }
  }


  return (
    // <InfiniteScrollLoop>
    <div className={cx(...classes)} >
      <div className={styles.instr}>
        <div className={styles.redCont}> <div className={styles.red}>A</div><div className={styles.text}>{favs ? t('channels.showAll') : t('channels.showFavs')}</div></div>
        <div className={styles.yellowCont}> <div className={styles.yellow}>C</div><div className={styles.text}>{activeSub?.favoriteChannels?.includes(currentFocusedChannel?._id) ? t('channels.unmarkFav') : t('channels.markFav')}</div></div>
      </div>
      <div onKeyUpCapture={handleOpenFullEpg} onKeyDown={handleKeyUp} style={{height: 'calc(100vh - 160px)'}} >
        <div style={{height: "100%", overflow: 'auto', padding: '0px 20px' }}>
        {favs && !activeSub?.favoriteChannels?.length ?
        <Focusable>
        <div ref={noFavsRef} className={styles.noFavs}>
          {t('channels.noFavs')}
        </div>
        </Focusable>
         :
        Array.isArray(list) &&
          channelsListF?.map((item, i) => (
            <SingleChannel
              key={item._id}
              ref={(ref) => channelsRef.current[i] = ref}
              activeChannel={currentChannel}
              channel={item}
              onChannelClick={onChannelClick}
              handleFocus={handleFocus}
              i={i}
              handleSelectChannel={handleSelectChannel}
              activeSub={activeSub}
              user={user}
            />

          ))}
      </div>
      </div>
    </div>
    // </InfiniteScrollLoop>
  );
}

const SingleChannel = React.forwardRef(({
  i,
  channel,
  onChannelClick,
  handleFocus,
  handleSelectChannel,
  activeSub,
  user
}, itemRef) => {

  const [isFav, setIsFav] = useState(false)

  const handleFavChange = () => {
    let info = {
      userId: user.id,
      personalID: activeSub.id,
      favoriteChannels: []
    }
    if (activeSub?.favoriteChannels?.length) {
      const foundIndex = activeSub.favoriteChannels.indexOf(channel._id)
      setIsFav(!(foundIndex > -1))
      if (foundIndex > -1) {
        info.favoriteChannels = activeSub.favoriteChannels.filter(item => item !== channel._id)
      } else {
        info.favoriteChannels = [...activeSub.favoriteChannels, channel._id]
      }
    } else {
      info.favoriteChannels = [channel._id]
      setIsFav(true)
    }
    activeSub.favoriteChannels = info.favoriteChannels
    saveSubuser(activeSub)
    api.put(
      `/users/${info.userId}/subuser/${info.personalID}`,
      {
        favoriteChannels: info.favoriteChannels,
      }
    );

  }

  const handleKeyUp = (e) => {
    switch (e.which) {
      case keyMap.REMOTE_COLOR_YELLOW:
        handleFavChange()
        break;
      case keyMap.KEY_Y:
        handleFavChange()
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (activeSub?.favoriteChannels?.length) {
      setIsFav(activeSub.favoriteChannels.includes(channel._id))
    }
  }, [activeSub?.favoriteChannels])

  return (
    <div onKeyDown={handleKeyUp}>
      <Focusable
        id={channel._id}
        onClickEnter={() => onChannelClick(channel, i)}
        className={styles.channel}
        onFocus={() => { handleFocus(channel, i) }}
      >
        <div
          style={{ width: '100%' }}
          onClick={() => {
            handleSelectChannel(channel, i);
          }}>
          <div
            ref={itemRef}
            id={channel._id}
            className={cx(styles.channelCont)}
          >
            <div className={cx(styles.nameCont)} >
              <div style={{ width: '40px' }} >{channel.index + 1}.</div>
              {' '}
              <div
                style={{ backgroundImage: `url("${base}/img/channels/${channel.image}")` }}
                className={styles.channelLogo}
              ></div>{' '}
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', maxWidth: 'calc(100% - 120px)', textOverflow: 'ellipsis' }} >
                {channel.name}
              </div>
            </div>
            <div className={styles.icons}>
              <div onClick={e=> {e.stopPropagation(); handleFavChange()}}>
              {isFav ? <StarIcon fontSize='large' htmlColor='#EDF050' /> : <StarBorderIcon fontSize='large' htmlColor='#EDF050' />}
              </div>
              {channel.archive && <RestoreIcon fontSize='large' />}
            </div>
          </div>
        </div>
      </Focusable >
    </div>
  );
});

ChannelsList.defaultProps = {
  classes: []
}
